











import { defineComponent } from "@vue/composition-api"
export default defineComponent({
  props: {
    bordered: Boolean,
    height: [String, Number],
    width: [String, Number],
    bgColor: String,
    elevation: {
      type: [String, Number],
      default: 1,
    },
    color: {
      type: String,
      default: "#fff",
    },
  },

  setup(props) {
    const getBgColor = () => {
      return props.bgColor ?? "#fff"
    }
    return { getBgColor }
  },
})
