























































import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api"
import I18nFormattedDateTime from "@/components/i18n/I18nFormattedDateTime.vue"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import Icon from "@/components/Icon.vue"
import Loading from "@/components/Loading.vue"
import IconButton from "@/components/buttons/IconButton.vue"
import { teamsContextContainer } from "@/containers/TeamsContextContainer"
import { projectsContainer } from "@/containers/ProjectsContainer"
import { meetingContainer } from "@/containers/MeetingsContainer"
import { i18nContainer } from "@/containers/I18nContainer"
import { Meeting, MeetingStructure } from "@/models/Meeting"
import { Project } from "@/models/Project"
import { getMeetingDeepLinkUrl } from "@/utilities/URIHelper"
import DateTime from "@/models/DateTime"
import type { SearchResult as SearchResultApi } from "@/api/ListItemApi"

interface SearchResult {
  meeting: {
    project: Project
    parent: MeetingStructure
    meeting: Meeting
  }
  startTime: DateTime
  endTime: DateTime
  name: string
  date: string
  parentId: string
  id: string
  summary: string
  linkUrl: string
  loadingSummary: boolean
}

interface State {
  total: number
  isSearching: boolean
  searchResults: Array<SearchResult>
}

export default defineComponent({
  props: {
    isMenuVisible: {
      type: Boolean,
    },
    searchQuery: {
      type: String,
    },
  },
  components: {
    I18nFormattedDateTime,
    I18nFormattedMessage,
    Icon,
    Loading,
    IconButton,
  },
  setup(props, { emit }) {
    const { formatDateTime, formatMessage } = i18nContainer.useContainer()
    const { getMeetingsAsync } = meetingContainer.useContainer()
    const { getContext } = teamsContextContainer.useContainer()
    const { getMemoHtmlValue } = meetingContainer.useContainer()
    const { getProjectAsync, searchProjectMemosAsync } =
      projectsContainer.useContainer()

    const state = reactive<State>({
      total: 0,
      isSearching: false,
      searchResults: [],
    })

    const getNextAndPastMeetingItems = (
      meetings: Array<{
        project: Project
        meetings: MeetingStructure[]
      }>,
      resultKeyValue: Array<{
        listId: string
        id: string
        summary: string
        fields: { [key: string]: string }
      }>
    ): Array<SearchResult> => {
      const meeteintParentPaire = meetings
        .map((m) =>
          m.meetings.map((mi) => ({ project: m.project, parent: mi }))
        )
        .reduce((a, b) => {
          return a.concat(b)
        }, [])
        .map((m) =>
          m.parent.meetings.map((mi) => ({
            project: m.project,
            parent: m.parent,
            meeting: mi,
          }))
        )
        .reduce((a, b) => {
          return a.concat(b)
        }, [])

      const sortedItems = resultKeyValue
        .reduce((acc: Array<SearchResult>, item) => {
          const meeting = meeteintParentPaire.find(
            (m) =>
              m.project.sharepointListId === item.listId &&
              m.meeting.sharepointItemId === item.id
          )
          if (meeting) {
            const linkUrl = getMeetingDeepLinkUrl(
              meeting.project,
              meeting.parent,
              meeting.meeting
            )
            if (linkUrl) {
              const meetingName = item.fields["title"]
              let name = `${meeting.parent.name}${
                meetingName === meeting.parent.name ? "" : " > " + meetingName
              }`
              name = name.replaceAll(
                props.searchQuery || "",
                `<b>${props.searchQuery}</b>`
              )
              acc.push({
                meeting: meeting,
                name,
                startTime: meeting.meeting.startTime,
                endTime: meeting.meeting.endTime,
                date: meeting.meeting.startTime.toDateJpString(),
                parentId: meeting.parent.id,
                id: meeting.meeting.id,
                summary: "",
                loadingSummary: true,
                linkUrl,
              } as SearchResult)
            } else {
              state.total -= 1
            }
          } else {
            state.total -= 1
          }
          return acc
        }, [])
        .sort((m1: SearchResult, m2: SearchResult) => {
          return (
            m2.startTime.getDifference(m1.startTime, "minutes") ||
            m2.endTime.getDifference(m1.endTime, "minutes")
          )
        })
      return sortedItems
    }

    const search = async (searchQuery: string) => {
      state.isSearching = true
      const context = await getContext()
      const [project, meetings] = await Promise.all([
        getProjectAsync(context.entityId),
        getMeetingsAsync(context.entityId),
      ])
      if (project && meetings) {
        let results: SearchResultApi
        try {
          results = await searchProjectMemosAsync([project], searchQuery)
        } catch (e) {
          state.isSearching = false
          throw e
        }
        state.total = results.value[0].hitsContainers[0].total
        const hits = results.value[0].hitsContainers[0].hits || []
        const resultKeyValue = hits.map((h) => ({
          listId: h.resource.sharepointIds?.listId || "",
          id: h.resource.sharepointIds?.listItemId || "",
          summary: h.summary || "",
          fields: (h.resource.fields || {}) as { [key: string]: string },
        }))
        state.searchResults = getNextAndPastMeetingItems(
          [{ project, meetings }],
          resultKeyValue
        )
        state.isSearching = false
        const values = (
          await Promise.all(
            state.searchResults.map(async (currentMeeting) => {
              let memo = ""
              try {
                memo = await getMemoHtmlValue(
                  project.siteId,
                  project.sharepointListId,
                  currentMeeting.meeting.meeting.sharepointItemId
                )
              } catch {
                memo = "取得失敗"
              }
              return { key: currentMeeting.meeting.meeting.id, memo }
            })
          )
        ).reduce((v, a) => {
          v[a.key] = a.memo
          return v
        }, {} as { [key: string]: string })
        state.searchResults = state.searchResults.map((v) => {
          if (values[v.id]) {
            let summrayData = values[v.id].replace(/<[^>]*>/g, " ")
            const stringSpan = 30
            const trimingStart = Math.max(
              summrayData.indexOf(searchQuery) - stringSpan,
              0
            )
            const trimingEnd = Math.min(
              summrayData.indexOf(searchQuery) +
                searchQuery.length +
                stringSpan,
              summrayData.length
            )
            const isEnded = trimingEnd === summrayData.length
            summrayData = summrayData.replaceAll(
              searchQuery,
              `<b>${searchQuery}</b>`
            )
            v.loadingSummary = false
            v.summary =
              (trimingStart === 0 ? "" : "...") +
              summrayData.substring(trimingStart, trimingEnd) +
              (isEnded ? "" : "...")
          } else {
            v.loadingSummary = false
            v.summary =
              '<label style="color:#AAA">(メモのデータがありません)</label>'
          }
          return v
        })
      }

      emit("searched")
    }

    watch(
      () => props.searchQuery,
      async (newValue) => {
        if (newValue && props.isMenuVisible) {
          await search(newValue)
        }
      }
    )

    onMounted(async () => {
      if (props.isMenuVisible && props.searchQuery) {
        await search(props.searchQuery)
      }
    })

    const closeDialog = () => {
      emit("close")
    }

    return {
      state,
      formatDateTime,
      i18nFormattedMessage: formatMessage,
      closeDialog,
    }
  },
})
