import { Meeting } from "@/models/Meeting"

const boundary = 10
const mult = 2
const base = 32

class Identifier {
  digit: number
  constructor(digit: number) {
    this.digit = digit
  }

  compareTo(otherId: Identifier) {
    if (this.digit < otherId.digit) {
      return -1
    } else if (this.digit > otherId.digit) {
      return 1
    } else {
      return 0
    }
  }
}

export function generateOrderValue(beforeValue = "", afterValue = "") {
  const pos1 = beforeValue
    .split("-")
    .filter(s => !isNaN(parseInt(s, 10)))
    .map(s => new Identifier(parseInt(s, 10)))
  const pos2 = afterValue
    .split("-")
    .filter(s => !isNaN(parseInt(s, 10)))
    .map(s => new Identifier(parseInt(s, 10)))
  const ret = generatePosBetween(pos1, pos2)
  return ret.reduce((a, i) => `${a ? a + "-" : a}${i.digit}`, "")
}

export function order(a = "", b = "") {
  const pos1 = a.split("-").map(s => new Identifier(parseInt(s, 10)))
  const pos2 = b.split("-").map(s => new Identifier(parseInt(s, 10)))
  const max = pos1.length > pos2.length ? pos1.length : pos2.length
  for (let i = 0; i < max; i++) {
    const compareValue = (pos1[i] || new Identifier(0)).compareTo(
      pos2[i] || new Identifier(0)
    )
    if (compareValue !== 0) return compareValue
  }
  return 0
}

function generatePosBetween(
  pos1: Array<Identifier>,
  pos2: Array<Identifier>,
  newPos: Array<Identifier> = [],
  level = 0
): Array<Identifier> {
  const currentBase = Math.pow(mult, level) * base
  const boundaryStrategy = Math.round(Math.random()) === 0 ? "+" : "-"
  const id1 = pos1[0] || new Identifier(0)
  const id2 = pos2[0] || new Identifier(currentBase)

  if (id2.digit - id1.digit > 1) {
    const newDigit = generateIdBetween(id1.digit, id2.digit, boundaryStrategy)
    newPos.push(new Identifier(newDigit))
    return newPos
  } else if (id2.digit - id1.digit === 1) {
    newPos.push(id1)
    return generatePosBetween(pos1.slice(1), [], newPos, level + 1)
  } else if (id1.digit === id2.digit) {
    newPos.push(id1)
    return generatePosBetween(pos1.slice(1), pos2.slice(1), newPos, level + 1)
  } else {
    throw Error("pos1がpos2より前")
  }
}

function generateIdBetween(min: number, max: number, boundaryStrategy: string) {
  if (max - min < boundary) {
    min = min + 1
  } else {
    if (boundaryStrategy === "-") {
      min = max - boundary
    } else {
      min = min + 1
      max = min + boundary
    }
  }
  return Math.floor(Math.random() * (max - min)) + min
}

export function meetingOrder(m1: Meeting, m2: Meeting) {
  return (
    m2.startTime.getDifference(m1.startTime, "minutes") ||
    m2.endTime.getDifference(m1.endTime, "minutes") ||
    (m2.id < m1.id ? -1 : 1)
  )
}
