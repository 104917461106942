import SofieClient from "./clients/SofieClient"
import Notification from "@/models/Notification"

class NotificationApi {
  private _client = new SofieClient()

  removeNotification = async (id: string) => {
    await this._client.deleteAsync(`notifications/${id}`)
  }

  getMyNotifications = async () => {
    const notifications = await this._client.getAsync<Array<Notification>>(
      "notifications/getMyNotifications"
    )
    return notifications.map(n => new Notification(n))
  }

  createNotification = async (notification: Notification) => {
    return await this._client.postAsync<Notification>(
      "notifications",
      JSON.stringify(
        Object.assign(notification, {
          notificationDate: notification.notificationDate.toIsoString(),
        })
      )
    )
  }
}

export default NotificationApi
