























import {
  defineComponent,
  reactive,
  watch,
  computed,
  PropType,
} from "@vue/composition-api"
import { ruleFunction } from "@/utilities/ruleFunction"

interface State {
  theValue: string
}

export default defineComponent({
  props: {
    readonly: Boolean,
    required: Boolean,
    name: String,
    label: String,
    placeholder: String,
    hideDetails: Boolean,
    rows: {
      type: [String, Number],
      default: "9",
    },
    value: {
      type: String,
      default: "",
    },
    height: {
      type: [String, Number],
      defualt: 180,
    },
    rules: Array as PropType<ruleFunction[]>,
    autoGrow: Boolean,
    blackText: Boolean,
  },

  setup(props, { emit }) {
    const state = reactive<State>({
      theValue: props.value,
    })

    const getDefaultRules = (): Array<ruleFunction> => {
      const rules: Array<ruleFunction> = []

      if (props.required)
        rules.push(value => !!value.toString().trim() || "入力は必須です。")

      return rules
    }
    const combinedRules = computed<Array<ruleFunction>>(() =>
      getDefaultRules().concat(props.rules || [])
    )

    const onChange = (value: string) => {
      state.theValue = value
      emit("input", state.theValue)
    }

    watch(
      () => props.value,
      newValue => {
        state.theValue = newValue
      }
    )

    return { state, onChange, combinedRules }
  },
})
