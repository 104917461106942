




import { defineComponent, computed } from "@vue/composition-api"
import { i18nContainer } from "@/containers/I18nContainer"

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
    defaultMessage: {
      type: String,
      required: true,
    },
    values: Object,
  },

  setup(props) {
    const { formatMessage } = i18nContainer.useContainer()

    const message = computed(() =>
      formatMessage(
        { id: props.id, defaultMessage: props.defaultMessage },
        props.values
      )
    )

    return { message }
  },
})
