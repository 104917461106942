import MsGraphClient from "./clients/MsGraphClient"
import * as MicrosoftGraph from "@microsoft/microsoft-graph-types"
import ApiError from "@/models/Errors/ApiError"

class UserApi {
  private _client = new MsGraphClient()

  getUser = async (
    userId: string
  ): Promise<MicrosoftGraph.User | undefined> => {
    try {
      return await this._client.getAsync<MicrosoftGraph.User>(
        `/users/${userId}`
      )
    } catch (e) {
      if (e instanceof ApiError) {
        if (e.status === 404) return undefined
      }
      throw e
    }
  }
}

export default UserApi
