import { authentication } from "@microsoft/teams-js"

import HttpClient from "@/api/clients/HttpClient"
import ApiError from "@/models/Errors/ApiError"
import ErrorResponse from "@/models/ErrorResponse"
import azureAdAuthentication from "@/utilities/AzureAdAuthentication"

interface TokenData {
  token?: string
  expaire?: Date
  scopes?: string
}

const customerToken = [
  {
    version: 2,
    accessToken: [
      "Tasks.ReadWrite",
      "Files.ReadWrite.All",
      "Calendars.ReadWrite",
      "User.Read",
      "User.ReadBasic.All",
      "Sites.ReadWrite.All",
      "TeamMember.Read.All",
      "Team.ReadBasic.All",
      "Channel.ReadBasic.All",
      "ChannelMember.Read.All",
      "ChatMessage.Send",
    ],
  },
  {
    version: 1,
    accessToken: [
      "Tasks.ReadWrite",
      "Files.ReadWrite.All",
      "Calendars.ReadWrite",
      "User.Read",
      "User.ReadBasic.All",
      "Sites.ReadWrite.All",
      "TeamMember.Read.All",
      "Team.ReadBasic.All",
      "Channel.ReadBasic.All",
    ],
  },
]

export const manageToken = ["Sites.Manage.All"]

class SofieTokenProvider {
  private _client = new HttpClient({ baseUrl: "/api" })
  private _token?: TokenData

  async getTokenAsync(): Promise<string> {
    if (window.inTheTeamsIFrame) {
      return await authentication.getAuthToken()
    } else {
      const login = await azureAdAuthentication.getTokenAsync()
      return login.accessToken
    }
  }

  async getRoles(): Promise<Array<string>> {
    const token = await this.getTokenAsync()
    const base64Url = token.split(".")[1]
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function(c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join("")
    )
    return JSON.parse(jsonPayload)?.roles
  }

  async getCustomerGraphApiTokenAsync(): Promise<string> {
    if (window.tokenVersion) {
      const tokens = customerToken.find(t => t.version === window.tokenVersion)
      if (tokens?.accessToken)
        return await this.getGraphApiTokenAsync(tokens.accessToken.join(","))
    }
    for (const current of customerToken) {
      let ret = ""
      try {
        ret = await this.getGraphApiTokenAsync(current.accessToken.join(","))
      } catch {
        continue
      }
      if (ret) {
        window.tokenVersion = current.version
        return ret
      }
    }
    throw new Error(
      "Tokenの取得に失敗しました。アドミンコンセントの状態を確認して下さい。"
    )
  }

  async getManageGraphApiTokenAsync(): Promise<string> {
    return await this.getGraphApiTokenAsync(manageToken.join(","))
  }

  async getGraphApiTokenAsync(scopes: string): Promise<string> {
    if (
      this._token &&
      this._token.token &&
      this._token.expaire &&
      new Date(this._token.expaire) > new Date() &&
      this._token.scopes === scopes
    ) {
      return this._token.token
    }
    const currentToken = await this.getTokenAsync()
    const query = new URLSearchParams()
    query.append("scopes", scopes)
    try {
      const tokenData = await this._client.getAsync<TokenData>("tokens/Graph", {
        headers: { Authorization: `Bearer ${currentToken}` },
        queryParameters: query,
      })
      if (!tokenData || !tokenData.token)
        throw new Error("Tokenの取得に失敗しました")
      tokenData.scopes = scopes
      this._token = tokenData
      return tokenData.token
    } catch (e) {
      if (e instanceof ApiError) {
        let errorData: ErrorResponse | undefined
        if (e.status === 400 && e.response) {
          errorData = (await e.response.json()) as ErrorResponse
          e.errorInfo = errorData
        }
      }
      throw e
    }
  }

  async consentAndGetTokenAsync(
    clientId: string,
    scopes?: string
  ): Promise<string> {
    const url = `${window.location.origin}/authorize-start?clientId=${clientId}&scopes=${scopes}`
    if (window.inTheTeamsIFrame) {
      const auth = await authentication.authenticate({
        url: url,
        width: 600,
        height: 535,
      })
      return auth
    } else {
      return new Promise((resolve, reject) => {
        const newWindow = window.open(
          url,
          "authentication",
          "width=600,height=535"
        )
        if (newWindow) {
          newWindow.document.addEventListener("successAuth", ((
            event: CustomEvent<{ result: string | undefined }>
          ) => {
            resolve(event.detail.result || "")
          }) as EventListener)
          newWindow.document.addEventListener("failureAuth", ((
            event: CustomEvent<{ reason: string | undefined }>
          ) => {
            reject(new Error(event.detail.reason || "unknown"))
          }) as EventListener)
        }
      })
    }
  }
}

export default SofieTokenProvider
