import MsGraphClient from "./clients/MsGraphClient"
import * as MicrosoftGraph from "@microsoft/microsoft-graph-types"
import { Meeting } from "@/models/Meeting"
import DateTime from "@/models/DateTime"

class EventApi {
  private _client = new MsGraphClient()

  getEvents = async (
    startDatetime: DateTime,
    endDatetime: DateTime
  ): Promise<Array<MicrosoftGraph.Event>> => {
    const rets = []
    let url = `/me/calendar/calendarView?startDateTime=${startDatetime.toIsoString()}&endDateTime=${endDatetime.toIsoString()}&$top=100`
    while (url !== "") {
      url = url.replace("https://graph.microsoft.com/v1.0/", "")
      const ret = await this._client.getAsync<{
        "@odata.nextLink": string | null
        value: Array<MicrosoftGraph.Event>
      }>(url)
      url = ret["@odata.nextLink"] || ""
      rets.push(...ret.value)
    }
    return rets
  }

  getEventByICalUid = async (
    iCalUid: string,
    startDatetime: DateTime,
    endDatetime: DateTime
  ): Promise<MicrosoftGraph.Event> => {
    const ret = await this._client.getAsync<{
      "@odata.nextLink": string | null
      value: Array<MicrosoftGraph.Event>
    }>(
      `/me/calendar/calendarView?startDateTime=${startDatetime.toIsoString()}&endDateTime=${endDatetime.toIsoString()}&$top=1&$filter=iCalUid eq '${iCalUid}'`
    )
    return ret.value[0]
  }

  getRepeats = async (
    id: string,
    startDatetime: DateTime,
    endDatetime: DateTime
  ): Promise<Array<MicrosoftGraph.Event>> => {
    const rets = []
    let url = `/me/events/${id}/instances?startDateTime=${startDatetime.toIsoString()}&endDateTime=${endDatetime.toIsoString()}&$top=999`
    while (url !== "") {
      url = url.replace("https://graph.microsoft.com/v1.0/", "")
      const ret = await this._client.getAsync<{
        "@odata.nextLink": string | null
        value: Array<MicrosoftGraph.Event>
      }>(url)
      url = ret["@odata.nextLink"] || ""
      rets.push(...ret.value)
    }
    return rets
  }

  createEvent = async (event: Meeting, body = "") => {
    const ret = await this._client.postAsync<MicrosoftGraph.Event>(
      "/me/events/",
      JSON.stringify({
        subject: event.subject,
        body: {
          contentType: "html",
          content: body,
        },
        isOnlineMeeting: !!event.isTeams,
        location: {
          displayName: event.location ?? "",
        },
        start: {
          dateTime: event.startTime.toDateTimeString(),
          timeZone: "Tokyo Standard Time",
        },
        end: {
          dateTime: event.endTime.toDateTimeString(),
          timeZone: "Tokyo Standard Time",
        },
        attendees: event.users.map(u => ({
          emailAddress: {
            address: u.email,
            name: u.displayName,
          },
          type: "required",
        })),
      } as MicrosoftGraph.Event)
    )
    return new Meeting({
      iCaluid: ret.iCalUId,
      isTeams: ret.isOnlineMeeting,
      subject: ret.subject,
      location: ret.location?.displayName,
      startTime: new DateTime(ret.start?.dateTime),
      endTime: new DateTime(ret.end?.dateTime),
      userIds: event.users.map(u => u.userId),
      users: event.users,
    } as Meeting)
  }
}

export default EventApi
