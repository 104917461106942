import MsGraphClient from "./clients/MsGraphClient"
import * as MicrosoftGraph from "@microsoft/microsoft-graph-types"
import GraphArrayResult, { getNextLinkPath } from "@/models/GraphArrayResult"

interface SearchHitListItem extends MicrosoftGraph.SearchHit {
  resource: MicrosoftGraph.ListItem
}

export interface SearchResult {
  value: Array<{
    searchTerms: Array<string>
    hitsContainers: Array<{
      total: number
      moreResultsAvailable: boolean
      hits: Array<SearchHitListItem>
    }>
  }>
}

class ListItemApi {
  private client = new MsGraphClient()

  createListItem = async (siteId: string, listId: string, fields: object) => {
    const list = await this.client.postAsync<MicrosoftGraph.ListItem>(
      `sites/${siteId}/lists/${listId}/items`,
      JSON.stringify({
        fields,
      })
    )
    return list
  }

  updateListItem = async (
    siteId: string,
    listId: string,
    itemid: string,
    fields: object
  ) => {
    const list = await this.client.patchAsync<MicrosoftGraph.ListItem>(
      `sites/${siteId}/lists/${listId}/items/${itemid}`,
      JSON.stringify({
        fields,
      })
    )
    return list
  }

  deleteListItem = async (siteId: string, listId: string, itemid: string) => {
    await this.client.deleteAsync(
      `sites/${siteId}/lists/${listId}/items/${itemid}`
    )
  }

  deleteList = async (siteId: string, listId: string) => {
    await this.client.deleteAsync(`sites/${siteId}/lists/${listId}`)
  }

  getListItems = async (
    siteId: string,
    listId: string,
    fields: string[] = ["title", "users", "memo", "search"],
    getAll = true
  ) => {
    let listItems: Array<MicrosoftGraph.ListItem> = []
    let nextLink: string | null = null

    do {
      const url = nextLink
        ? nextLink
        : `sites/${siteId}/lists/${listId}/items?expand=fields(select=${fields.join(
            ","
          )})`
      const result: GraphArrayResult<MicrosoftGraph.ListItem> = await this.client.getAsync(
        url
      )
      listItems = listItems.concat(result.value)
      nextLink = getNextLinkPath(result)
    } while (nextLink && getAll)

    return listItems
  }

  getListItem = async (
    siteId: string,
    listId: string,
    itemId: string,
    groupid = ""
  ) => {
    const baseUrl = siteId ? `sites/${siteId}` : `groups/${groupid}/sites/root`
    const list = await this.client.getAsync<MicrosoftGraph.ListItem>(
      `${baseUrl}/lists/${listId}/items/${itemId}`
    )
    return list
  }

  searchListItem = async (
    listWebUrls: Array<string>,
    queryString: string,
    from = 0,
    size = 25
  ) => {
    const pathUrls = listWebUrls
      .map(listWebUrl => `path:${listWebUrl}`)
      .join(" OR ")
    const list = await this.client.postAsync<SearchResult>(
      `search/query`,
      JSON.stringify({
        requests: [
          {
            entityTypes: ["listItem"],
            fields: ["sharepointids", "title"],
            query: {
              queryString: `(${pathUrls}) AND ${queryString} `,
            },
            from,
            size,
          },
        ],
      })
    )
    return list
  }

  getListItemVersionsLatestFirst = async (
    siteId: string,
    listId: string,
    itemId: string,
    fields: string[] = ["title", "users", "memo", "search", "modified"]
  ): Promise<Array<MicrosoftGraph.ListItemVersion>> => {
    const response = await this.client.getAsync<{
      value: Array<MicrosoftGraph.ListItemVersion>
    }>(
      `sites/${siteId}/lists/${listId}/items/${itemId}/versions?expand=fields(select=${fields.join(
        ","
      )})`
    )
    return response.value
  }
}

export default ListItemApi
