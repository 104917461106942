export const required = (value: string) => {
  if (value.trim() === "") return "必須入力です"
  return true
}

export const isAbsoluteUrl = (value: string): boolean => {
  try {
    new URL(value)
    return true
  } catch {
    return false
  }
}
