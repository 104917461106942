
















import { alertContainer } from "@/containers/AlertContainer"
import { defineComponent, reactive, watch } from "@vue/composition-api"

interface State {
  showAlert: boolean
}

export default defineComponent({
  props: {
    alertType: {
      type: String,
      default: "success",
    },
    value: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
    },
  },

  setup(props, { emit }) {
    const { hideMessage } = alertContainer.useContainer()

    const state = reactive<State>({
      showAlert: props.value,
    })

    const emitInput = () => {
      emit("input", state.showAlert)
    }

    const onShowAlertChanged = emitInput

    watch(
      () => [props.value, state.showAlert],
      ([newValue, newState]) => {
        if (state.showAlert !== newValue) state.showAlert = newValue
        // 1.5秒で消す
        if (newState) {
          setTimeout(() => {
            hideMessage()
          }, 1500)
        }
      }
    )

    return { state, onShowAlertChanged }
  },
})
