import MsGraphClient from "./clients/MsGraphClient"
import * as MicrosoftGraph from "@microsoft/microsoft-graph-types"

class TeamApi {
  private _client = new MsGraphClient()

  getGroupIds = async () => {
    const ret = await this._client.postAsync<{
      value: Array<string>
    }>(
      "me/getMemberGroups",
      JSON.stringify({
        securityEnabledOnly: false,
      })
    )
    return ret.value
  }

  getTeam = async (groupId: string) => {
    const ret = await this._client.getAsync<MicrosoftGraph.Group>(
      `teams/${groupId}`
    )
    return ret
  }

  getMembers = async (groupId: string, channelId: string) => {
    const rets = []
    let url =
      window.tokenVersion === 2
        ? `teams/${groupId}/channels/${channelId}/members`
        : `teams/${groupId}/members`
    while (url !== "") {
      url = url.replace("https://graph.microsoft.com/v1.0/", "")
      const ret = await this._client.getAsync<{
        "@odata.nextLink": string | null
        value: Array<MicrosoftGraph.AadUserConversationMember>
      }>(url)
      url = ret["@odata.nextLink"] || ""
      rets.push(...ret.value)
    }
    return rets
  }

  getMyTeams = async () => {
    const ret = await this._client.getAsync<{
      value: Array<MicrosoftGraph.Group>
    }>(`me/joinedTeams`)
    return ret.value
  }

  getTeamChannels = async (teamId: string) => {
    const ret = await this._client.getAsync<{
      value: Array<MicrosoftGraph.Channel>
    }>(`teams/${teamId}/channels`)
    return ret.value
  }
}

export default TeamApi
