import SofieClient from "./clients/SofieClient"
import {
  Meeting,
  MeetingStructure,
  MeetingStructureSummary,
} from "@/models/Meeting"

class MeetingApi {
  private _sofieClient = new SofieClient()

  getMeetingsSummary = async (listId: string) => {
    const meetings = await this._sofieClient.getAsync<Array<MeetingStructure>>(
      `projects/${listId}/meetings`
    )
    return meetings.map(m => new MeetingStructure(m))
  }

  getAllMeetingsSummary = async () => {
    const meetings = await this._sofieClient.getAsync<
      Array<MeetingStructureSummary>
    >(`meetingstructure`)
    return meetings.map(m => new MeetingStructureSummary(m))
  }

  createMeetingStructure = async (
    listId: string,
    meetingStructure: MeetingStructure
  ) => {
    const meetings = await this._sofieClient.postAsync<MeetingStructure>(
      `projects/${listId}/meetings`,
      JSON.stringify(meetingStructure)
    )
    return meetings
  }

  updateMeetingStructure = async (
    listId: string,
    meetingStructureId: string,
    meetingStructure: MeetingStructure
  ) => {
    const meetings = await this._sofieClient.patchAsync<MeetingStructure>(
      `projects/${listId}/meetings/${meetingStructureId}`,
      JSON.stringify(meetingStructure)
    )
    return meetings
  }

  deleteMeetingStructure = async (
    listId: string,
    meetingStructureId: string
  ) => {
    await this._sofieClient.deleteAsync(
      `projects/${listId}/meetings/${meetingStructureId}`
    )
  }

  createMeeting = async (
    listId: string,
    structureId: string,
    meeting: Meeting
  ) => {
    const meetings = await this._sofieClient.postAsync<Meeting>(
      `projects/${listId}/meetings/${structureId}/items`,
      JSON.stringify({
        startTime: meeting.startTime.toIsoString(),
        endTime: meeting.endTime.toIsoString(),
        iCaluid: meeting.iCaluid,
        sharepointItemId: meeting.sharepointItemId,
        userIds: meeting.userIds,
        isTeams: meeting.isTeams,
        location: meeting.location,
        purpose: meeting.purpose,
        goal: meeting.goal,
        agendas: meeting.agendas,
      })
    )
    return new Meeting(meetings)
  }

  updateMeeting = async (
    listId: string,
    structureId: string,
    meeting: Meeting
  ) => {
    const meetings = await this._sofieClient.patchAsync<Meeting>(
      `projects/${listId}/meetings/${structureId}/items/${meeting.id}`,
      JSON.stringify({
        startTime: meeting.startTime.toIsoString(),
        endTime: meeting.endTime.toIsoString(),
        iCaluid: meeting.iCaluid,
        sharepointItemId: meeting.sharepointItemId,
        userIds: meeting.userIds,
        isTeams: meeting.isTeams,
        location: meeting.location,
        purpose: meeting.purpose,
        goal: meeting.goal,
        agendas: meeting.agendas,
      })
    )
    return new Meeting(meetings)
  }

  deleteMeeting = async (
    listId: string,
    meetingStructureId: string,
    id: string
  ) => {
    await this._sofieClient.deleteAsync(
      `projects/${listId}/meetings/${meetingStructureId}/items/${id}`
    )
  }
}

export default MeetingApi
