import { createContainer } from "./Container"
import { Meeting } from "@/models/Meeting"
import FileApi, { getCleanPath } from "@/api/FileApi"
import DateTime from "@/models/DateTime"
import Minutes from "@/models/Minutes"
import MemoWordApi from "@/api/MemoWordApi"
import MemoPdfApi from "@/api/MemoPdfApi"
import ApplicationInformation from "@/models/ApplicationInformation"

const fileApi = new FileApi()

interface State {
  isInited: boolean
}
const notesFolderPath = "/teirei-assist_temp"

const headerTemplate =
  '<table border="1" style="border-collapse: collapse; width: 100%;" >' +
  '<tr><td style="background-color:#D9E2F3; width:120px"><b>&nbsp;会議名</b></td><td style="padding:5px">{SUBJECT}</td></tr>' +
  '<tr><td style="background-color:#D9E2F3"><b>&nbsp;日時</b></td><td style="padding:5px">{MEETING_DATE}</td></tr>' +
  '<tr><td style="background-color:#D9E2F3"><b>&nbsp;場所</b></td><td style="padding:5px">{LOCATION}</td></tr>' +
  '<tr><td style="background-color:#D9E2F3"><b>&nbsp;参加者</b></td><td style="padding:5px">{ATTENDEES}</td></tr>' +
  '<tr><td style="background-color:#D9E2F3"><b>&nbsp;利用ファイル</b></td><td style="padding:5px">{FILES}</td></tr>' +
  "</table>"
const bodyTemplate =
  '<table border="1" style="border-collapse: collapse; width: 100%;" >' +
  '<tr style="background-color:#D9E2F3"><td><b>&nbsp;議事</b></td></tr><tr><td style="padding:5px">' +
  "{BODY}" +
  "</td></tr></table>"
const htmlTemplate =
  "<!DOCTYPE html><html>" +
  '<head><meta charset="utf-8"></head><body>' +
  "<h1>議事録</h1>" +
  "{BODY}" +
  "</body></html>"

function minutesState() {
  const memoPdfApi = new MemoPdfApi(
    (window.aplicationInfo as ApplicationInformation).pdfEndpointUrl
  )
  const memoWordApi = new MemoWordApi()

  const exportType = {
    Pdf: 0,
    Word: 1,
  }
  const uploadMinutesPdfAsync = async (
    siteId: string,
    meeting: Meeting,
    blob: Blob,
    type: string
  ) => {
    const targetPath = `${notesFolderPath}/${getCleanPath(meeting.subject)}_${
      meeting.id
    }`
    await fileApi.createFolderIfNotExist(siteId, targetPath)
    const currentTimeStamp = new DateTime(new Date())
      .toDateTimeString()
      .replace(/:/g, "-")
    const file = new File([blob], `議事録_${currentTimeStamp}.${type}`)
    const uploadedFile = await fileApi.fileUpload(siteId, targetPath, file)
    return uploadedFile
  }

  const styleWordHtml = (memo: string, minute: Minutes): string => {
    const header = headerTemplate
      .replace("{SUBJECT}", minute.title)
      .replace("{MEETING_DATE}", minute.date)
      .replace("{LOCATION}", minute.location)
      .replace("{ATTENDEES}", minute.users)
      .replace("{FILES}", minute.files)
    const body = bodyTemplate.replace("{BODY}", memo)
    const html = htmlTemplate.replace("{BODY}", header + "<br>" + body)
    return html
  }

  const getMinutesAsync = async (minute: Minutes, memo: string) => {
    const isPDF = exportType.Pdf === minute.type
    const blob = await (isPDF
      ? memoPdfApi.getMeetingMinutesPdf(minute, memo)
      : memoWordApi.getMeetingMinutesWord(styleWordHtml(memo, minute)))
    return blob
  }

  return {
    exportType,
    styleWordHtml,
    getMinutesAsync,
    uploadMinutesPdfAsync,
  }
}

type minutesStore = ReturnType<typeof minutesState>

/**
 * @constant
 * Answererコンテナ
 */
export const minutesContainer = createContainer<minutesStore, State>(
  minutesState
)
