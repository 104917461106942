






















import { computed, defineComponent, onMounted, ref } from "@vue/composition-api"
import AppNavigation from "./AppNavigation.vue"
import AppBody from "./AppBody.vue"
import ErrorBoundary from "@/components/ErrorBoundary.vue"
import Hints from "@/components/hints/Hints.vue"
import {
  addBeforeResolveGuard as routerAddBeforeResolveGuard,
  addAfterEachGuard,
  Route,
} from "./router"
import RouteName, { routeNameToString } from "./constants/RouteName"
import Loading from "./components/Loading.vue"
import SofieTokenProvider from "./utilities/SofieTokenProvider"
import DefaultAlert from "@/components/alerts/DefaultAlert.vue"
import { appContainer } from "@/containers/AppContainer"
import { i18nContainer } from "@/containers/I18nContainer"
import { meetingContainer } from "./containers/MeetingsContainer"
import { listStructureContainer } from "@/containers/ListStructureContainer"
import { teamsContextContainer } from "@/containers/TeamsContextContainer"
import { projectsContainer } from "@/containers/ProjectsContainer"
import { taskContainer } from "@/containers/TaskContainer"
import { alertContainer } from "@/containers/AlertContainer"
import { memoContainer } from "@/containers/MemoContainer"
import { notificationContainer } from "@/containers/NotificationContainer"
import { hintContainer, HintType } from "@/containers/HintContainer"
import { memoSyncContainer } from "@/containers/MemoSyncContainer"
import { metricContainer } from "@/containers/MetricContainer"
import { workflowContainer } from "@/containers/WorkflowContainer"
import { minutesContainer } from "@/containers/MinutesContainer"
import { getQueryParam } from "@/utilities/URIHelper"
import TeamsContext from "./models/TeamsContext"
import azureAdAuthentication from "@/utilities/AzureAdAuthentication"
import { Project } from "./models/Project"
import ApplicationError from "./models/Errors/ApplicationError"

const sofieTokenProvider = new SofieTokenProvider()

export default defineComponent({
  components: {
    DefaultAlert,
    AppNavigation,
    AppBody,
    ErrorBoundary,
    Loading,
    Hints,
  },
  setup() {
    appContainer.provide()
    i18nContainer.provide()
    const { syncStructure } = listStructureContainer.provide()
    const { setContext, getContext } = teamsContextContainer.provide()
    meetingContainer.provide()
    const {
      getProjectOrNullAsync,
      updateProjectAsync,
      getProjectSiteAsync,
    } = projectsContainer.provide()
    const { state: alertState } = alertContainer.provide()
    const {
      isAuthenticated,
      setIsAuthenticated,
      getBeforePage,
      setBeforePage,
      isRealPage,
    } = appContainer.useContainer()
    memoContainer.provide()
    taskContainer.provide()
    notificationContainer.provide()
    const { showUpdateInfomationHint, showHint } = hintContainer.provide()
    memoSyncContainer.provide()
    const { CreateAccessMetric } = metricContainer.provide()
    workflowContainer.provide()
    minutesContainer.provide()

    const alert = computed(() => {
      return {
        type: alertState.type.value,
        showAlert: alertState.showAlert.value,
        message: alertState.message.value,
      }
    })

    const routeHideAppNavigation = ref(true)

    const isNavigationHidden = computed<boolean>(
      () => routeHideAppNavigation.value || !isAuthenticated.value
    )

    const syncData = async () => {
      const context = await getContext()
      if (!context.entityId) return
      if (
        /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
          context.entityId
        )
      ) {
        const [project, site] = await Promise.all([
          getProjectOrNullAsync(context.entityId),
          getProjectSiteAsync(context.teamSiteDomain, context.teamSitePath),
        ])
        // 登録前なのでスルー
        if (!project) return
        if (!site.id) {
          throw new Error("Sharepoint Siteが見つかりません")
        }
        const listValue = await syncStructure(site.id, context.entityId)

        if (
          project.groupId !== context.groupId ||
          project.siteId !== site.id ||
          project.sharepointListId !== context.entityId ||
          project.appId !== context.appId ||
          project.teamId !== context.teamId ||
          project.channelId !== context.channelId ||
          project.isPrivate !== context.isPrivate
        ) {
          const updatedProject = new Project(
            Object.assign(project, {
              groupId: context.groupId,
              siteId: site.id,
              sharepointListId: context.entityId,
              sharepointListWeburl: listValue?.webUrl,
              appId: context.appId,
              teamId: context.teamId,
              channelId: context.channelId,
              isPrivate: context.isPrivate,
            })
          )
          await updateProjectAsync(project.id, updatedProject)
        }
      }
    }
    const checkEnoughToken = async () => {
      if (!window.tokenVersion) {
        await sofieTokenProvider.getCustomerGraphApiTokenAsync()
      }
      const context = await getContext()
      if (window.tokenVersion == 1 && context.isPrivate) {
        throw new ApplicationError({
          message: `権限が不足しているため利用できません。

・管理者によるアプリの承認がされていない
・Exchange Onlineのライセンスがない`,
          showMessageModal: true,
          showDetail: false,
        })
      }
    }

    routerAddBeforeResolveGuard(async (to, _from, next) => {
      try {
        const toRequiresAuthentication = to.matched.some(
          m => m.meta.requiresAuthentication
        )
        if (toRequiresAuthentication) {
          if (isAuthenticated.value) {
            try {
              await sofieTokenProvider.getTokenAsync()
              await checkEnoughToken()
              next()
              return
            } catch (error) {
              if (error instanceof ApplicationError) {
                throw error
              } else {
                setIsAuthenticated(false)
              }
            }
          }
          const context = await getContext()
          const currentUrl = window.inTheTeamsIFrame
            ? getBeforePage(to, context.entityId)
            : null
          next({
            name: routeNameToString(
              window.inTheTeamsIFrame
                ? RouteName.Authenticate
                : RouteName.AuthenticateBrowse
            ),
            query: {
              redirect: decodeURIComponent(currentUrl || to.fullPath),
            },
          })
          return
        } else {
          const context = await getContext()
          if (context.subEntityId && context.subEntityId.startsWith("/")) {
            const currentUrl =
              window.location.pathname && window.location.pathname !== "/"
                ? window.location.pathname
                : `${context.subEntityId}`
            context.subEntityId = ""
            setContext(context)
            next({ path: currentUrl })
          } else {
            next()
          }
          return
        }
      } catch (error) {
        if (error instanceof ApplicationError) {
          throw error
        }
        // 失敗した時、下で「/」に遷移する
      }

      window.location.assign("/")
    })

    routerAddBeforeResolveGuard(async (to, _from, next) => {
      routeHideAppNavigation.value = to.matched.some(
        m => m.meta.hideAppNavigation
      )
      next()
    })

    addAfterEachGuard(async (to: Route) => {
      const context = await getContext()
      setBeforePage(to, context.entityId)
      if (isRealPage(to) && showUpdateInfomationHint()) {
        showHint(HintType.Updates)
      }
    })

    if (!window.inTheTeamsIFrame) {
      const ctx = getQueryParam("ctx")
      if (ctx) {
        const context = JSON.parse(
          decodeURIComponent(atob(ctx))
        ) as TeamsContext
        context.userObjectId =
          azureAdAuthentication.getLogedInAccount()?.localAccountId || ""
        setContext(context)
      }
    }

    onMounted(async () => {
      const context = await getContext()
      if (window.appInsights && context.userObjectId)
        window.appInsights.setAuthenticatedUserContext(context.userObjectId)
      CreateAccessMetric(context.tid, context.userObjectId)
      if ((context.isPrivate || context.groupId) && context.entityId)
        await syncData()
    })

    return {
      alert,
      isNavigationHidden,
    }
  },
})
