import {
  app as microsoftTeams,
  pages,
  authentication,
} from "@microsoft/teams-js"

import { reactive, toRefs } from "@vue/composition-api"
import { createContainer } from "./Container"
import TeamApi from "@/api/TeamApi"
import FileApi from "@/api/FileApi"
import UserApi from "@/api/UserApi"
import ChatApi from "@/api/ChatApi"
import {
  AadUserConversationMember,
  Channel,
  ConversationMember,
  Group,
  User,
} from "@microsoft/microsoft-graph-types"
import TeamsContext from "@/models/TeamsContext"
import ProjectFile from "@/models/ProjectFile"

const api = new TeamApi()
const fileApi = new FileApi()
const userApi = new UserApi()
const chatApi = new ChatApi()

interface State {
  context: TeamsContext | null
  members: { [key: string]: Array<ConversationMember> }
  teams: Array<{
    team: Group
    channels: Channel[]
  }> | null
}

function useTeamsContext(props?: State | null) {
  const state = reactive<State>(
    props ?? { context: null, members: {}, teams: null }
  )

  const setContext = (context: TeamsContext) => {
    state.context = context
  }

  const getMyTeams = async (
    targetTeams: Array<string> | null = null
  ): Promise<{
    team: Group
    channels: Channel[]
  }[]> => {
    const teams =
      state.teams ??
      (await api.getMyTeams()).map(team => ({ team, channels: [] }))
    const rets = await Promise.all(
      teams.map(async team => {
        const channels =
          targetTeams === null || targetTeams.some(t => t === team.team.id)
            ? team.channels.length
              ? team.channels
              : await api.getTeamChannels(team.team.id ?? "")
            : team.channels
        return {
          team: team.team,
          channels,
        }
      })
    )
    state.teams = rets
    return state.teams
  }

  const getContext = async (): Promise<TeamsContext> => {
    if (state.context) return state.context
    const context = await microsoftTeams.getContext()
    const tabs = await pages.tabs.getMruTabInstances()
    let appId = ""
    const targetTab =
      tabs.teamTabs.find(t => t.entityId === context.page?.id) ??
      tabs.teamTabs.length > 0
        ? tabs.teamTabs[0]
        : null
    if (targetTab) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      appId = (targetTab as any).appId || ""
    }
    const ret = new TeamsContext(context, appId)
    if (appId) state.context = ret
    return Object.assign(ret, {
      context: context,
      tabs: tabs,
      page: context.page,
    })
  }

  const getDeepLinkUrl = async (targetUrl: string) => {
    const teamsContext = await getContext()
    const entityId = encodeURI(teamsContext.entityId)
    const urlContext = {
      subEntityId: targetUrl,
      channelId: teamsContext.channelId,
    }
    const encodedContext = encodeURI(JSON.stringify(urlContext))
    return `https://teams.microsoft.com/l/entity/${teamsContext.appId}/${entityId}?context=${encodedContext}`
  }

  const notifySuccessnotifySuccess = (result?: string) => {
    if (window.inTheTeamsIFrame) {
      authentication.notifySuccess(result)
    } else {
      const event = new CustomEvent("successAuth", {
        detail: {
          result,
        },
      })
      window.document.dispatchEvent(event)
    }
  }

  const notifyFailure = (reason?: string) => {
    if (window.inTheTeamsIFrame) {
      authentication.notifyFailure(reason)
    } else {
      const event = new CustomEvent("failureAuth", {
        detail: {
          reason,
        },
      })
      window.document.dispatchEvent(event)
    }
  }

  const getMembers = async (
    group: string | null = null,
    channelId: string | null = null
  ): Promise<Array<AadUserConversationMember>> => {
    const context = await getContext()
    const grpId = group || context.groupId || ""
    const chId = channelId || context.channelId
    const key = `${grpId}_${chId}`
    if (state.members[key]) {
      return state.members[key]
    }
    if (key) {
      state.members[key] = (await api.getMembers(grpId, chId)).filter(
        u => u.displayName && u.email
      )
    }
    return state.members[key]
  }

  const getUser = async (userid: string): Promise<User | undefined> => {
    return await userApi.getUser(userid)
  }

  const getFiles = async (
    siteId: string,
    filePath: string
  ): Promise<Array<ProjectFile>> => {
    const files = await fileApi.getFileItems(siteId, filePath)
    return files.map(f => new ProjectFile(f))
    return []
  }

  const deleteFiles = async (siteId: string, files: Array<ProjectFile>) => {
    await Promise.all(
      files.map(async f => {
        if (f.id) return await fileApi.deleteFileItem(siteId, f.id)
        else {
          const folderFiles = await await fileApi.getFileItems(
            siteId,
            f.parentPath
          )
          const targetFile = folderFiles.find(file => file.name === f.name)
          if (targetFile && targetFile.id)
            return await fileApi.deleteFileItem(siteId, targetFile.id)
        }
      })
    )
  }

  const createFiles = async (
    siteId: string,
    path: string,
    addFiles: Array<File>,
    progress: ((file: File, progress: number) => void) | null = null
  ) => {
    // 4メガバイト以上は逐次アップロードしなくちゃいけない
    const boarderSize = 4000000
    for (const adf of addFiles) {
      if (adf.size <= boarderSize) {
        await fileApi.uploadSmallFile(siteId, path, adf)
        if (progress) progress(adf, adf.size)
      } else {
        await fileApi.uploadFile(siteId, path, adf, (currentSize: number) => {
          if (progress) progress(adf, currentSize)
        })
      }
    }
  }

  const sendMessage = async (
    message: string,
    mentionUsers: Array<AadUserConversationMember> = []
  ): Promise<void> => {
    const context = await getContext()
    if (context.isPrivate) {
      await chatApi.sendMessageUseGraph({
        message,
        channelId: context.channelId,
        teamId: context.teamId,
        mentionUsers: mentionUsers.map(u => ({
          id: u.userId || "",
          displayName: u.displayName || "",
        })),
      })
    } else {
      await chatApi.sendMessage({
        message,
        channelId: context.channelId,
        teamId: context.teamId,
        mentionUserIds: mentionUsers.map(u => u.userId || ""),
      })
    }
  }

  const getTeams = async (
    targetTeams: Array<string>
  ): Promise<{
    team: Group
    channels: Channel[]
  }[]> => {
    const targets = targetTeams.reduce((a, b) => {
      if (!a.includes(b)) a.push(b)
      return a
    }, [] as Array<string>)
    const rets = await Promise.all(
      targets.map(async t => {
        const cacheTeam = state.teams?.find(te => te.team.id === t)
        try {
          const team = cacheTeam?.team ?? (await api.getTeam(t))
          const channels = cacheTeam?.channels.length
            ? cacheTeam.channels
            : await api.getTeamChannels(t)
          return {
            team,
            channels,
          }
        } catch (e) {
          console.error(e)
          return {
            team: null,
            channels: [] as Channel[],
          }
        }
      })
    )
    const retTeams = rets.filter(r => !!r.team) as Array<{
      team: Group
      channels: Channel[]
    }>
    if (state.teams) {
      for (const r of retTeams) {
        const index = state.teams.findIndex(t => t.team.id === r.team.id)
        if (index >= 0) state.teams[index] = r
        else state.teams.push(r)
      }
    } else {
      state.teams = retTeams
    }
    return state.teams
  }

  return {
    state: toRefs(state),
    getUser,
    getFiles,
    deleteFiles,
    createFiles,
    setContext,
    getContext,
    getMembers,
    getDeepLinkUrl,
    getMyTeams,
    getTeams,
    notifyFailure,
    notifySuccessnotifySuccess,
    sendMessage,
  }
}

type useTeamsContextStore = ReturnType<typeof useTeamsContext>

/**
 * @constant
 * Answererコンテナ
 */
export const teamsContextContainer = createContainer<
  useTeamsContextStore,
  State
>(useTeamsContext)
