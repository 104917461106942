import HttpClient, { HttpRequestOptions } from "./clients/HttpClient"
import DateTime from "@/models/DateTime"
import Minutes from "@/models/Minutes"

class MemoPdfApi {
  client: HttpClient

  constructor(baseUrl: string) {
    this.client = new HttpClient({ baseUrl })
  }

  async getMeetingMinutesPdf(
    minutes: Minutes,
    meetingMemoHtml: string
  ): Promise<Blob> {
    const options: HttpRequestOptions = {}
    return new Promise(resolve => {
      this.client.postAsync<void>(
        "download",
        JSON.stringify({
          date: new DateTime(new Date()).toDateJpString(),
          "meeting-subject": minutes.title ?? "",
          "meeting-date": minutes.date ?? "",
          "meeting-location": minutes.location ?? "",
          "meeting-users": minutes.users ?? "",
          "meeting-files": minutes.files ?? "",
          "meeting-memo": meetingMemoHtml,
        }),
        options,
        async (r: Response) => {
          const blob = await r.blob()
          resolve(blob)
        }
      )
    })
  }

  async getMeetingApproverPdf(value: Array<object>): Promise<Blob> {
    const options: HttpRequestOptions = {}
    return new Promise(resolve => {
      this.client.postAsync<void>(
        "download",
        JSON.stringify({
          templateFileName: "approver.html",
          approvers: value,
        }),
        options,
        async (r: Response) => {
          const blob = await r.blob()
          resolve(blob)
        }
      )
    })
  }
}

export default MemoPdfApi
