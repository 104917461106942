import ApiError from "@/models/Errors/ApiError"
import SofieClient from "./clients/SofieClient"
import Tenant from "@/models/Tenant"

class TenantApi {
  private _sofieClient = new SofieClient()

  getTenant = async (): Promise<Tenant | null> => {
    try {
      return await this._sofieClient.getAsync<Tenant>(`tenants`)
    } catch (e) {
      if (e instanceof ApiError) {
        if (e.status === 404) return null
      }
      throw e
    }
  }
}

export default TenantApi
