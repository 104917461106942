import { app } from "@microsoft/teams-js"

export default class TeamsContext {
  appId: string
  rootFolder: string
  teamId: string
  tid: string
  subEntityId: string
  entityId: string
  groupId: string
  userObjectId: string
  channelId: string
  isPrivate: boolean
  teamSiteDomain: string
  teamSitePath: string

  constructor(c: app.Context, appId: string) {
    const folders = c.channel?.relativeUrl?.split("/") ?? []
    this.appId = appId
    this.rootFolder =
      folders.length !== 0 ? `/${folders[folders?.length - 1]}` : "/"
    this.subEntityId = c.page.subPageId || ""
    this.tid = c.user?.tenant?.id || ""
    this.teamId = c.team?.internalId || ""
    this.entityId = c.page.id
    this.groupId = c.team?.groupId || c.channel?.ownerGroupId || ""
    this.userObjectId = c.user?.id || ""
    this.channelId = c.channel?.id || ""
    this.isPrivate = c.channel?.membershipType === "Private"
    this.teamSiteDomain = c.sharePointSite?.teamSiteDomain || ""
    this.teamSitePath = c.sharePointSite?.teamSitePath || ""
  }
}
