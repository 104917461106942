export const Limitation = Object.freeze({
  Default: 0,
  CharacterLimit: 1,
})

export class Project {
  id: string
  groupId: string
  sharepointListId: string
  sharepointListWeburl: string
  appId: string
  teamId: string
  siteId: string
  channelId: string
  noLimitation: number
  isPrivate: boolean

  hasFlag(limitaion: typeof Limitation[keyof typeof Limitation]) {
    return this.noLimitation && this.noLimitation & limitaion
  }

  constructor(input: Project) {
    this.id = input.id
    this.groupId = input.groupId
    this.sharepointListId = input.sharepointListId
    this.sharepointListWeburl = input.sharepointListWeburl
    this.appId = input.appId
    this.teamId = input.teamId
    this.siteId = input.siteId
    this.channelId = input.channelId
    this.noLimitation = input.noLimitation
    this.isPrivate = input.isPrivate
  }
}
