if (!Intl.PluralRules) {
  require("@formatjs/intl-pluralrules/polyfill")
  require("@formatjs/intl-pluralrules/locale-data/ja")
  require("@formatjs/intl-pluralrules/locale-data/en")
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
if (!(Intl as any).RelativeTimeFormat) {
  require("@formatjs/intl-relativetimeformat/polyfill")
  require("@formatjs/intl-relativetimeformat/locale-data/ja")
  require("@formatjs/intl-relativetimeformat/locale-data/en")
}

import Vue from "vue"
import "./plugins/vueCompositionApi"
import router from "./plugins/vueRouter"
import vuetify from "./plugins/vuetify"
import App from "./App.vue"
import { app as microsoftTeams } from "@microsoft/teams-js"
import azureAdAuthentication from "./utilities/AzureAdAuthentication"
import ApplicationInformationApi from "@/api/ApplicationInformationApi"
import { getQueryParam } from "@/utilities/URIHelper"
import { ApplicationInsights } from "@microsoft/applicationinsights-web"
import { ClickAnalyticsPlugin } from "@microsoft/applicationinsights-clickanalytics-js"

Vue.config.productionTip = false

const tid = getQueryParam("tid")
const mode = getQueryParam("mode")
const adminConcent = getQueryParam("admin_consent")
if (adminConcent && adminConcent === "True") {
  const error = getQueryParam("error")
  if (error) {
    document.write(
      "<h3 style='padding: 8px;'>定例会議アシスタントのアクセス許可の設定に失敗しました。<br/>再度実施して下さい。</h3>"
    )
  } else {
    document.write(
      "<h3 style='padding: 8px;'>定例会議アシスタントをご利用いただきありがとうございます。<br/>アクセス許可の設定が完了しました。</h3>"
    )
  }
} else {
  window.inTheTeamsIFrame = mode !== "browser"
  if (window.inTheTeamsIFrame) microsoftTeams.initialize()
  const api = new ApplicationInformationApi()
  api.getApplicationInformationAsync().then(aplication => {
    if (!aplication) throw new Error("失敗しました")
    window.aplicationInfo = aplication
    // application insights
    if (aplication.applicationInsightsKey) {
      const clickPluginInstance = new ClickAnalyticsPlugin()
      const clickPluginConfig = {
        autoCapture: true,
        dataTags: {
          useDefaultContentNameOrId: true,
        },
      }
      const configObj = {
        instrumentationKey: aplication.applicationInsightsKey,
        enableUnhandledPromiseRejectionTracking: true,
        disableFetchTracking: false,
        extensions: [clickPluginInstance],
        extensionConfig: {
          [clickPluginInstance.identifier]: clickPluginConfig,
        },
      }
      window.appInsights = new ApplicationInsights({ config: configObj })
      window.appInsights.loadAppInsights()
    }
    // ここまで
    if (!window.inTheTeamsIFrame) {
      azureAdAuthentication.init(
        Object.assign(aplication, {
          authority: tid
            ? `https://login.microsoftonline.com/${tid}/`
            : aplication.authority,
        }),
        {
          scopes: aplication.scopes.split(","),
        }
      )
    }
    new Vue({
      router,
      vuetify,
      render: h => h(App),
    }).$mount("#app")
  })
}
