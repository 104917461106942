import { reactive, toRefs } from "@vue/composition-api"
import { createContainer } from "./Container"

export enum AlertType {
  Success = "success",
  Information = "information",
  Warning = "warning",
  Error = "error",
}

interface State {
  showAlert: boolean
  type: AlertType
  message: string
}

/**
 * @private
 * alertコンテナの定義
 * @param props - 既定以外の初期化が必要な場合に指定する
 */
function useAlert(props?: State | null) {
  //
  const state = reactive<State>(
    props ?? {
      showAlert: false,
      type: AlertType.Success,
      message: "",
    }
  )

  const showWarningMessage = (message: string) => {
    state.message = message
    state.type = AlertType.Warning
    state.showAlert = true
  }

  const showSuccessMessage = (message: string) => {
    state.message = message
    state.type = AlertType.Success
    state.showAlert = true
  }

  const hideMessage = () => {
    state.showAlert = false
    state.message = ""
  }

  return {
    state: toRefs(state),
    showSuccessMessage,
    showWarningMessage,
    hideMessage,
  }
}

type AlertStore = ReturnType<typeof useAlert>

/**
 * @constant
 * alertコンテナ
 */
export const alertContainer = createContainer<AlertStore, State>(useAlert)
