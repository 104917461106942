import ApiError from "@/models/Errors/ApiError"
import { CandidateDateJson, CandidateDate } from "@/models/CandidateDate"
import HttpClient from "./clients/HttpClient"
import DateTime from "@/models/DateTime"
import SofieTokenProvider from "@/utilities/SofieTokenProvider"
import ApplicationInformation from "@/models/ApplicationInformation"

export interface SofieRequestOptions {
  noToken?: boolean
  queryParameters?: URLSearchParams
  httpHeaders?: Record<string, string>
}

const sofieTokenProvider: SofieTokenProvider = new SofieTokenProvider()

class SukerakuApi {
  private _httpClient: HttpClient | null = null

  getClient = () => {
    if (!this._httpClient)
      this._httpClient = new HttpClient({
        baseUrl: `${
          (window.aplicationInfo as ApplicationInformation).sukerakuUrl
        }/api`,
      })
    return this._httpClient
  }

  getExternalMeetingDatas = async (start: DateTime, end: DateTime) => {
    try {
      const token = await sofieTokenProvider.getTokenAsync()
      const candidates = await this.getClient().getAsync<
        Array<CandidateDateJson>
      >(
        `external/meetings?start=${start.toIsoString()}&end=${end.toIsoString()}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      return candidates.map(c => new CandidateDate(c))
    } catch (e) {
      if (e instanceof ApiError) {
        if (e.status === 404) return null
      }
      throw e
    }
  }
}

export default SukerakuApi
