export default function copy(text: string) {
  if (navigator.clipboard) {
    return navigator.clipboard.writeText(text).catch(_ => {
      copyByElement(text)
    })
  }
  return copyByElement(text)
}

function copyByElement(text: string) {
  if (!document || !document.body) {
    return Promise.reject(
      new window.DOMException("The request is not allowed", "NotAllowedError")
    )
  }

  const span = document.createElement("span")
  span.textContent = text
  span.style.whiteSpace = "pre"
  document.body && document.body.appendChild(span)
  const selection = window.getSelection()
  const range = window.document.createRange()
  if (!selection) {
    return
  }
  selection.removeAllRanges()
  range.selectNode(span)
  selection.addRange(range)
  let success = false
  try {
    success = window.document.execCommand("copy")
  } catch (err) {
    console.log("error", err)
  }

  // Cleanup
  selection.removeAllRanges()
  window.document.body.removeChild(span)

  return success
    ? Promise.resolve()
    : Promise.reject(
        new window.DOMException("The request is not allowed", "NotAllowedError")
      )
}
