








import { defineComponent, onMounted } from "@vue/composition-api"
import FirstHint from "./FirstHint.vue"
import NeedUpdateHint from "./NeedUpdateHint.vue"
import UpdatesHints from "./UpdatesHints.vue"
import { hintContainer, HintType } from "@/containers/HintContainer"

export default defineComponent({
  components: {
    FirstHint,
    UpdatesHints,
    NeedUpdateHint,
  },
  setup() {
    const { showHint, needUpdateHint } = hintContainer.useContainer()
    onMounted(() => {
      if (needUpdateHint()) showHint(HintType.NeedUpdate)
    })
  },
})
