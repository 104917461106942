






























































import { hintContainer, HintType } from "@/containers/HintContainer"
import DefaultDialog from "@/components/dialogs/DefaultDialog.vue"
import TextButton from "@/components/buttons/TextButton.vue"
import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api"

interface State {
  open: Array<string>
  showHint: boolean
  updates: Array<{
    id: string
    class?: string
    name: string
    children: Array<{
      id?: string
      icon?: string
      name: string
      detail: string
      image?: string
      showDetail?: boolean
    }>
  }>
}

export default defineComponent({
  components: {
    DefaultDialog,
    TextButton,
  },
  setup() {
    const {
      state: hideState,
      setUpdateInfomationVersion,
      hideHint,
    } = hintContainer.useContainer()
    const state = reactive<State>({
      open: ["202206"],
      showHint:
        hideState.showHint.value && hideState.type.value === HintType.Updates,
      updates: [
        {
          id: "202206",
          class: "text-h6",
          name: "2022年6月 更新",
          children: [
            {
              name: "プライベートチャネルで利用できるようになりました",
              detail:
                'プライベートチャネルで"定例会議アシスト"のタブを追加できます。',
            },
            {
              name: "議事録をWord形式で出力できるようになりました",
              detail:
                "議事録の形式をWord・PDFどちらで出力するか選べます。(※ 承認依頼時の議事録はPDFのみとなります。)\nまた、議事録に出力する会議情報を編集できます。",
              image: "/img/updates/202206-minutes.gif",
            },
          ],
        },
        {
          id: "202201",
          class: "text-h6",
          name: "2022年2月 更新",
          children: [
            {
              name: "会議にアジェンダを追加できるようになりました",
              detail:
                "会議に目的・ゴール・議題を設定できます。\n 会議中画面で確認・変更可能です。",
            },
            {
              name: "会議画面で残り時間が表示されるようになりました",
              detail:
                "会議画面で会議の残り時間と議題の残り時間が表示されます。",
            },
          ],
        },
        {
          id: "202111",
          class: "text-h6",
          name: "2021年11月 更新",
          children: [
            {
              name: "議事メモにハイパーリンクが使えるようになりました",
              detail:
                "リンクを設定したい本文を選択し、リンクボタンをクリックするとURLが設定できます。",
              image: "/img/updates/202111-linksetting.gif",
            },
            {
              name: "会議画面からTeams会議へ参加できるようになりました",
              detail: "会議画面のヘッダーよりTeams会議へ参加できます。",
              image: "/img/updates/202111-openmeeting.gif",
            },
            {
              name: "ボットの投稿への通知を消せるようになりました",
              detail:
                "「準備依頼通知」,「開催直前通知」を一定時間経過後、自動で消せるようになりました。\n通知設定画面より設定可能ですのでご利用ください。",
            },
            {
              name: "参加者の選択が便利になりました",
              detail:
                "参加者選択がオートコンプリートで検索できるようになり、より探しやすくなりました。\nまた、「全選択」ボタンでチーム全員を入れる事ができるようになりました。",
            },
            {
              name:
                "承認者情報が記載されたPDFが会議画面からダウンロードできるようになりました",
              detail:
                "議事メモが承認済みの会議の場合は、\n会議画面のPDFダウンロードボタンでも承認者情報が記載されたPDFをダウンロードできるようになりました。",
            },
            {
              name: "議事のMicrosoft Teams投稿のメッセージ配置が変わりました",
              detail:
                "議事メモ内容を投稿上部に表示するようになりました。\nこれにより、折りたたまれた状態でも議事メモの冒頭を確認できるようになります。",
            },
            {
              name: "前回の会議へアクセスしやすくなりました",
              detail: "Top画面に前回の会議へのリンクが追加されました。",
            },
            {
              name: "文字サイズが大きくなりました",
              detail:
                "フォントの初期サイズが大きくなりMicrosoft Teamsと同様のサイズになりました。\nさらに倍率をあげたい場合はMicrosoft Teamsクライアントやブラウザの機能で倍率変更することで適用されます。",
            },
            {
              name: "議事メモの文字色が追加されました",
              detail: "オレンジ、ピンク、紫が追加になりました。",
            },
            {
              name: "過去のメモエリアの幅を任意に動かせるようになりました",
              detail:
                "過去のメモエリアをドラック＆ドロップで任意の幅に広げられるようになりました。",
              image: "/img/updates/202111-resize.gif",
            },
            {
              name: "議事メモを復元できるようになりました",
              detail:
                "バージョン履歴が残っているメモを復元できるようになりました。\n※承認中、承認済みのメモを復元することはできません。",
              image: "/img/updates/202111-memohistory.gif",
            },
          ],
        },
      ],
    })

    watch(
      () => hideState.showHint.value,
      newValue => {
        state.showHint = newValue && hideState.type.value === HintType.Updates
      }
    )

    onMounted(() => {
      state.updates = state.updates.map(i =>
        Object.assign({}, i, {
          children: i.children.map((c, index) =>
            Object.assign(
              {
                id: `${i.id}-${index}`,
              },
              c
            )
          ),
        })
      )
    })

    const closeHint = () => {
      setUpdateInfomationVersion()
      hideHint()
    }

    const showDetail = (id: string) => {
      const parentId = id.split("-")[0]
      state.updates = state.updates.map(p =>
        p.id === parentId
          ? Object.assign({}, p, {
              children: p.children.map(ch =>
                ch.id === id ? Object.assign({}, ch, { showDetail: true }) : ch
              ),
            })
          : p
      )
    }
    return { state, closeHint, showDetail }
  },
})
