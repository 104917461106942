import { reactive, toRefs } from "@vue/composition-api"
import { createContainer } from "./Container"
import Locale, {
  defaultLocale,
  localeIsValid,
  localeFromString,
  localeToString,
} from "@/constants/Locale"
import localesJson from "@/assets/locales.json"
import DateTime from "@/models/DateTime"
import IntlMessageFormat, {
  PrimitiveType as IntlPrimitiveType,
} from "intl-messageformat"
import areIntlLocalesSupported from "intl-locales-supported"
import RegularMeetingSolutionError from "@/models/RegularMeetingSolutionError"

interface State {
  locale: Locale
  timeZone: string
  messages: Record<string, string>
}

const messagesByLocale = localesJson as Record<string, Record<string, string>>

export type PrimitiveType = IntlPrimitiveType

export interface MessageDescriptor {
  id: string
  defaultMessage: string
}

export interface MessageValues extends Record<string, PrimitiveType> {}
export interface FormatDateTimeOptions extends Intl.DateTimeFormatOptions {}

function getDefaultState(): State {
  let locale: Locale = defaultLocale
  let messages: Record<string, string> = {}

  const browserLocale = getLocaleFromBrowser() || ""
  if (localeIsValid(browserLocale) && messagesByLocale[browserLocale]) {
    locale = localeFromString(browserLocale)
    messages = messagesByLocale[browserLocale]
  }

  return {
    locale,
    timeZone: DateTime.getDefaults().timeZone,
    messages,
  }
}

/**
 * ブラウザから言語設定を取得します。
 *
 * 参考
 *   https://github.com/austintackaberry/i18n-example/blob/master/src/index.js
 */
function getLocaleFromBrowser() {
  const { navigator } = window

  // navigator.languageがnavigator.languages[0]と同じ値のはずだが、
  // ChromeなどがブラウザのUI言語にしているため異なるかもしれないので、
  // 先にnavigator.languagesを見る
  // 参考
  //   https://caniuse.com/#feat=mdn-api_navigatorlanguage_languages
  let preferredLanguage: string | undefined =
    navigator.languages && navigator.languages[0]

  // iOS Safari v13.2以前とIE v11
  // 参考
  //   https://caniuse.com/#feat=mdn-api_navigatorlanguage_languages
  //   http://web.archive.org/web/20170616234605/http://blog.ksol.fr/user-locale-detection-browser-javascript/
  if (!preferredLanguage)
    preferredLanguage = navigator.language as string | undefined

  // IE v9とv10
  // 参考
  //   http://web.archive.org/web/20170616234605/http://blog.ksol.fr/user-locale-detection-browser-javascript/
  if (!preferredLanguage)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    preferredLanguage = (navigator as any).userLanguage as string | undefined

  const languageWithoutRegionCode = (preferredLanguage || "")
    .toLowerCase()
    .split(/[_-]+/)[0]

  const locales = [languageWithoutRegionCode, preferredLanguage || ""]
  return locales.find(l => !!messagesByLocale[l])
}

/**
 * @private
 * I18nコンテナの定義
 * @param props - 既定以外の初期化が必要な場合に指定する
 */

export default useI18n

function useI18n() {
  const state = reactive<State>(getDefaultState())

  const formatMessage = (
    descriptor: MessageDescriptor,
    values?: MessageValues
  ): string => {
    const { id, defaultMessage } = descriptor

    let message = defaultMessage

    const messages = state.messages
    if (messages && messages[id] !== undefined) message = messages[id]

    const formattedMessage = new IntlMessageFormat(
      message,
      localeToString(state.locale)
    ).format(values)
    if (typeof formattedMessage !== "string")
      throw new RegularMeetingSolutionError({
        message: `IntlMessageFormat did not return a string. message=${message} locale=${state.locale}`,
      })

    return formattedMessage
  }

  const formatDateTime = (
    date: Date,
    options?: FormatDateTimeOptions
  ): string => {
    let locale = localeToString(state.locale)
    if (!areIntlLocalesSupported(locale)) locale = localeToString(defaultLocale)
    return new Intl.DateTimeFormat(locale, {
      timeZone: state.timeZone,
      ...options,
    }).format(date)
  }

  const setLocale = (locale: Locale): void => {
    if (locale === state.locale) return

    let messages: Record<string, string> = {}

    if (locale !== defaultLocale) {
      const localeString = localeToString(locale)
      messages = messagesByLocale[localeString]
      if (!messages) {
        messages = messagesByLocale[localeString.split("-")[0]]
        if (!messages) throw new Error(`no-messages-for-locale-${locale}`)
      }
    }

    state.locale = locale
    state.messages = messages
  }

  const setTimeZone = (timeZone: string): void => {
    if (timeZone === state.timeZone) return
    DateTime.setDefaults({ timeZone })
    state.timeZone = timeZone
  }

  return {
    state: toRefs(state),
    formatMessage,
    formatDateTime,
    setLocale,
    setTimeZone,
  }
}

type I18nStore = ReturnType<typeof useI18n>

/**
 * @constant
 * I18nコンテナ
 */
export const i18nContainer = createContainer<I18nStore, State>(useI18n)
