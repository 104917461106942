import "@fontsource/roboto/latin.css"
import "@mdi/font/css/materialdesignicons.css"
import Vue from "vue"
import Vuetify from "vuetify/lib/framework"
import ja from "vuetify/src/locale/ja"

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: "mdi",
    values: {
      "ms-teams": "ms-icon teams",
    },
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#6264a7", //Teamsと同色
        secondary: "#f5f5fc", //仮
        accent: "#FF3D00",
        error: "#FF3860",
        info: "#209CEE",
        success: "#23D160",
        warning: "#FFA600",
        anchor: "#6264a7", //Teamsと同色
        inputField: "#F3F2F1",
        inputFieldHover: "#e0e0e0",
      },
    },
  },
  lang: {
    locales: { ja },
    current: "ja",
  },
})
