import { reactive, toRefs } from "@vue/composition-api"
import { createContainer } from "./Container"
import WorkflowApi from "@/api/WorkflowApi"
import FileApi from "@/api/FileApi"
import { WorkflowIn, WorkflowOut } from "@/models/Workflow"
import { PDFDocument } from "pdf-lib"
import MemoPdfApi from "@/api/MemoPdfApi"
import ApplicationInformation from "@/models/ApplicationInformation"

const api = new WorkflowApi()
const fileApi = new FileApi()

interface State {
  isAdmin: boolean
  meetingStructureId: string | null
  workflows: Array<WorkflowIn> | null
}

function getDefaultState(): State {
  return {
    isAdmin: false,
    meetingStructureId: null,
    workflows: null,
  }
}

function useWorkflow(props?: State | null) {
  const approvedFolderPath = "/teirei-assist_approved"
  const state = reactive<State>(props ?? getDefaultState())

  const getWorkflowsAsync = async (
    isAdmin: boolean,
    meetingStructureId?: string,
    updateForce = false
  ) => {
    if (
      !updateForce &&
      state.isAdmin === isAdmin &&
      state.meetingStructureId === meetingStructureId &&
      state.workflows
    )
      return state.workflows
    let workflows
    if (isAdmin) workflows = await api.getAllWorkflows()
    else workflows = await api.getWorkflows(meetingStructureId)
    state.workflows = workflows
    state.isAdmin = isAdmin
    state.meetingStructureId = meetingStructureId || null
    return workflows
  }

  const creaeWorkflows = async (workflow: WorkflowOut) => {
    const newWorkflow = await api.createWorkflow(workflow)
    state.workflows?.push(newWorkflow)
    return newWorkflow
  }

  const createApprovedPdf = async (
    workflowId: string,
    fileName: string,
    approveData: Array<object>
  ) => {
    const memoPdfApi = new MemoPdfApi(
      (window.aplicationInfo as ApplicationInformation).pdfEndpointUrl
    )
    const approveBlog = await memoPdfApi.getMeetingApproverPdf(approveData)
    const workflow = state.workflows?.find(w => w.id === workflowId)
    if (!workflow) return null
    const siteId = workflow.siteId
    const file = await fileApi.getFileItem(
      siteId,
      workflow.filePath.split("Shared%20Documents")[1]
    )
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const url = (file as any)["@microsoft.graph.downloadUrl"]
    if (!url) return null
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
    const mergedPdf = await PDFDocument.create()
    const pdfA = await PDFDocument.load(existingPdfBytes)
    const pdfB = await PDFDocument.load(await approveBlog.arrayBuffer())

    const copiedPagesA = await mergedPdf.copyPages(pdfA, pdfA.getPageIndices())
    copiedPagesA.forEach(page => mergedPdf.addPage(page))
    const copiedPagesB = await mergedPdf.copyPages(pdfB, pdfB.getPageIndices())
    copiedPagesB.forEach(page => mergedPdf.addPage(page))

    const mergedPdfFile = await mergedPdf.save()
    const newFile = new Blob([mergedPdfFile.buffer], {
      type: "application/pdf",
    })
    const uploadedFile = await fileApi.fileUpload(
      siteId,
      approvedFolderPath,
      new File([newFile], fileName)
    )
    return uploadedFile
  }

  const approveWorkflow = async (
    workflowId: string,
    isApporved: boolean,
    comment: string,
    teamName: string,
    channelName: string,
    approvedFileUrl: string | null = null
  ) => {
    const workflow = await api.postWorkflowApprove(
      workflowId,
      isApporved,
      comment,
      teamName,
      channelName,
      approvedFileUrl
    )
    state.workflows =
      state.workflows?.map(w => (w.id === workflowId ? workflow : w)) || []
    return workflow
  }

  const patchWithdrawal = async (workflowId: string) => {
    const workflow = await api.patchWithdrawal(workflowId)
    state.workflows =
      state.workflows?.map(w => (w.id === workflowId ? workflow : w)) || []
    return workflow
  }

  return {
    state: toRefs(state),
    getWorkflowsAsync,
    creaeWorkflows,
    createApprovedPdf,
    approveWorkflow,
    patchWithdrawal,
  }
}

type useWorkflowStore = ReturnType<typeof useWorkflow>

/**
 * @constant
 * Answererコンテナ
 */
export const workflowContainer = createContainer<useWorkflowStore, State>(
  useWorkflow
)
