import DateTime from "./DateTime"

const workflowStatus = Object.freeze({
  Processing: 0,
  Complete: 1,
  Reject: 2,
})

const workflowStatusText = Object.freeze(["処理中", "承認", "却下", "取下げ"])

const workflowOperationText = Object.freeze(["未処理", "承認", "却下"])

class WorkflowBase {
  applicantComment: string
  filePath: string
  meetingId: string
  meetingStructureId: string
  teamId: string
  channelId: string
  groupId: string
  siteId: string
  isFromPrivate: boolean

  constructor(input?: WorkflowBase) {
    this.applicantComment = input?.applicantComment || ""
    this.filePath = input?.filePath || ""
    this.meetingId = input?.meetingId || ""
    this.meetingStructureId = input?.meetingStructureId || ""
    this.teamId = input?.teamId || ""
    this.groupId = input?.groupId || ""
    this.siteId = input?.siteId || ""
    this.channelId = input?.channelId || ""
    this.isFromPrivate = input?.isFromPrivate || false
  }
}

class WorkflowApprover {
  userId: string
  comment: string
  operation: number
  operateAt: DateTime | null

  constructor(input?: WorkflowApprover) {
    this.userId = input?.userId || ""
    this.comment = input?.comment || ""
    this.operation = input?.operation || 0
    this.operateAt = input?.operateAt ? new DateTime(input.operateAt) : null
  }
}

class WorkflowOut extends WorkflowBase {
  approvers: Array<string>
  appId: string
  teamName: string
  channelName: string

  constructor(input?: WorkflowOut) {
    super(input)
    this.approvers = input?.approvers || []
    this.appId = input?.appId || ""
    this.teamName = input?.teamName || ""
    this.channelName = input?.channelName || ""
  }
}

class WorkflowIn extends WorkflowBase {
  id: string
  applicantId: string
  approvers: Array<WorkflowApprover>
  status: number
  createdAt: DateTime

  constructor(input?: WorkflowIn) {
    super(input)
    this.id = input?.id || ""
    this.applicantId = input?.applicantId || ""
    this.approvers = input?.approvers.map(a => new WorkflowApprover(a)) || []
    this.status = input?.status || 0
    this.createdAt = new DateTime(input?.createdAt)
  }
}

export {
  WorkflowOut,
  WorkflowIn,
  WorkflowApprover,
  workflowStatus,
  workflowStatusText,
  workflowOperationText,
}
