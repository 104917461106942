class MemoTemplate {
  id: string
  name: string
  body: string
  type: number
  constructor(input: MemoTemplate) {
    this.id = input.id
    this.name = input.name
    this.body = input.body
    this.type = input.type
  }
}

export default MemoTemplate
