import MsGraphClient from "./clients/MsGraphClient"
import * as MicrosoftGraph from "@microsoft/microsoft-graph-types"

class TaskApi {
  private _client = new MsGraphClient()

  getPlans = async (
    groupId: string
  ): Promise<Array<MicrosoftGraph.PlannerPlan>> => {
    return (
      await this._client.getAsync<{
        value: Array<MicrosoftGraph.PlannerPlan>
      }>(`/groups/${groupId}/planner/plans`)
    ).value
  }

  getBuckets = async (
    planId: string
  ): Promise<Array<MicrosoftGraph.PlannerBucket>> => {
    return (
      await this._client.getAsync<{
        value: Array<MicrosoftGraph.PlannerBucket>
      }>(`/planner/plans/${planId}/buckets`)
    ).value
  }

  createTasks = async (task: {
    title: string
    planId: string
    bucketId: string
    percentComplete: number
    priority: number
    assignments: { [key: string]: MicrosoftGraph.PlannerAssignment }
  }): Promise<MicrosoftGraph.PlannerTask> => {
    return await this._client.postAsync<MicrosoftGraph.PlannerTask>(
      `/planner/tasks`,
      JSON.stringify({
        title: task.title,
        planId: task.planId,
        bucketId: task.bucketId,
        percentComplete: task.percentComplete,
        priority: task.priority,
        assignments: task.assignments,
      })
    )
  }
}

export default TaskApi
