import { reactive, toRefs } from "@vue/composition-api"
import { createContainer } from "./Container"
import ProjectApi from "@/api/ProjectApi"
import TeamApi from "@/api/TeamApi"
import ListItemApi from "@/api/ListItemApi"
import { Project } from "@/models/Project"
import { MeetingStructure } from "@/models/Meeting"
import SiteApi from "@/api/SiteApi"
import * as MicrosoftGraph from "@microsoft/microsoft-graph-types"

const api = new ProjectApi()
const teamApi = new TeamApi()
const listItemApi = new ListItemApi()
const siteApi = new SiteApi()

interface State {
  myProjects: Array<{
    project: Project
    meetings: MeetingStructure[]
  }> | null
  project: Project | null
  list: MicrosoftGraph.List | null
}

function getDefaultState(): State {
  return {
    myProjects: null,
    project: null,
    list: null,
  }
}

function useProject(props?: State | null) {
  const state = reactive<State>(props ?? getDefaultState())

  const getProjectOrNullAsync = async (listId: string) => {
    if (state.project) return state.project
    const project = await api.getProject(listId)
    state.project = project
    return project
  }

  const getChannelProjects = async (channelId: string) => {
    const projects = await api.getChannelProjects(channelId)
    return projects
  }

  const getProjectAsync = async (listId: string) => {
    const ret = await getProjectOrNullAsync(listId)
    if (!ret) throw new Error("project is empty")
    return ret
  }

  const getProjectSiteAsync = async (
    hostName: string,
    serverRelativePath: string
  ) => {
    if (state.project) return state.project
    const site = await siteApi.getSite(hostName, serverRelativePath)
    return site
  }

  const createProjectAsync = async (project: Project) => {
    const createdProject = await api.createProject(project)
    state.project = createdProject
    return createdProject
  }

  const updateProjectAsync = async (id: string, project: Project) => {
    const updatedProject = await api.updateProject(id, project)
    state.project = updatedProject
    return updatedProject
  }

  const deleteProjectAsync = async (project: Project) => {
    await api.deleteProject(project.id)
  }

  const getMyProjectsAsync = async () => {
    if (state.myProjects) return state.myProjects
    const groupids = await teamApi.getGroupIds()
    state.myProjects = await api.getMyProjects(groupids)
    return state.myProjects
  }

  const getAdminProjectsAsync = async (groupids: Array<string>) => {
    return await api.getMyProjects(groupids)
  }

  const searchProjectMemosAsync = async (
    projects: Array<Project>,
    queryString: string,
    page = 1
  ) => {
    const result = await listItemApi.searchListItem(
      projects
        .filter(p => !!p.sharepointListWeburl)
        .map(p => p.sharepointListWeburl),
      queryString,
      25 * (page - 1),
      25
    )
    return result
  }

  return {
    state: toRefs(state),
    getProjectAsync,
    getChannelProjects,
    getProjectOrNullAsync,
    updateProjectAsync,
    deleteProjectAsync,
    createProjectAsync,
    getMyProjectsAsync,
    getProjectSiteAsync,
    getAdminProjectsAsync,
    searchProjectMemosAsync,
  }
}

type useProjectStore = ReturnType<typeof useProject>

/**
 * @constant
 * Answererコンテナ
 */
export const projectsContainer = createContainer<useProjectStore, State>(
  useProject
)
