import { reactive, toRefs } from "@vue/composition-api"
import { createContainer } from "./Container"
import { applicationVersion } from "@/constants/AppConfig"
import ApplicationInformation from "@/models/ApplicationInformation"

const storageVersionKey = "appContainer.currentVersion"

export enum HintType {
  First = "first",
  Updates = "updates",
  NeedUpdate = "needUpdate",
}

interface State {
  showHint: boolean
  type: HintType
}

/**
 * @private
 * alertコンテナの定義
 * @param props - 既定以外の初期化が必要な場合に指定する
 */
function useHint(props?: State | null) {
  //
  const state = reactive<State>(
    props ?? {
      showHint: false,
      type: HintType.First,
    }
  )

  const showHint = (type: HintType) => {
    state.showHint = true
    state.type = type
  }

  const hideHint = () => {
    state.showHint = false
  }

  const showUpdateInfomationHint = () => {
    try {
      const clientVersion = (window.aplicationInfo as ApplicationInformation)
        .applicationVersion
      const showedAppVersion = localStorage.getItem(storageVersionKey)
      return showedAppVersion !== clientVersion
    } catch {
      return false
    }
  }

  const setUpdateInfomationVersion = () => {
    try {
      const clientVersion = (window.aplicationInfo as ApplicationInformation)
        .applicationVersion
      localStorage.setItem(storageVersionKey, clientVersion)
    } catch {
      return
    }
  }

  const needUpdateHint = () => {
    const clientVersion = (window.aplicationInfo as ApplicationInformation)
      .applicationVersion
    return clientVersion !== applicationVersion
  }

  return {
    state: toRefs(state),
    showHint,
    hideHint,
    showUpdateInfomationHint,
    setUpdateInfomationVersion,
    needUpdateHint,
  }
}

type HintStore = ReturnType<typeof useHint>

/**
 * @constant
 * alertコンテナ
 */
export const hintContainer = createContainer<HintStore, State>(useHint)
