import { reactive, toRefs } from "@vue/composition-api"
import { createContainer } from "./Container"
import MeetingApi from "@/api/MeetingApi"
import EventApi from "@/api/EventApi"
import ListItemApi from "@/api/ListItemApi"
import MemoTemplateApi from "@/api/MemoTemplateApi"
import { Meeting, MeetingStructure } from "@/models/Meeting"
import User from "@/models/User"
import ProjectFile from "@/models/ProjectFile"
import MemoTemplate from "@/models/MemoTemplate"
import DateTime from "@/models/DateTime"
import { order } from "@/utilities/Order"
import SukerakuApi from "@/api/SukerakuApi"

type Dictionary<T> = { [key: string]: T }
const meetingApi = new MeetingApi()
const eventApi = new EventApi()
const listItemApi = new ListItemApi()
const memoTemplateApi = new MemoTemplateApi()
const sukerakuApi = new SukerakuApi()

interface State {
  isLoadingMeetings: boolean
  memo: string | null
  meetings: Array<MeetingStructure> | null
  templates: Array<MemoTemplate> | null
  memoValues: { [key: string]: string }
}

function getDefaultState(): State {
  return {
    isLoadingMeetings: true,
    memo: null,
    meetings: null,
    templates: null,
    memoValues: {},
  }
}

const decodeHTMLEntities = (text: string) => {
  const entities = {
    amp: "&",
    apos: "'",
    lt: "<",
    gt: ">",
    quot: '"',
    nbsp: "\xa0",
  }
  const entityPattern = /&([a-z]+);/gi
  return text.replace(entityPattern, (match, entity) => {
    entity = entity.toLowerCase()
    if (Object.prototype.hasOwnProperty.call(entities, entity)) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (entities as any)[entity]
    }
    return match
  })
}

const decodeMemoValue = (text: string | undefined | null) => {
  const value = (text || "").replace(/&#[0-9]+;/g, r =>
    String.fromCharCode(parseInt(r.substring(2, r.length), 10))
  )
  return decodeHTMLEntities(value)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function objectWithoutProperties<T>(obj: any, keys: Array<string>) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const target: any = {}
  for (const i in obj) {
    if (keys.indexOf(i) >= 0) continue
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue
    target[i] = obj[i]
  }
  return target as T
}

function useMeeting(props?: State | null) {
  const state = reactive<State>(props ?? getDefaultState())

  const getMeetingsAsync = async (listId: string) => {
    if (state.meetings) return state.meetings
    const meetings = await meetingApi.getMeetingsSummary(listId)
    state.meetings = meetings.sort((a, b) => order(a.order, b.order))
    state.isLoadingMeetings = false
    return meetings
  }

  const getAllMeetingsAsync = async () => {
    const meetings = await meetingApi.getAllMeetingsSummary()
    return meetings
  }

  const getMemosAsync = async (meetingId: string) => {
    state.templates = await memoTemplateApi.getMemoTemplates(meetingId)
    return state.templates
  }

  const createMemosAsync = async (
    meetingId: string,
    template: MemoTemplate
  ) => {
    const newTemplate = await memoTemplateApi.createMemoTemplates(
      meetingId,
      template
    )
    if (state.templates) state.templates.push(newTemplate)
  }

  const updateMemosAsync = async (
    meetingId: string,
    id: string,
    template: MemoTemplate
  ) => {
    const updateTemplate = await memoTemplateApi.updateMemoTemplate(
      meetingId,
      id,
      template
    )
    if (state.templates) {
      state.templates = state.templates.map(s =>
        s.id === updateTemplate.id ? updateTemplate : s
      )
    }
  }

  const deleteMemosAsync = async (meetingId: string, id: string) => {
    await memoTemplateApi.deletemeetingMemoTemplate(meetingId, id)
    if (state.templates) {
      state.templates = state.templates.filter(s => s.id !== id)
    }
  }

  const createMeetingStructureAsync = async (
    siteId: string,
    listId: string,
    newMeeting: MeetingStructure
  ) => {
    const listItem = await listItemApi.createListItem(siteId, listId, {
      Title: `${newMeeting.name}_Root`,
      Users: JSON.stringify(newMeeting.users),
    })
    newMeeting.sharepointNextMemoItemId = listItem.id || ""
    const meeting = await meetingApi.createMeetingStructure(listId, newMeeting)
    if (state.meetings) {
      state.meetings.push(meeting)
      return meeting
    }
    return meeting
  }

  const setMemoValue = (currentMemo: string) => {
    state.memo = currentMemo
  }

  const getMemoValue = async (
    siteId: string,
    listId: string,
    itemId: string
  ) => {
    const listItem = await listItemApi.getListItem(siteId, listId, itemId)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fields = listItem.fields as any
    return decodeMemoValue(fields.memo as string)
  }

  const getMemoHtmlValue = async (
    siteId: string,
    listId: string,
    itemId: string,
    groupId = ""
  ) => {
    const key = `${siteId}-${listId}-${itemId}-${groupId}`
    if (state.memoValues[key]) return state.memoValues[key]
    const listItem = await listItemApi.getListItem(
      siteId,
      listId,
      itemId,
      // 下位バージョン互換、siteIdがない場合はgroupidの情報で検索する。
      siteId ? "" : groupId
    )
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fields = listItem.fields as any
    state.memoValues[key] = (fields.Search || "") as string
    return state.memoValues[key]
  }

  const getMemoVersionsLatestFirstAsync = async (
    siteId: string,
    listId: string,
    itemId: string
  ) => {
    const versions = await listItemApi.getListItemVersionsLatestFirst(
      siteId,
      listId,
      itemId,
      ["memo", "Modified"]
    )
    return (
      versions
        .reverse()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .filter(v => !!(v.fields as any).memo)
        // メモ変更のないバージョンを除外する
        .filter((version, index, self) => {
          if (index === 0) return true
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const memo = (version.fields as any).memo
          const previousVersion = self[index - 1]
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const previousMemo = (previousVersion.fields as any).memo
          return memo !== previousMemo
        })
        .map(v => {
          const fields = v.fields as { memo: string; Modified: string }
          return {
            id: v.id || "",
            value: JSON.parse(decodeMemoValue(fields.memo)),
            createdDateTime: fields.Modified
              ? new DateTime(fields.Modified)
              : null,
          }
        })
        .reverse()
    )
  }

  const setMeetingDetails = async (
    meetingStructure: MeetingStructure[],
    siteId: string,
    listId: string
  ) => {
    if (meetingStructure.length < 1) return
    const items = await listItemApi.getListItems(siteId, listId)
    for (const structure of meetingStructure) {
      const listItem = items.find(
        i => i.id === structure.sharepointNextMemoItemId
      )
      if (listItem) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const fields = listItem.fields as any
        structure.users = JSON.parse(fields.Users ?? "[]").map(
          (m: User) => new User(m)
        )
      }
      for (const meeting of structure.meetings) {
        const listItem = items.find(i => i.id === meeting.sharepointItemId)
        if (listItem) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const fields = listItem.fields as any
          meeting.subject = fields.Title as string
          const memoValue = (fields.memo || "{}") as string
          meeting.memo = JSON.parse(decodeMemoValue(memoValue))
          meeting.users = JSON.parse(fields.Users ?? "[]").map(
            (m: User) => new User(m)
          )
        }
      }
    }
  }

  const getAndSetMeetingDetails = async (siteId: string, listId: string) => {
    const meetingStructure = await getMeetingsAsync(listId)
    await setMeetingDetails(meetingStructure, siteId, listId)
    state.meetings = meetingStructure
    return meetingStructure
  }

  const loadMeetingDetailAsync = async (
    siteId: string,
    listId: string,
    currentMeeting: Meeting
  ) => {
    if (!currentMeeting.sharepointItemId) return
    const listItem = await listItemApi.getListItem(
      siteId,
      listId,
      currentMeeting.sharepointItemId
    )
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fields = listItem.fields as any
    currentMeeting.subject = fields.Title
    currentMeeting.users = JSON.parse(fields.Users ?? "[]").map(
      (m: User) => new User(m)
    )
    currentMeeting.files = JSON.parse(fields.Files ?? "[]").map(
      (m: ProjectFile) => new ProjectFile(m)
    )
  }

  const updateSharepointItem = async (
    siteId: string,
    listId: string,
    updateMeeting: MeetingStructure,
    filed: object
  ) => {
    if (!updateMeeting.sharepointNextMemoItemId)
      throw new Error("初期化に失敗しました")
    await listItemApi.updateListItem(
      siteId,
      listId,
      updateMeeting.sharepointNextMemoItemId,
      filed
    )
  }

  const updateMeeting = (meeting: MeetingStructure) => {
    if (state.meetings) {
      const newMeetings = state.meetings
        .map(m =>
          m.id === meeting.id
            ? new MeetingStructure({ ...meeting, meetings: m.meetings })
            : m
        )
        .sort((a, b) => order(a.order, b.order))
      state.meetings.splice(0, state.meetings.length)
      state.meetings.push(...newMeetings)
    }
  }

  let userDisplayNameCache: Dictionary<string> | null = null

  const getUserDisplayNames = (force = false) => {
    if (!userDisplayNameCache || force)
      userDisplayNameCache =
        state.meetings
          ?.reduce((out, m) => {
            out.push(...m.meetings)
            return out
          }, [] as Array<Meeting>)
          ?.sort((m1, m2) =>
            m1.startTime?.getDifference(m2.startTime, "minutes")
          )
          ?.reduce((out, current) => {
            current.users.forEach(u => {
              if (u.email && u.displayName) out[u.email] = u.displayName
            })
            return out
          }, {} as Dictionary<string>) ?? {}
    return userDisplayNameCache
  }

  const complementUserDisplayName = (users: User[]) => {
    const currentUsers = getUserDisplayNames()
    return users.map(u =>
      u.displayName && u.displayName !== u.email.split("@")[0]
        ? u
        : Object.assign(new User(u), {
            displayName: currentUsers[u?.email || ""] ?? (u.displayName || ""),
          })
    )
  }

  const createMeetingAsync = async (
    siteId: string,
    listId: string,
    structureId: string,
    newMeeting: Meeting,
    createO365Event: boolean
  ) => {
    let currentMeeting = new Meeting(newMeeting)
    currentMeeting.users = complementUserDisplayName(currentMeeting.users)
    if (createO365Event)
      currentMeeting = await eventApi.createEvent(currentMeeting)
    const listItem = await listItemApi.createListItem(siteId, listId, {
      Title: currentMeeting.subject,
      Users: JSON.stringify(currentMeeting.users.map(u => new User(u))),
      Time:
        currentMeeting.startTime.toIsoString() +
        "_" +
        currentMeeting.endTime.toIsoString(),
      Location: currentMeeting.location,
    })
    currentMeeting.sharepointItemId = listItem.id || ""
    currentMeeting.purpose = newMeeting.purpose
    currentMeeting.goal = newMeeting.goal
    currentMeeting.agendas = newMeeting.agendas
    const meeting = await meetingApi.createMeeting(
      listId,
      structureId,
      currentMeeting
    )
    if (state.meetings) {
      const updateState = state.meetings.find(m => m.id === structureId)
      if (updateState) {
        updateState.meetings.push(meeting)
        const updateStates = state.meetings?.map(m =>
          m.id === structureId ? updateState : m
        )
        state.meetings.splice(0, state.meetings.length)
        state.meetings.push(...updateStates)
      }
    }
    getUserDisplayNames(true)
  }

  const deleteMeetingAsync = async (
    siteId: string,
    listId: string,
    meetingStructureId: string,
    meeting: Meeting
  ) => {
    await Promise.all([
      meetingApi.deleteMeeting(listId, meetingStructureId, meeting.id),
      listItemApi.deleteListItem(siteId, listId, meeting.sharepointItemId),
    ])
    if (state.meetings) {
      const newMeetings = state.meetings.find(m => m.id === meetingStructureId)
      if (newMeetings) {
        newMeetings.meetings = newMeetings.meetings.filter(
          m => m.id !== meeting.id
        )
        updateMeeting(newMeetings)
      }
    }
  }

  const getEventsAsync = async (
    startDatetime: DateTime,
    endDatetime: DateTime
  ) => {
    const events = await eventApi.getEvents(startDatetime, endDatetime)
    return events
  }

  const getSukerakuAgendasAsync = async (
    startDatetime: DateTime,
    endDatetime: DateTime
  ) => {
    const candidates = await sukerakuApi.getExternalMeetingDatas(
      startDatetime,
      endDatetime
    )
    return candidates
  }

  const getRepeatEventsAsync = async (
    id: string,
    startDatetime: DateTime,
    endDatetime: DateTime
  ) => {
    const events = await eventApi.getRepeats(id, startDatetime, endDatetime)
    return events
  }

  const updateMeetingAsync = async (
    siteId: string,
    listId: string,
    editMeeting: Meeting,
    structureId: string
  ) => {
    let meeting = new Meeting(editMeeting)
    meeting = await meetingApi.updateMeeting(listId, structureId, editMeeting)
    meeting.subject = editMeeting.subject
    meeting.files = editMeeting.files
    meeting.users = editMeeting.users
    await listItemApi.updateListItem(
      siteId,
      listId,
      editMeeting.sharepointItemId,
      {
        Title: meeting.subject,
        Files: JSON.stringify(meeting.files),
        Users: JSON.stringify(meeting.users),
        Time:
          meeting.startTime.toIsoString() + "_" + meeting.endTime.toIsoString(),
        Location: meeting.location,
      }
    )
    if (state.meetings) {
      state.meetings = state.meetings.map(m =>
        m.id === structureId
          ? Object.assign(m, {
              meetings: m.meetings.map(m =>
                m.id === meeting.id ? meeting : m
              ),
            })
          : m
      )
    }
    getUserDisplayNames(true)
  }

  const syncMeetingAsync = async (
    siteId: string,
    listId: string,
    editMeeting: Meeting,
    structureId: string,
    onUpdated: (newMeeting: Meeting, isUpdate: boolean) => void
  ) => {
    const meeting = new Meeting(editMeeting)
    let currentMeeting
    try {
      currentMeeting = await eventApi.getEventByICalUid(
        meeting.iCaluid,
        meeting.startTime.sub({ months: 1 }),
        meeting.endTime.add({ months: 1 })
      )
    } catch {
      // ゲストユーザは確実に失敗するので、エラーを無視する
      return
    }
    // 自分が招待されていない会議の場合は終了
    if (!currentMeeting) return

    const start = new DateTime(
      currentMeeting.start?.dateTime,
      currentMeeting.start?.timeZone || undefined
    )
    const end = new DateTime(
      currentMeeting.end?.dateTime,
      currentMeeting.end?.timeZone || undefined
    )
    // 件名 時刻のみ同期
    if (
      meeting.subject !== (currentMeeting.subject ?? "(件名なし)") ||
      meeting.startTime.toIsoString() !== start.toIsoString() ||
      meeting.endTime.toIsoString() !== end.toIsoString()
    ) {
      meeting.subject = currentMeeting.subject || ""
      meeting.startTime = start
      meeting.endTime = end
      await updateMeetingAsync(siteId, listId, meeting, structureId)
      meeting.onlineMeetingUrl = currentMeeting.onlineMeeting?.joinUrl || ""
      onUpdated(meeting, true)
    } else {
      meeting.onlineMeetingUrl = currentMeeting.onlineMeeting?.joinUrl || ""
      onUpdated(meeting, false)
    }
  }

  const updateMeetingStructureAsync = async (
    siteId: string | null,
    listId: string,
    meetingStructureId: string,
    updateMeetingStructure: MeetingStructure,
    updateLinks = false
  ) => {
    const update = objectWithoutProperties<MeetingStructure>(
      updateMeetingStructure,
      updateLinks ? ["meetings", "users"] : ["meetings", "links", "users"]
    )
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const tasks: Array<Promise<any>> = [
      meetingApi.updateMeetingStructure(listId, meetingStructureId, update),
    ]
    if (siteId)
      tasks.push(
        listItemApi.updateListItem(
          siteId,
          listId,
          updateMeetingStructure.sharepointNextMemoItemId,
          { Users: JSON.stringify(updateMeetingStructure.users ?? []) }
        )
      )
    const [meeting] = await Promise.all(tasks)
    meeting.users = updateMeetingStructure.users
    updateMeeting(meeting)
  }

  const deleteMeetingStructureAsync = async (
    listId: string,
    meetingStructureId: string
  ) => {
    await meetingApi.deleteMeetingStructure(listId, meetingStructureId)
    if (state.meetings) {
      const newMeetings = state.meetings.filter(
        m => m.id !== meetingStructureId
      )
      state.meetings.splice(0, state.meetings.length)
      state.meetings.push(...newMeetings)
    }
  }

  const decodeUri = (html: string) => {
    const txt = document.createElement("textarea")
    txt.innerHTML = html
    const ret = txt.value
    txt.remove()
    return ret
  }

  const getOrCreateCarryOverMemo = async (
    siteId: string,
    listId: string,
    updateMeeting: MeetingStructure
  ) => {
    if (!updateMeeting.sharepointNextMemoItemId) {
      const listItem = await listItemApi.createListItem(siteId, listId, {
        Title: `${updateMeeting.name}_Root`,
        memo: "",
      })
      const update = new MeetingStructure(
        Object.assign(updateMeeting, {
          sharepointNextMemoItemId: listItem.id,
        })
      )
      await updateMeetingStructureAsync(
        siteId,
        listId,
        updateMeeting.id,
        update
      )
      return ""
    } else {
      const listItem = await listItemApi.getListItem(
        siteId,
        listId,
        updateMeeting.sharepointNextMemoItemId
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const fields = listItem.fields as any
      return decodeUri(fields.memo ?? "")
    }
  }

  return {
    state: toRefs(state),
    getMeetingsAsync,
    getAllMeetingsAsync,
    getAndSetMeetingDetails,
    getEventsAsync,
    getSukerakuAgendasAsync,
    getRepeatEventsAsync,
    getMemoValue,
    getMemoHtmlValue,
    getMemoVersionsLatestFirstAsync,
    setMemoValue,
    loadMeetingDetailAsync,
    createMeetingStructureAsync,
    createMeetingAsync,
    updateMeetingAsync,
    syncMeetingAsync,
    deleteMeetingAsync,
    updateMeetingStructureAsync,
    deleteMeetingStructureAsync,
    getMemosAsync,
    createMemosAsync,
    updateMemosAsync,
    deleteMemosAsync,
    updateSharepointItem,
    getOrCreateCarryOverMemo,
    setMeetingDetails,
    getUserDisplayNames,
  }
}

type useMeetingStore = ReturnType<typeof useMeeting>

/**
 * @constant
 * Answererコンテナ
 */
export const meetingContainer = createContainer<useMeetingStore, State>(
  useMeeting
)
