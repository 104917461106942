/*
https://github.com/resessh/vue-unstated

*/
import {
  inject as _inject,
  InjectionKey,
  provide as _provide,
} from "@vue/composition-api"

export type ContainerProviderProps<State = void> = {
  initialState?: State
}

export type Container<Value, State = void> = {
  provide: (props?: ContainerProviderProps<State>) => Value
  useContainer: () => Value
}

/**
 * @public
 * Composition APIを使用したコンテナ
 * @param useComposition
 */
export function createContainer<T, State = void>(
  useComposition: (initialState?: State) => T
): Container<T, State> {
  // コンテナのシンボル。コンテナ識別用に使用する。
  let providerSymbol: InjectionKey<T>

  /**
   * @public
   * 親コンポーネントでコンテナの実態を生成する
   * @param props - 既定以外の初期化が必要な場合は指定する
   */
  function provide(props?: ContainerProviderProps<State>): T {
    providerSymbol = Symbol()
    const value = useComposition(props && props.initialState)
    _provide<T>(providerSymbol, value)
    return value
  }

  /**
   * @public
   * 子コンポーネントでコンテナを使用する
   */
  function useContainer(): T {
    const value = _inject(providerSymbol)
    if (!value) {
      throw new Error("Container must be used in provided Component.")
    }
    return value
  }

  return { provide, useContainer }
}
