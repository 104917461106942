import SofieClient from "./clients/SofieClient"
import { WorkflowIn, WorkflowOut } from "@/models/Workflow"

class WorkflowApi {
  private _client = new SofieClient()

  createWorkflow = async (workflow: WorkflowOut): Promise<WorkflowIn> => {
    return await this._client.postAsync<WorkflowIn>(
      `workflows`,
      JSON.stringify(workflow)
    )
  }

  getWorkflows = async (
    meetingStructureId?: string
  ): Promise<Array<WorkflowIn>> => {
    const queryParameters = new URLSearchParams()
    if (meetingStructureId)
      queryParameters.append("meetingStructureId", meetingStructureId)
    const ret = await this._client.getAsync<Array<WorkflowIn>>(`workflows`, {
      queryParameters,
    })
    return ret.map(w => new WorkflowIn(w))
  }

  getAllWorkflows = async (): Promise<Array<WorkflowIn>> => {
    const queryParameters = new URLSearchParams()
    const ret = await this._client.getAsync<Array<WorkflowIn>>(
      `workflows/admin`,
      {
        queryParameters,
      }
    )
    return ret.map(w => new WorkflowIn(w))
  }

  postWorkflowApprove = async (
    workflowId: string,
    isApporved: boolean,
    comment: string,
    teamName: string,
    channelName: string,
    filePath: string | null
  ): Promise<WorkflowIn> => {
    const ret = await this._client.postAsync<WorkflowIn>(
      `workflows/${workflowId}/confirm`,
      JSON.stringify({
        comment,
        isApporved,
        teamName,
        channelName,
        filePath,
      })
    )
    return new WorkflowIn(ret)
  }

  patchWithdrawal = async (workflowId: string): Promise<WorkflowIn> => {
    const ret = await this._client.patchAsync<WorkflowIn>(
      `workflows/${workflowId}`,
      JSON.stringify({
        status: 3,
      })
    )
    return new WorkflowIn(ret)
  }
}

export default WorkflowApi
