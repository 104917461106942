import { HttpRequestOptions } from "./clients/HttpClient"
import DateTime from "@/models/DateTime"
import SofieClient from "./clients/SofieClient"

class MemoWordApi {
  private _client = new SofieClient()

  async getMeetingMinutesWord(html: string): Promise<Blob> {
    const options: HttpRequestOptions = {}
    const body = JSON.stringify({
      date: new DateTime(new Date()).toDateJpString(),
      html,
    })
    return new Promise(resolve => {
      this._client.postAsync<void>(
        "minutes",
        body,
        options,
        async (r: Response) => {
          const blob = await r.blob()
          resolve(blob)
        }
      )
    })
  }
}

export default MemoWordApi
