import ApiError from "@/models/Errors/ApiError"
import HttpClient, { HttpRequestOptions } from "./HttpClient"
import SofieTokenProvider from "@/utilities/SofieTokenProvider"

export interface SofieRequestOptions {
  noToken?: boolean
  queryParameters?: URLSearchParams
  httpHeaders?: Record<string, string>
}

interface SofieRequestOptionsInner extends SofieRequestOptions {
  noToken: boolean
}

const sofieTokenProvider: SofieTokenProvider = new SofieTokenProvider()

class SofieClient {
  private _client: HttpClient

  constructor() {
    this._client = new HttpClient({
      baseUrl: `/api`,
    })
  }

  getAsync = async <T>(path: string, options?: SofieRequestOptions) => {
    return this._handleRequestErrorsAsync<T>(async () => {
      const httpRequestOptions = await this._getHttpRequestOptionsAsync(options)
      return this._client.getAsync<T>(path, httpRequestOptions)
    })
  }

  postAsync = async <T>(
    path: string,
    data?: string,
    options?: SofieRequestOptions,
    responseHandler: ((r: Response) => Promise<T>) | null = null
  ) => {
    return this._handleRequestErrorsAsync<T>(async () => {
      const httpRequestOptions = await this._getHttpRequestOptionsAsync(
        options,
        data !== undefined
      )
      return this._client.postAsync(
        path,
        data,
        httpRequestOptions,
        responseHandler
      )
    })
  }

  patchAsync = async <T>(
    path: string,
    data?: string,
    options?: SofieRequestOptions
  ) => {
    return this._handleRequestErrorsAsync<T>(async () => {
      const httpRequestOptions = await this._getHttpRequestOptionsAsync(
        options,
        data !== undefined
      )
      return this._client.patchAsync(path, data, httpRequestOptions)
    })
  }

  deleteAsync = async (path: string, options?: SofieRequestOptions) => {
    return this._handleRequestErrorsAsync<void>(async () => {
      const httpRequestOptions = await this._getHttpRequestOptionsAsync(options)
      return this._client.deleteAsync(path, httpRequestOptions)
    })
  }

  private _getHttpRequestOptionsAsync = async (
    options?: SofieRequestOptions,
    hasData?: boolean
  ): Promise<HttpRequestOptions> => {
    const opts = this._getRequestOptions(options)
    const headers = await this._getHeadersAsync(opts, hasData)

    return { headers, queryParameters: opts.queryParameters }
  }

  private _getRequestOptions = (
    options?: SofieRequestOptions
  ): SofieRequestOptionsInner => {
    return {
      noToken: false,
      ...options,
    }
  }

  private _getHeadersAsync = async (
    options: SofieRequestOptionsInner,
    hasData?: boolean
  ) => {
    const defaultHeaders: Record<string, string> = {
      Accept: "application/json",
    }

    if (hasData) defaultHeaders["Content-Type"] = "application/json"

    if (!options.noToken) {
      try {
        const token = await sofieTokenProvider.getTokenAsync()
        defaultHeaders.Authorization = `Bearer ${token}`
      } catch (_error) {
        throw new ApiError({ status: -100 })
      }
    }

    return { ...defaultHeaders, ...options.httpHeaders }
  }

  private _handleRequestErrorsAsync = async <T>(
    sendRequestAsync: () => Promise<T>,
    retryCount = 0
  ): Promise<T> => {
    try {
      const response = await sendRequestAsync()
      return response
    } catch (e) {
      if (e instanceof ApiError)
        if (e.status >= 500 && retryCount < 3) {
          return await this._handleRequestErrorsAsync<T>(
            sendRequestAsync,
            retryCount + 1
          )
        }
      throw e
    }
  }
}

export default SofieClient
