import { Meeting, MeetingStructure } from "@/models/Meeting"
import { Project } from "@/models/Project"

export const getQueryParam = (
  name: string,
  url: string | null = null
): string | null => {
  if (!url) url = window.location.href
  name = name.replace(/[[\]]/g, "\\$&")
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ""
  return decodeURIComponent(results[2].replace(/\+/g, " "))
}

export const getMeetingDeepLinkUrl = (
  project: Project,
  parent: MeetingStructure,
  meeting: Meeting
) => {
  if (
    !parent.id ||
    !meeting.id ||
    !project.sharepointListId ||
    !project.channelId ||
    !project.appId
  )
    return ""
  const entityId = encodeURI(project.sharepointListId)
  const urlContext = {
    subEntityId: `/meeting-series/${parent.id}/in-meeting/${meeting.id}`,
    channelId: project.channelId,
  }
  const encodedContext = encodeURI(JSON.stringify(urlContext))
  return `https://teams.microsoft.com/l/entity/${project.appId}/${entityId}?context=${encodedContext}`
}
