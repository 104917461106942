































import { hintContainer, HintType } from "@/containers/HintContainer"
import DefaultDialog from "@/components/dialogs/DefaultDialog.vue"
import { defineComponent, reactive, watch } from "@vue/composition-api"

interface State {
  page: number
  showHint: boolean
}

export default defineComponent({
  components: {
    DefaultDialog,
  },
  setup() {
    const { state: hideState, hideHint } = hintContainer.useContainer()
    const state = reactive<State>({
      page: 0,
      showHint:
        !!hideState.showHint.value && hideState.type.value === HintType.First,
    })
    const titles = ["ようこそ！", "まずは会議体を作成"]
    const images = ["welcome", "create-meeting-structure"]
    watch(
      () => hideState.showHint.value,
      newValue => {
        state.showHint = newValue && hideState.type.value === HintType.First
      }
    )
    const closeHint = () => {
      hideHint()
      state.page = 0
    }
    return { state, closeHint, titles, images }
  },
})
