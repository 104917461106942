/* eslint-disable @typescript-eslint/no-explicit-any */
import RegularMeetingSolutionError from "@/models/RegularMeetingSolutionError"

enum RouteName {
  Login,
  Authenticate,
  AuthenticateBrowse,
  AuthorizeStart,
  AuthorizeEnd,
  InMeeting,
  MeetingSeries,
  TabConfig,
  RemoveConfig,
  MeetingInfo,
  NotificationSettings,
  MemoTemplateSettings,
  ExternalLinkSettings,
  NewMeetingCreation,
  MeetingCreationFromOutlook,
  PastMemoList,
  ConfirmApproves,
  PersonalSearch,
  PersonalNotification,
  PersonalConfirmMemo,
  MeetingCreationFromExternal,
}

export default RouteName

const keys = Object.keys(RouteName).filter(
  k => typeof RouteName[k as any] === "number"
)
const values = keys.map<RouteName>(k => RouteName[k as any] as any)

export { values as routeNames, routeNameToString, routeNameFromString }

function routeNameToString(name: RouteName): string {
  return RouteName[name]
}

function routeNameFromString(name: string): RouteName {
  const routeName: RouteName | string | undefined = RouteName[
    name as any
  ] as any

  if (routeName === undefined || typeof routeName === "string")
    throw new RegularMeetingSolutionError({
      message: `Unknown route name: '${name}'`,
    })

  return routeName
}
