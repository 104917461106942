






























































































import {
  defineComponent,
  reactive,
  computed,
  watch,
  onMounted,
  ref,
} from "@vue/composition-api"
import { TypedComponentProps } from "@/vue-props-types"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import TextField from "@/components/textFields/TextField.vue"
import TextArea from "@/components/textAreas/TextArea.vue"
import { teamsContextContainer } from "@/containers/TeamsContextContainer"
import { meetingContainer } from "@/containers/MeetingsContainer"
import { MeetingStructure } from "@/models/Meeting"
import User from "@/models/User"
import { Props } from "../views/MeetingInfo/types"
import ExternalAttendeesAdd from "./ExternalAttendeesAdd.vue"
import UsersAutoComplete from "@/views/UsersAutoComplete.vue"

interface State {
  isFormValid: boolean
  isOpenConfirmDialog: boolean
  isLoading: boolean
  meetingStructure: MeetingStructure
  members: Array<User>
  selectedMembers: Array<User>
}

const propsOptions = {
  id: {
    type: String,
    default: "",
  },
  errorMsg: String,
}

export default defineComponent<TypedComponentProps<Props, typeof propsOptions>>(
  {
    props: propsOptions,
    components: {
      I18nFormattedMessage,
      TextField,
      TextArea,
      ExternalAttendeesAdd,
      UsersAutoComplete,
    },
    setup(props, { emit }) {
      const {
        state: meetingState,
        getMeetingsAsync,
      } = meetingContainer.useContainer()

      const { getContext, getMembers } = teamsContextContainer.useContainer()

      const state = reactive<State>({
        isFormValid: false,
        isOpenConfirmDialog: false,
        selectedMembers: [],
        isLoading: false,
        members: [],
        meetingStructure: new MeetingStructure(
          meetingState.meetings.value?.find(v => v.id === props.id) ??
            ({} as MeetingStructure)
        ),
      })

      const setCurrentUser = () => {
        if (state.meetingStructure && state.members) {
          if (state.meetingStructure.id) {
            //会議体編集時
            const target = state.members.filter(m =>
              state.meetingStructure.userIds?.includes(m.userId ?? "")
            )
            state.selectedMembers.slice(0, state.selectedMembers.length)
            state.selectedMembers.push(...target)
          } else {
            //新規会議体作成時（全員無選択状態にする）
            state.selectedMembers = []
            state.meetingStructure.userIds = []
          }
        }
      }

      const mtgStructureInputForm = ref<InstanceType<typeof HTMLFormElement>>()
      const resetValidation = () => {
        if (!mtgStructureInputForm.value) return
        mtgStructureInputForm.value.resetValidation()
      }

      //親コンポーネントからの指示で使うメソッド
      const clearData = () => {
        state.meetingStructure.name = ""
        state.meetingStructure.summary = ""
        state.meetingStructure.location = ""
        const target = state.members.slice()
        state.selectedMembers = target
        state.meetingStructure.userIds = target.map(m => m.userId ?? "")
        state.meetingStructure.users = []
        resetValidation()
      }

      const onFormValidityChanged = (isValid: boolean) => {
        state.isFormValid = isValid
        emit("valid-change", isValid)
      }

      const attendeesCount = computed<number>(() => {
        return state.selectedMembers.length ?? 0
      })

      const onNameInput = (name: string) => {
        state.meetingStructure.name = name
        emit("input", state.meetingStructure)
      }
      const onSummaryInput = (summary: string) => {
        state.meetingStructure.summary = summary
        emit("input", state.meetingStructure)
      }
      const onLocationInput = (location: string) => {
        state.meetingStructure.location = location
        emit("input", state.meetingStructure)
      }

      const onSelectedMemberChange = (members: Array<User>) => {
        state.selectedMembers = members
        if (state.selectedMembers.length < 1) {
          state.isFormValid = false
          emit("valid-change", state.isFormValid)
        } else {
          state.isFormValid = true
          emit("valid-change", state.isFormValid)
        }
        state.meetingStructure.userIds = members.map(m => m.userId ?? "")
        emit("input", state.meetingStructure)
      }

      const externalAttendees = computed<Array<User>>({
        get: () => state.meetingStructure.users.filter(u => !u.id),
        set: newValue => {
          state.meetingStructure.users = state.meetingStructure.users
            .filter(u => u.id)
            .concat(newValue)
          emit("external-attendees-change", newValue)
        },
      })

      watch(
        () => meetingState.isLoadingMeetings.value,
        newValue => {
          if (!newValue) {
            state.meetingStructure = new MeetingStructure(
              meetingState.meetings.value?.find(v => v.id === props.id) ??
                ({} as MeetingStructure)
            )
          }
        }
      )

      watch(
        () => state.members,
        () => {
          setCurrentUser()
        }
      )

      onMounted(async () => {
        const context = await getContext()
        await getMeetingsAsync(context.entityId)
        state.members = (await getMembers()).map(m => new User(m))
      })

      return {
        state,
        onFormValidityChanged,
        onNameInput,
        onSummaryInput,
        onLocationInput,
        onSelectedMemberChange,
        attendeesCount,
        externalAttendees,
        clearData,
        mtgStructureInputForm,
        resetValidation,
      }
    },
  }
)
