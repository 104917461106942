import SofieClient from "./clients/SofieClient"
import { Project } from "@/models/Project"
import ApiError from "@/models/Errors/ApiError"
import { MeetingStructure } from "@/models/Meeting"

class ProjectApi {
  private _sofieClient = new SofieClient()

  getProject = async (listId: string) => {
    try {
      const project = await this._sofieClient.getAsync<Project>(
        `projects/${listId}`
      )
      return new Project(project)
    } catch (e) {
      if (e instanceof ApiError) {
        if (e.status === 404) return null
      }
      throw e
    }
  }

  getChannelProjects = async (channelId: string) => {
    const projects = await this._sofieClient.postAsync<
      Array<{
        project: Project
        meetings: Array<MeetingStructure>
      }>
    >(
      `projects/getChannelProjects`,
      JSON.stringify({
        channelId,
      })
    )
    for (const project of projects) {
      project.project = new Project(project.project)
      project.meetings = project.meetings.map(m => new MeetingStructure(m))
    }
    return projects
  }

  getMyProjects = async (groupIds: string[]) => {
    const projects = await this._sofieClient.postAsync<
      Array<{
        project: Project
        meetings: Array<MeetingStructure>
      }>
    >(
      "projects/getMyProjects",
      JSON.stringify({
        groupIds,
      })
    )
    for (const project of projects) {
      project.project = new Project(project.project)
      project.meetings = project.meetings.map(m => new MeetingStructure(m))
    }
    return projects
  }

  createProject = async (project: Project) => {
    const ret = await this._sofieClient.postAsync<Project>(
      `projects`,
      JSON.stringify(project)
    )
    return new Project(ret)
  }

  updateProject = async (id: string, project: Project) => {
    const ret = await this._sofieClient.patchAsync<Project>(
      `projects/${id}`,
      JSON.stringify(project)
    )
    return new Project(ret)
  }

  deleteProject = async (id: string) => {
    await this._sofieClient.deleteAsync(`projects/${id}`)
  }
}

export default ProjectApi
