import MsGraphClient from "./clients/MsGraphClient"
import { ListStructure, TextColumn } from "@/models/Management/listStructure"
import * as MicrosoftGraph from "@microsoft/microsoft-graph-types"

class ManagementApi {
  private _client = new MsGraphClient("manage")

  createListStructure = async (
    siteId: string,
    structure: ListStructure
  ): Promise<MicrosoftGraph.List> => {
    const ret = await this._client.postAsync<MicrosoftGraph.List>(
      `sites/${siteId}/lists`,
      JSON.stringify(structure)
    )
    return ret
  }

  updateListStructure = async (
    siteId: string,
    listId: string,
    column: TextColumn
  ): Promise<TextColumn> => {
    const ret = await this._client.postAsync<TextColumn>(
      `sites/${siteId}/lists/${listId}/columns`,
      JSON.stringify(column)
    )
    return ret
  }

  getListStructure = async (
    siteId: string,
    listId: string
  ): Promise<MicrosoftGraph.List> => {
    const ret = await this._client.getAsync<MicrosoftGraph.List>(
      `sites/${siteId}/lists/${listId}?expand=columns`
    )
    return ret
  }
}

export default ManagementApi
