import { reactive, toRefs } from "@vue/composition-api"
import { createContainer } from "./Container"
import { GetMetricClient, eventType } from "@/api/MetricApi"

interface State {}

function getDefaultState(): State {
  return {}
}

function useMetric(props?: State | null) {
  const state = reactive<State>(props ?? getDefaultState())

  const CreateInstallMetric = async (tenantId: string, userId: string) => {
    await GetMetricClient().createMetricAsync(
      tenantId,
      userId,
      eventType.install
    )
  }

  const CreateAccessMetric = async (tenantId: string, userId: string) => {
    await GetMetricClient().createMetricAsync(
      tenantId,
      userId,
      eventType.access
    )
  }

  const CreateGoalMetric = async (tenantId: string, userId: string) => {
    await GetMetricClient().createMetricAsync(tenantId, userId, eventType.goal)
  }

  return {
    state: toRefs(state),
    CreateInstallMetric,
    CreateAccessMetric,
    CreateGoalMetric,
  }
}

type useMetricStore = ReturnType<typeof useMetric>

/**
 * @constant
 * Answererコンテナ
 */
export const metricContainer = createContainer<useMetricStore, State>(useMetric)
