import { assertExhaustive } from "@/utilities/switch"

enum Locale {
  Japan = "ja",
  English = "en-US", // TODO: 英語ユーザーの暫定対応。英語対応をする時に要確認
}

export default Locale

const defaultLocale = Locale.Japan

export {
  defaultLocale,
  localeFromString,
  localeIsValid,
  localeToLanguage,
  localeToString,
}

function localeToString(locale: Locale): string {
  return locale.toString()
}

function localeFromString(localeAsString: string): Locale {
  const locale = localeAsString as Locale

  switch (locale) {
    case Locale.Japan:
      return Locale.Japan

    case Locale.English: // TODO: 英語ユーザーの暫定対応。英語対応をする時に要確認
      return defaultLocale

    default:
      return assertExhaustive<never>(
        locale,
        undefined,
        `localeFromString, unknown locale: '${localeAsString}'`
      )
  }
}

function localeIsValid(localeAsString: string): boolean {
  try {
    localeFromString(localeAsString)
    return true
  } catch {
    return false
  }
}

function localeToLanguage(locale: Locale) {
  switch (locale) {
    case Locale.Japan:
      return "日本語"

    case Locale.English:
      return "English"

    default:
      return assertExhaustive<never>(
        locale,
        undefined,
        `locaToLanguage, unknown locale: '${locale}'`
      )
  }
}
