import RegularMeetingSolutionError from "@/models/RegularMeetingSolutionError"

/**
 * TypeScriptのタイプチェックを利用して、
 * switchを使う時に忘れていない値があるかチェックします。
 * 実行中の時、もし想定しなかった値が入ってきとしたら、
 * 指定したreturnValueがundefinedの場合はエラーをスローして、
 * undefined以外の場合はその値を返します。
 *
 * @param {never} value チェックしたい値
 * @param {T} [returnValue] 返したいデフォルト値（undefinedの場合はエラーをスローします）
 * @param {string} [errorMessage] エラーをスローした時に使うメッセージ
 * @returns {T} 指定したreturnValue（undefinedの場合はエラーをスローします）
 */
export const assertExhaustive = <T = never>(
  value: never,
  returnValue?: T,
  errorMessage?: string
): T => {
  if (returnValue === undefined) {
    throw new RegularMeetingSolutionError({
      message: errorMessage || `Unexpected value in switch: '${value}'`,
    })
  }

  return returnValue
}
