export default interface GraphArrayResult<T> {
  value: Array<T>
  "@odata.nextLink"?: string
}

export const getNextLinkPath = <T>(
  result: GraphArrayResult<T>
): string | null => {
  const nextLink = result["@odata.nextLink"] || null
  return (
    nextLink?.replace(/^https:\/\/graph\.microsoft\.com\/.+?\//, "") || null
  )
}
