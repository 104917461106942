export class List {
  template:
    | "documentLibrary"
    | "genericList"
    | "tasks"
    | "survey"
    | "links"
    | "announcements"
    | "contacts" = "genericList"
  hidden = false
  contentTypesEnabled = false
}

class ColumnBase {
  description: string
  displayName: string
  enforceUniqueValues: boolean
  hidden: boolean
  name: string
  readOnly: boolean
  required: boolean
  // ↓はエラーになるのでコメントアウト
  // columnGroup: string
  // indexed: boolean

  constructor({
    name,
    displayName,
    description = "",
    enforceUniqueValues = false,
    hidden = false,
    readOnly = false,
    required = false,
  }: // columnGroup = null,
  // indexed = true,
  ColumnBase) {
    this.description = description
    this.displayName = displayName
    this.enforceUniqueValues = enforceUniqueValues
    this.hidden = hidden
    this.name = name
    this.readOnly = readOnly
    this.required = required
    // this.columnGroup = columnGroup
    // this.indexed = indexed
  }
}

export class TextColumn extends ColumnBase {
  text: {
    allowMultipleLines: boolean
    appendChangesToExistingText: boolean
    linesForEditing: number
    maxLength: number
    textType: "plain" | "richText"
  }

  constructor({
    name,
    displayName,
    description = "",
    enforceUniqueValues = false,
    hidden = false,
    readOnly = false,
    required = false,
    text: {
      allowMultipleLines = false,
      appendChangesToExistingText = false,
      linesForEditing = 6,
      maxLength = 255,
      textType = "plain",
    },
  }: TextColumn) {
    super({
      name,
      displayName,
      description,
      enforceUniqueValues,
      hidden,
      readOnly,
      required,
    })
    this.text = {
      allowMultipleLines,
      appendChangesToExistingText,
      linesForEditing,
      maxLength,
      textType: textType,
    }
  }
}

export class ListStructure {
  displayName: string
  columns: Array<TextColumn>
  list: List

  constructor({ displayName, columns = [], list = new List() }: ListStructure) {
    this.displayName = displayName
    this.columns = columns
    this.list = list
  }
}
