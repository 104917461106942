





























import {
  defineComponent,
  onErrorCaptured,
  reactive,
} from "@vue/composition-api"
import DefaultDialog from "@/components/dialogs/DefaultDialog.vue"
import ApiError from "@/models/Errors/ApiError"
import ApplicationError from "@/models/Errors/ApplicationError"
import { goToRoute } from "@/router"
import RouteName from "@/constants/RouteName"
import { teamsContextContainer } from "@/containers/TeamsContextContainer"
import DateTime from "@/models/DateTime"
import copy from "@/utilities/Copy"
import IconButton from "@/components/buttons/IconButton.vue"
import { v4 as uuidV4 } from "uuid"

interface State {
  isOpenErrorDialog: boolean
  title: string
  message: string
  trace: string
  showDetail: boolean
}

export default defineComponent({
  components: {
    DefaultDialog,
    IconButton,
  },

  setup() {
    const state = reactive<State>({
      isOpenErrorDialog: false,
      title: "",
      message: "",
      trace: "",
      showDetail: false,
    })

    const { getContext } = teamsContextContainer.useContainer()

    const setApiErrorMessage = async (err: ApplicationError) => {
      const context = await getContext()
      const traceId = uuidV4()
      const dateTime = new DateTime().toIsoString()
      state.isOpenErrorDialog = true
      state.title = "処理に失敗しました"
      state.message = err.message
      state.trace = `発生日時：${dateTime}\nトレースID：${traceId}\nユーザーID：${context.userObjectId}\nチームID：${context.groupId}`
      state.showDetail = err.showDetail
    }

    const set507ApiError = () => {
      state.isOpenErrorDialog = true
      state.title = "データを保存するための容量が足りません"
      state.message =
        "チーム内に保存されている不要なファイルを削除するか、Microsoft Teamsの担当者に容量の追加を依頼してください。"
      state.showDetail = false
    }

    window.addEventListener("unhandledrejection", p => {
      if (p.reason instanceof ApplicationError && p.reason.showMessageModal) {
        setApiErrorMessage(p.reason)
      }
      if (p.reason instanceof ApiError && p.reason.status === 507) {
        set507ApiError()
      }
      if (window.appInsights) {
        window.appInsights.trackException({
          exception: new Error(`unhandledrejection reason:${p.reason}`),
        })
      }
    })
    onErrorCaptured(
      async (err: {
        /* eslint-disable @typescript-eslint/no-explicit-any */
        errorInfo: { code: string; details: { [x: string]: any } }
        showMessageModal: any
        message: string
        /* eslint-enable @typescript-eslint/no-explicit-any */
      }) => {
        const context = await getContext()
        const traceId = uuidV4()
        const dateTime = new DateTime().toIsoString()
        // Apiのエラーだけ
        if (err instanceof ApiError) {
          // アドミンコンセントがされてないエラー。ログインページに飛ばす
          if (err.errorInfo?.code === "E00001") {
            goToRoute({
              name: RouteName.Login,
              params: {
                redirectPath: location.pathname,
                clientId: err.errorInfo.details["clientId"],
                scopes: err.errorInfo.details["scope"],
              },
            })
            return false
          }
          // SharePointの507を特別待遇
          else if (err.status === 507) {
            set507ApiError()
          } else {
            state.isOpenErrorDialog = true
            state.title = "処理に失敗しました"
            state.message =
              "一時的な事象の可能性があるため時間をおいてから再度お試しください。\n※再試行でも改善されない場合は、担当部署に下記情報を添えてお問い合わせください。"
            state.trace = `発生日時：${dateTime}\nトレースID：${traceId}\nユーザーID：${context.userObjectId}\nチームID：${context.groupId}`
            state.showDetail = true
          }
        } else if (err instanceof ApplicationError && err.showMessageModal) {
          setApiErrorMessage(err)
        }
        if (window.appInsights) {
          // https://github.com/microsoft/ApplicationInsights-JS#sending-telemetry-to-the-azure-portal
          window.appInsights.trackException({
            exception: err,
            properties: {
              traceId: traceId,
              userId: context.userObjectId,
              teamId: context.groupId,
            },
          })
        }
      }
    )

    return {
      state,
      copy,
    }
  },
})
