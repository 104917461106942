import { reactive, toRefs } from "@vue/composition-api"
import EventApi from "@/api/EventApi"
import { createContainer } from "./Container"
import { Meeting } from "@/models/Meeting"
import FileApi from "@/api/FileApi"

const eventApi = new EventApi()
const fileApi = new FileApi()

interface State {
  isInited: boolean
}

function memoState(props?: State | null) {
  const state = reactive<State>(props ?? { isInited: false })
  const notesFolderPath = "/teirei-assist_temp"

  const createBaseFolderIfNotExist = async (groupId: string) => {
    fileApi.createFolderIfNotExist(groupId, notesFolderPath)
  }

  const createEventAsync = async (meeting: Meeting, body: string) => {
    return await eventApi.createEvent(meeting, body)
  }

  const styleMemo = (memo: string): string => {
    if (!memo) return memo

    const parsedMemo = new window.DOMParser().parseFromString(memo, "text/html")
    fillEmptyElementsWithBr(parsedMemo, "p, code, h1, h2, h3, h4, h5, h6")
    styleTables(parsedMemo)

    return parsedMemo.body.innerHTML
  }

  function fillEmptyElementsWithBr(memo: Document, selector: string): void {
    memo.documentElement.querySelectorAll(selector).forEach(e => {
      if (e.innerHTML) return
      e.append(document.createElement("br"))
    })
  }

  function styleTables(memo: Document): void {
    memo.documentElement.querySelectorAll("table").forEach(t => {
      const tBody = t.querySelector(":scope > tbody")
      if (!tBody) return

      const firstRowCells = tBody.querySelectorAll<HTMLTableCellElement>(
        ":scope > tr:first-of-type > td"
      )
      const allCells = tBody.querySelectorAll<HTMLTableCellElement>(
        ":scope > tr > td"
      )

      t.style.width = "100%"
      t.style.borderCollapse = "collapse"
      t.style.tableLayout = "fixed"
      t.style.overflow = "hidden"
      t.style.margin = "1em 0px"
      allCells.forEach(c => {
        c.style.border = "1px solid #ddd"
        c.style.padding = "3px 5px"
      })

      const cellWidths: Array<number | null> = []
      firstRowCells.forEach(c => {
        let colspan: string | number | null = c.getAttribute("colspan")
        colspan = parseInt(colspan || "1", 10)
        if (isNaN(colspan)) colspan = 1

        const colwidths = c.getAttribute("data-colwidth")?.split(",") || []
        if (colwidths.length < colspan)
          colwidths.push(...new Array(colspan - colwidths.length).fill(null))
        else if (colwidths.length > colspan) colwidths.splice(colspan)

        cellWidths.push(
          ...colwidths
            .map(w => parseInt(w, 10))
            .map(w => (isNaN(w) || w < 1 ? null : w))
        )
      })

      const tableWidth = cellWidths.reduce<number>(
        (sum, w) => sum + (w || 0),
        0
      )
      if (tableWidth > 0) {
        let isMinWidth = false
        const colgroups = document.createElement("colgroup")
        cellWidths.forEach(w => {
          const col = document.createElement("col")
          colgroups.append(col)
          if (w === null) isMinWidth = true
          else col.style.width = `${w}px`
        })
        t.prepend(colgroups)
        if (isMinWidth) {
          // editorが幅が設定されていないカラムのmin幅を25pxにしています
          const autoColumnsWidth =
            cellWidths.filter(w => w === null).length * 25
          t.style.minWidth = `${tableWidth + autoColumnsWidth}px`
        } else t.style.width = `${tableWidth}px`
      }
    })
  }

  return {
    state: toRefs(state),
    styleMemo,
    createEventAsync,
    createBaseFolderIfNotExist,
  }
}

type memoStore = ReturnType<typeof memoState>

/**
 * @constant
 * Answererコンテナ
 */
export const memoContainer = createContainer<memoStore, State>(memoState)
