import { Agenda } from "./Meeting"

class MeetingContent {
  purpose?: string
  goal?: string
  agendas?: Array<Agenda>

  constructor(input?: MeetingContent) {
    this.purpose = input?.purpose
    this.goal = input?.goal
    this.agendas = input?.agendas?.map(a => new Agenda(a))
  }
}

export interface CandidateDateJson {
  id?: string
  start: string
  end: string
  o365iCalUId?: string
  contents?: object
}

export class CandidateDate {
  id?: string
  start: Date
  end: Date
  o365iCalUId?: string
  contents?: MeetingContent

  constructor(input?: CandidateDate | CandidateDateJson) {
    this.id = input?.id
    this.start = new Date(input?.start || "")
    this.end = new Date(input?.end || "")
    this.o365iCalUId = input?.o365iCalUId
    this.contents = new MeetingContent(input?.contents)
  }
}
