import SofieClient from "./clients/SofieClient"
import ApplicationInformation from "@/models/ApplicationInformation"

class ApplicationInformationApi {
  private _sofieClient = new SofieClient()

  getApplicationInformationAsync = async (): Promise<
    ApplicationInformation
  > => {
    return await this._sofieClient.getAsync<ApplicationInformation>(
      `ApplicationInformation`,
      { noToken: true }
    )
  }
}

export default ApplicationInformationApi
