import SofieClient from "./clients/SofieClient"
import MemoTemplate from "@/models/MemoTemplate"

class MemoTemplateApi {
  private _sofieClient = new SofieClient()

  getMemoTemplates = async (meetingId: string) => {
    const meetings = await this._sofieClient.getAsync<Array<MemoTemplate>>(
      `meetings/${meetingId}/memoTemplates`
    )
    return meetings.map(m => new MemoTemplate(m))
  }

  createMemoTemplates = async (
    meetingId: string,
    meetingMemoTemplate: MemoTemplate
  ) => {
    const meetings = await this._sofieClient.postAsync<MemoTemplate>(
      `meetings/${meetingId}/memoTemplates`,
      JSON.stringify(meetingMemoTemplate)
    )
    return meetings
  }

  updateMemoTemplate = async (
    meetingId: string,
    id: string,
    meetingMemoTemplate: MemoTemplate
  ) => {
    const meetings = await this._sofieClient.patchAsync<MemoTemplate>(
      `meetings/${meetingId}/memoTemplates/${id}`,
      JSON.stringify(meetingMemoTemplate)
    )
    return meetings
  }

  deletemeetingMemoTemplate = async (meetingId: string, id: string) => {
    await this._sofieClient.deleteAsync(
      `meetings/${meetingId}/memoTemplates/${id}`
    )
  }
}

export default MemoTemplateApi
