import * as msal from "@azure/msal-browser"
import ApplicationInformation from "@/models/ApplicationInformation"

const userNameKey = "meets-currentUserNameKey"

class AzureAdAuthentication {
  msalInstance?: msal.PublicClientApplication
  scopes?: string[]
  accounts?: msal.AccountInfo[]
  loginRequest?: msal.PopupRequest
  targetTenant = ""

  init(
    application: ApplicationInformation,
    requestProp: msal.PopupRequest = {
      scopes: ["access_as_user"],
    }
  ) {
    const msalConfig = {
      auth: {
        clientId: application.clientId,
        authority: application.authority,
        redirectUri: application.redirectUri,
      },
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
      },
    }
    this.targetTenant = application.authority
      .replace("https://login.microsoftonline.com/", "")
      .replace("/", "")
    this.loginRequest = requestProp
    this.msalInstance = new msal.PublicClientApplication(msalConfig)
    this.accounts = []
  }

  public logout() {
    localStorage.removeItem(userNameKey)
  }

  public loginAsync(): Promise<msal.AccountInfo> {
    return new Promise((resolve, reject) => {
      if (!this.msalInstance || !this.loginRequest) {
        throw new Error("please init")
      }
      this.msalInstance
        .loginPopup(Object.assign(this.loginRequest, { prompt: "login" }))
        .then(loginResult => {
          if (loginResult && loginResult.account) resolve(loginResult.account)
          else reject()
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  public getLogedInAccounts(): msal.AccountInfo[] {
    if (!this.msalInstance) {
      throw new Error("please init")
    }
    return this.msalInstance.getAllAccounts()
  }

  public getLogedInAccount(): msal.AccountInfo | null {
    if (!this.msalInstance) {
      throw new Error("please init")
    }
    const localAccountId = localStorage.getItem(userNameKey)
    if (!localAccountId) return null

    const account = this.msalInstance.getAccountByLocalId(localAccountId)
    if (
      this.targetTenant !== "common" &&
      account?.tenantId !== this.targetTenant
    ) {
      localStorage.removeItem(userNameKey)
      return null
    }
    return account
  }

  public setLogedInAccount(localAccountId: string) {
    localStorage.setItem(userNameKey, localAccountId)
  }

  public async getTokenAsync(): Promise<msal.AuthenticationResult> {
    const userAccount = this.getLogedInAccount()
    if (!userAccount) return Promise.reject()

    return new Promise((resolve, reject) => {
      if (!this.loginRequest) {
        throw new Error("please init")
      }
      const silentReques: msal.SilentRequest = {
        account: userAccount,
        scopes: this.loginRequest.scopes,
      }
      if (!this.msalInstance) {
        throw new Error("please init")
      }
      this.msalInstance
        .acquireTokenSilent(silentReques)
        .then(tokenResponse => {
          resolve(tokenResponse)
        })
        .catch(() => {
          if (!this.msalInstance || !this.loginRequest) {
            throw new Error("please init")
          }
          // ゲストアカウントを考慮してauthorityを書き換える
          const currentLoginRequest = Object.assign({}, this.loginRequest, {
            account: userAccount,
          })
          // ゲストアカウントならidpが入ってる
          if (
            userAccount.idTokenClaims &&
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (userAccount.idTokenClaims as any)["idp"]
          ) {
            Object.assign(currentLoginRequest, {
              authority: `https://login.microsoftonline.com/${userAccount.tenantId}/`,
            })
          }
          return this.msalInstance
            .acquireTokenPopup(currentLoginRequest)
            .then(tokenResponse => {
              resolve(tokenResponse)
            })
            .catch(error => {
              reject(error)
            })
        })
    })
  }
}
const azureAdAuthentication = new AzureAdAuthentication()
export default azureAdAuthentication
export { AzureAdAuthentication }
