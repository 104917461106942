import ApplicationInformation from "@/models/ApplicationInformation"
import DateTime from "@/models/DateTime"
import HttpClient from "./clients/HttpClient"

export interface MetricAccessOptions {
  endpoint: string

  token: string

  serviceId: string
}

/**
 * クラスインスタンス
 */
let instance: MetricService

export const eventType = Object.freeze({
  install: 1,
  access: 2,
  goal: 3,
})

/**
 * メトリック送付サービス
 */
class MetricService {
  private _httpClient: HttpClient
  private token: string
  private serviceId: string

  constructor() {
    this._httpClient = new HttpClient({
      baseUrl: (window.aplicationInfo as ApplicationInformation).metricEndpoint,
    })
    this.token = (window.aplicationInfo as ApplicationInformation).metricCode
    this.serviceId = (window.aplicationInfo as ApplicationInformation).metricServiceId
  }

  static sendedEvents: Array<number> = []

  /**
   * メトリックを送付します。
   *
   * @param {string} tenantId テナントID
   * @param {string} userId ユーザID
   * @param {number} eventTypeValue イベントタイプ
   * @param {MetricAccessOptions} option オプション
   */
  async createMetricAsync(
    tenantId: string,
    userId: string,
    eventTypeValue: number
  ) {
    // それぞれのイベントは1回まで
    if (MetricService.sendedEvents.some(s => s === eventTypeValue)) return
    MetricService.sendedEvents.push(eventTypeValue)

    const queryParameters = new URLSearchParams()
    queryParameters.append("code", this.token)
    await this._httpClient.postAsync(
      "events",
      JSON.stringify({
        eventType: eventTypeValue,
        tenantId,
        serviceId: this.serviceId,
        userId,
        createdAt: new DateTime(new Date()).toIsoString(),
      }),
      {
        queryParameters,
      }
    )
  }
}

export const GetMetricClient = () => {
  if (!instance) {
    instance = new MetricService()
  }
  return instance
}
