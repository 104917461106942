






































































import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import Icon from "@/components/Icon.vue"
import WithHeaderAndFooterLayout from "@/layouts/WithHeaderAndFooterLayout.vue"
import ShowSearch from "@/views/Navigation/ShowSearch.vue"
import ShowOldMeetings from "@/views/Navigation/ShowOldMeetings.vue"
import CreateMeetingStructure from "@/views/Navigation/CreateMeetingStructure.vue"
import { addBeforeEachGuard } from "@/router"
import { meetingContainer } from "@/containers/MeetingsContainer"
import { i18nContainer } from "@/containers/I18nContainer"
import { hintContainer, HintType } from "@/containers/HintContainer"

interface State {
  isSearch: boolean
  searchText: string
  currentSearchText: string
  isMenuVisible: boolean
  isLoadingCreate: boolean
  isOpenDialog: boolean
  meetingTitle: string
}

export default defineComponent({
  components: {
    I18nFormattedMessage,
    Icon,
    WithHeaderAndFooterLayout,
    ShowSearch,
    ShowOldMeetings,
    CreateMeetingStructure,
  },
  setup() {
    const { formatDateTime, formatMessage } = i18nContainer.useContainer()
    const { state: meetingState } = meetingContainer.useContainer()
    const { showHint } = hintContainer.useContainer()

    const state = reactive<State>({
      isSearch: false,
      searchText: "",
      currentSearchText: "",
      isMenuVisible: false,
      isLoadingCreate: false,
      isOpenDialog: false,
      meetingTitle: "",
    })

    const showCreateFirstMeeting = () => {
      state.isMenuVisible = true
      state.isOpenDialog = true
      showHint(HintType.First)
    }

    watch(
      () => meetingState.meetings.value,
      newValue => {
        if (!newValue?.length) showCreateFirstMeeting()
      }
    )

    onMounted(() => {
      if (!meetingState.meetings.value?.length) showCreateFirstMeeting()
    })

    const onMenuToggleClick = () => {
      state.isMenuVisible = !state.isMenuVisible
    }
    const onClickOutside = () => {
      state.isMenuVisible =
        !!meetingState.meetings.value &&
        meetingState.meetings.value.length === 0
    }
    const submit = () => {
      if (state.searchText) {
        state.isSearch = true
        state.currentSearchText = state.searchText
      }
    }

    addBeforeEachGuard((_to, _from, next) => {
      state.isMenuVisible = false
      next()
    })

    return {
      state,
      formatDateTime,
      i18nFormattedMessage: formatMessage,
      onMenuToggleClick,
      onClickOutside,
      submit,
    }
  },
})
