var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.state.isLoadingMeetings)?_c('Loading'):_c('draggable',{attrs:{"handle":".drag","move":_vm.move},on:{"end":_vm.end},model:{value:(_vm.state.meetingViewDatas),callback:function ($$v) {_vm.$set(_vm.state, "meetingViewDatas", $$v)},expression:"state.meetingViewDatas"}},_vm._l((_vm.state.meetingViewDatas),function(ref){
var meetingStructure = ref.meetingStructure;
var meetings = ref.meetings;
return _c('v-list',{key:meetingStructure.id,staticClass:"py-0"},[_c('v-list-item',{staticClass:"white black--text",attrs:{"color":"primary","title":meetingStructure.name,"to":("/meeting-series/" + (meetingStructure.id))}},[_c('v-list-item-icon',{staticClass:"mr-1"},[_c('icon',{staticClass:"drag",attrs:{"name":"drag","size":24}})],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(meetingStructure.name)+" ")])],1),_vm._l((meetings),function(item){return _c('v-list-item',{key:item.id,staticClass:"pl-8",attrs:{"exact":"","title":("" + (_vm.formatDateTime(item.startTime.toJsDate(), {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          })) + (item.subject ? (" (" + (item.subject) + ")") : '')),"to":{
          name: 'InMeeting',
          params: { parentId: meetingStructure.id, id: item.id },
        }}},[_c('v-list-item-title',[_c('I18nFormattedDateTime',{attrs:{"date-time":item.startTime.toJsDate(),"day":"2-digit","month":"2-digit","year":"numeric"}}),(item.subject)?_c('span',[_vm._v(" ("+_vm._s(item.subject)+")")]):_vm._e()],1)],1)})],2)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }