








































































import {
  computed,
  defineComponent,
  PropType,
  reactive,
  watch,
} from "@vue/composition-api"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import Icon from "@/components/Icon.vue"
import IconButton from "@/components/buttons/IconButton.vue"
import TextButton from "@/components/buttons/TextButton.vue"
import TextField from "@/components/textFields/TextField.vue"
import Card from "@/components/cards/Card.vue"
import { i18nContainer } from "@/containers/I18nContainer"
import { meetingContainer } from "@/containers/MeetingsContainer"
import User from "@/models/User"
import { required } from "@/utilities/Validation"

interface State {
  errorMsg: string
  isFormValid: boolean
}

export default defineComponent({
  props: {
    errorMsg: String,
    value: {
      type: Array as PropType<Array<User>>,
      default: [],
    },
    disabled: Boolean,
  },
  components: {
    I18nFormattedMessage,
    Icon,
    TextField,
    Card,
    IconButton,
    TextButton,
  },
  setup(props, { emit }) {
    const state = reactive<State>({
      isFormValid: false,
      errorMsg: "",
    })
    const { formatMessage } = i18nContainer.useContainer()
    const { getUserDisplayNames } = meetingContainer.useContainer()

    const attendees = computed({
      get: () => props.value,
      set: newValue => {
        emit("input", newValue)
      },
    })

    const validEmail = (email: string) => {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    }

    const nameRule = [required]

    const emailInputRules = [
      (value: string) => {
        if (value === "")
          return formatMessage({
            id: "InputEmail.required",
            defaultMessage: "必須入力です",
          })
        if (validEmail(value)) return true

        return formatMessage({
          id: "InputEmail.notValidMailAddress",
          defaultMessage: "有効なメールアドレスを入力してください",
        })
      },
    ]

    const deleteRow = (index: number) => {
      if (!attendees.value) return
      attendees.value.splice(index, 1)
      emit("input", attendees.value)
    }

    const addRow = () => {
      attendees.value.push(
        new User({
          id: "",
          displayName: "",
          email: "",
        })
      )
      emit("input", attendees.value)
    }

    watch(
      () => props.errorMsg,
      newValue => {
        state.errorMsg = newValue ?? ""
      }
    )

    const Input = (index: number) => {
      const displayNames = getUserDisplayNames()
      if (attendees.value[index] && !attendees.value[index].displayName) {
        attendees.value[index].displayName =
          displayNames[attendees.value[index].email] ?? ""
      }
      if (attendees.value) emit("input", attendees.value)
    }

    return {
      state,
      attendees,
      i18nFormattedMessage: formatMessage,
      deleteRow,
      addRow,
      nameRule,
      emailInputRules,
      Input,
    }
  },
})
