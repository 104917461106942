
























import { hintContainer, HintType } from "@/containers/HintContainer"
import DefaultDialog from "@/components/dialogs/DefaultDialog.vue"
import { defineComponent, reactive, watch } from "@vue/composition-api"

interface State {
  showHint: boolean
}

export default defineComponent({
  components: {
    DefaultDialog,
  },
  setup() {
    const { state: hideState, hideHint } = hintContainer.useContainer()
    const state = reactive<State>({
      showHint:
        hideState.showHint.value &&
        hideState.type.value === HintType.NeedUpdate,
    })
    watch(
      () => hideState.showHint.value,
      newValue => {
        state.showHint =
          newValue && hideState.type.value === HintType.NeedUpdate
      }
    )
    const closeHint = () => {
      hideHint()
    }
    return { state, closeHint }
  },
})
