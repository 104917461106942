
















import { defineComponent } from "@vue/composition-api"

export default defineComponent({
  props: {
    customName: {
      type: String,
      default: "not_specified",
    },
    buttonColor: {
      type: String,
      default: "primary",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    elavation: {
      type: [String, Number],
      default: 2,
    },
    height: {
      type: [String, Number],
      default: 30,
    },
    width: {
      type: [String, Number],
    },
    outlined: Boolean,
  },
  setup(_, { emit, slots }) {
    if (!slots.default) throw new Error("no-default-slot")

    const onClick = () => {
      emit("click")
    }

    return { onClick }
  },
})
