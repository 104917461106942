import { computed, reactive, toRefs } from "@vue/composition-api"
import { createContainer } from "./Container"
import RegularMeetingSolutionError from "@/models/RegularMeetingSolutionError"
import { Route } from "../router"
import RouteName, { routeNameFromString } from "@/constants/RouteName"
import TenantApi from "@/api/TenantApi"
import Tenant from "@/models/Tenant"
import ApplicationError from "@/models/Errors/ApplicationError"

interface State {
  isAuthenticating: boolean
  isAuthenticated: boolean
  tenant: Tenant | null
}

function getDefaultState(): State {
  return {
    isAuthenticating: true,
    isAuthenticated: false,
    tenant: null,
  }
}

const storageKey = (pid: string) => `appContainer.beforePageKey_${pid}`
const tenantApi = new TenantApi()

/**
 * @private
 * Appコンテナの定義
 * @param props - 既定以外の初期化が必要な場合に指定する
 */
function useApp() {
  const state = reactive<State>(getDefaultState())
  if (!state)
    throw new RegularMeetingSolutionError({
      message: "No app state found.",
    })

  const isAuthenticating = computed<boolean>(() => state.isAuthenticating)

  const setIsAuthenticating = (newValue: boolean): void => {
    state.isAuthenticating = newValue
  }

  const isAuthenticated = computed<boolean>(() => state.isAuthenticated)

  const setIsAuthenticated = (newValue: boolean): void => {
    state.isAuthenticated = newValue
  }

  const getCurrentTenant = async (): Promise<Tenant> => {
    if (!state.tenant) {
      state.tenant = await tenantApi.getTenant()
      if (!state.tenant) {
        throw new ApplicationError({
          message: "契約情報が見つかりません",
          showMessageModal: true,
          showDetail: false,
        })
      }
    }
    return state.tenant
  }

  const isRedirectableUrl = (to: Route, allowMeetingSeriesRoot: boolean) => {
    if (!to.name) return false
    const rootName = routeNameFromString(to.name)
    switch (rootName) {
      case RouteName.MeetingSeries:
        if (allowMeetingSeriesRoot || to.params.id) return true
        else return false

      case RouteName.InMeeting:
      case RouteName.MeetingInfo:
      case RouteName.NotificationSettings:
      case RouteName.MemoTemplateSettings:
      case RouteName.ExternalLinkSettings:
      case RouteName.NewMeetingCreation:
      case RouteName.MeetingCreationFromOutlook:
      case RouteName.ConfirmApproves:
      case RouteName.PastMemoList:
        return true

      case RouteName.Authenticate:
      case RouteName.AuthenticateBrowse:
      case RouteName.Login:
      case RouteName.AuthorizeStart:
      case RouteName.AuthorizeEnd:
      case RouteName.TabConfig:
      case RouteName.RemoveConfig:
      case RouteName.PersonalSearch:
      case RouteName.PersonalNotification:
      case RouteName.PersonalConfirmMemo:
        return false

      default:
        return false
    }
  }

  const isRealPage = (route: Route) => isRedirectableUrl(route, false)

  const getBeforePage = (to: Route, projectId: string) => {
    try {
      if (!isRedirectableUrl(to, true)) return null
      const ret = localStorage.getItem(storageKey(projectId))
      if (ret) {
        const data = JSON.parse(ret) as { limit: number; value: string }
        localStorage.removeItem(storageKey(projectId))
        if (new Date() < new Date(data.limit)) {
          return data.value
        }
      }
      return null
    } catch {
      // localstorageにアクセスできない場合はページ保存機能を無効
      return null
    }
  }

  const setBeforePage = (to: Route, projectId: string) => {
    try {
      if (!isRedirectableUrl(to, false)) return
      const min15 = 1000 * 60 * 15
      const value = { limit: new Date().getTime() + min15, value: to.path }
      localStorage.setItem(storageKey(projectId), JSON.stringify(value))
    } catch {
      // localstorageにアクセスできない場合はページ保存機能を無効
      return
    }
  }

  return {
    state: toRefs(state),
    isAuthenticating,
    setIsAuthenticating,
    isAuthenticated,
    isRealPage,
    setIsAuthenticated,
    getBeforePage,
    setBeforePage,
    getCurrentTenant,
  }
}

type AppStore = ReturnType<typeof useApp>

/**
 * @constant
 * Appコンテナ
 */
export const appContainer = createContainer<AppStore, State>(useApp)
