import MsGraphClient from "./clients/MsGraphClient"
import * as MicrosoftGraph from "@microsoft/microsoft-graph-types"
import ApplicationError from "@/models/Errors/ApplicationError"
import { DriveItem } from "@microsoft/microsoft-graph-types"
import ApiError from "@/models/Errors/ApiError"

class FileApi {
  private _client = new MsGraphClient()

  _normarizePath = (path: string) => {
    let requestPath = path.startsWith("/") ? path : `/${path}`
    requestPath = requestPath.endsWith("/")
      ? requestPath.substring(0, requestPath.length - 1)
      : requestPath
    return requestPath
  }

  _fileUploadSliceImple = async (
    url: string,
    startByte: number,
    max: number,
    file: ArrayBuffer,
    retries = 0
  ) => {
    let response = await window.fetch(
      new Request(url, {
        method: "PUT",
        headers: {
          "Content-Length": `${file.byteLength}`,
          "Content-Range": `bytes ${startByte}-${startByte +
            file.byteLength -
            1}/${max}`,
        },
        body: file,
      })
    )
    // 500番はリトライするらしい
    if (response.status >= 500) {
      response = await this._fileUploadSliceImple(
        url,
        startByte,
        max,
        file,
        retries + 1
      )
    }
    return response
  }

  _chunkUploadFiles = (
    url: string,
    file: File,
    index: number,
    chunkSize: number
  ) => {
    const reader = new FileReader()
    const start = index * chunkSize
    const stop = start + chunkSize
    const blob = file.slice(start, stop)
    return new Promise<Response>(res => {
      reader.onloadend = evt => {
        if (evt.target && evt.target.readyState === FileReader.DONE) {
          const readValue = evt.target.result as ArrayBuffer
          this._fileUploadSliceImple(
            `${url}`,
            index * chunkSize,
            file.size,
            readValue,
            0
          ).then(response => {
            res(response)
          })
        }
      }
      reader.readAsArrayBuffer(blob)
    })
  }

  _putUploadToSessionAsync = async (
    url: string,
    file: File,
    onProcessing: ((uploadedFileSize: number) => void) | null = null
  ) => {
    // 10メガずつぐらいでアップロードする
    const maxChunkUnitSize = 327680 * 30
    const chunkSize =
      file.size > maxChunkUnitSize ? maxChunkUnitSize : file.size
    const chunks = Math.ceil(file.size / chunkSize)
    for (const index of [...Array(chunks).keys()]) {
      await this._chunkUploadFiles(url, file, index, chunkSize)
      if (onProcessing) onProcessing(chunkSize * (index + 1))
    }
  }

  deleteFileItem = async (siteId: string, itemId: string) => {
    await this._client.deleteAsync(`sites/${siteId}/drive/items/${itemId}`)
  }

  _getFileItemsImple = async (baseUrl: string, path: string) => {
    let requestUri = ""
    const requestPath = this._normarizePath(path)
    if (path === "/") requestUri = `${baseUrl}/drive/root/children`
    else requestUri = `${baseUrl}/drive/root:${requestPath}:/children`
    const rets = []
    while (requestUri) {
      requestUri = requestUri.replace("https://graph.microsoft.com/v1.0/", "")
      const currentRet = await this._client.getAsync<{
        "@odata.nextLink": string
        value: Array<MicrosoftGraph.DriveItem>
      }>(requestUri)
      rets.push(...currentRet.value)
      requestUri = currentRet["@odata.nextLink"] ?? ""
    }
    return rets
  }

  getFileItemsByGroupId = async (groupId: string, path = "/") => {
    const baseUrl = `groups/${groupId}`
    return await this._getFileItemsImple(baseUrl, path)
  }

  getFileItems = async (siteId: string, path = "/") => {
    const baseUrl = `sites/${siteId}`
    return await this._getFileItemsImple(baseUrl, path)
  }

  getFileItem = async (siteId: string, path = "/") => {
    const requestPath = this._normarizePath(path)
    const srcDriveItem = await this._client.getAsync<DriveItem>(
      `sites/${siteId}/drive/root:${requestPath}`
    )
    return srcDriveItem
  }

  copyFileItem = async (
    siteId: string,
    srcFilePath: string,
    distFilePath: string,
    fileName: string
  ) => {
    const abstractPath = srcFilePath.split("Shared%20Documents")[1]
    const requestPath = this._normarizePath(abstractPath)
    const dist = this._normarizePath(distFilePath)
    const srcDriveItem = await this._client.getAsync<DriveItem>(
      `sites/${siteId}/drive/root:${requestPath}`
    )
    const distFileItem = await this._client.getAsync<DriveItem>(
      `sites/${siteId}/drive/root:${dist}`
    )
    await this._client.postAsync<null>(
      `sites/${siteId}/drive/items/${srcDriveItem.id}/copy`,
      JSON.stringify({
        parentReference: {
          driveId: distFileItem.parentReference?.driveId,
          id: distFileItem.id,
        },
        name: fileName,
      }),
      undefined,
      _ => {
        return Promise.resolve(null)
      }
    )
  }

  getFileItemContents = async (item: MicrosoftGraph.DriveItem) => {
    return new Promise<string>(resolve => {
      const oReq = new XMLHttpRequest()
      oReq.addEventListener("load", () => {
        resolve(oReq.response)
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      oReq.open("GET", (item as any)["@microsoft.graph.downloadUrl"])
      oReq.send()
    })
  }

  uploadSmallFile = async (siteId: string, path: string, file: File) => {
    let requestUri = ""
    const requestPath = this._normarizePath(path)
    if (path === "/")
      requestUri = `sites/${siteId}/drive/root:/${file.name}:/content`
    else
      requestUri = `sites/${siteId}/drive/root:${requestPath}/${file.name}:/content`
    return await this._client.putAsync<DriveItem>(requestUri, file, {
      noContentType: true,
    })
  }

  createFolder = async (siteId: string, path: string) => {
    let requestUri = ""
    const elements = this._normarizePath(path).split("/")
    const name = elements[elements.length - 1]
    const requestPath = elements.slice(0, elements.length - 1).join("/")
    if (path === "/") throw new Error("ルートの作成は無効です")
    if (!requestPath) requestUri = `sites/${siteId}/drive/root/children`
    else requestUri = `sites/${siteId}/drive/root:${requestPath}:/children`
    const ret = await this._client.postAsync<DriveItem>(
      requestUri,
      JSON.stringify({
        name,
        folder: {},
      })
    )
    return ret
  }

  createFolderIfNotExist = async (siteId: string, filePath: string) => {
    try {
      await this.getFileItems(siteId, filePath)
    } catch (e) {
      if (e instanceof ApiError) {
        if (e.status === 404) {
          // 開始の「/」と２つ以上ある場合
          const hasPath = (filePath.match(/\//g)?.length ?? 0) > 1
          if (hasPath) {
            const files = filePath.split("/")
            files.pop()
            await this.createFolderIfNotExist(siteId, files.join("/"))
          }
          await this.createFolder(siteId, filePath)
        } else throw e
      }
    }
  }

  fileUpload = async (siteId: string, path: string, uploadFile: File) => {
    const boarderSize = 4000000
    if (uploadFile.size <= boarderSize) {
      return await this.uploadSmallFile(siteId, path, uploadFile)
    } else {
      await this.uploadFile(siteId, path, uploadFile)
      const files = await this.getFileItems(siteId, path)
      return files.find(f => f.name === uploadFile.name)
    }
  }

  uploadFile = async (
    siteId: string,
    path: string,
    file: File,
    onProcessing: ((uploadedFileSize: number) => void) | null = null
  ) => {
    let requestUri = ""
    const requestPath = this._normarizePath(path)
    if (path === "/")
      requestUri = `sites/${siteId}/drive/root:/${file.name}:/createUploadSession`
    else
      requestUri = `sites/${siteId}/drive/root:${requestPath}/${file.name}:/createUploadSession`
    const currentRet = await this._client.postAsync<
      MicrosoftGraph.UploadSession
    >(
      requestUri,
      JSON.stringify({
        description: "",
        fileSize: file.size,
        name: file.name,
      } as MicrosoftGraph.DriveItemUploadableProperties)
    )
    if (!currentRet.uploadUrl)
      throw new ApplicationError({
        message: `${file.name}のアップロードに失敗しました。`,
        showMessageModal: true,
      })
    await this._putUploadToSessionAsync(
      currentRet.uploadUrl,
      file,
      onProcessing
    )
  }
}

export function getCleanPath(pathElement: string) {
  const newPath = pathElement.replace(/[\s~#%&*{}¥:<>?/+|\\"]/g, "")
  return newPath
}
export default FileApi
