export interface ApplicationErrorConstructorInput {
  message: string
  showMessageModal: boolean
  showDetail?: boolean
}

class ApplicationError extends Error {
  message: string
  showMessageModal: boolean
  showDetail: boolean

  constructor(base: ApplicationErrorConstructorInput) {
    super(base.message)

    this.message = base.message
    this.showMessageModal = base.showMessageModal
    this.showDetail = base.showDetail === undefined ? true : base.showDetail
  }
}

export default ApplicationError
