import DateTime from "@/models/DateTime"
import { DriveItem } from "@microsoft/microsoft-graph-types"

class ProjectFile {
  name = ""
  webUrl = ""
  parentPath = ""
  id: string
  isFolder: boolean
  isPackage: boolean
  packageType: string | null = null
  lastModifiedDateTime: DateTime | null = null

  constructor(input: ProjectFile | DriveItem, parentPath = "") {
    this.webUrl = input.webUrl || ""
    this.id = input.id || ""
    this.name = input.name || ""
    this.lastModifiedDateTime = new DateTime(input.lastModifiedDateTime || "")
    this.parentPath = parentPath
    if (Object.prototype.hasOwnProperty.call(input, "parentPath")) {
      this.parentPath = (input as ProjectFile)?.parentPath
    } else {
      this.parentPath =
        (input as DriveItem)?.parentReference?.path?.replace(
          "/drive/root:",
          ""
        ) + "/"
    }
    if (Object.prototype.hasOwnProperty.call(input, "isFolder")) {
      this.isFolder = (input as ProjectFile)?.isFolder
    } else {
      this.isFolder = !!(input as DriveItem)?.folder
    }
    if (Object.prototype.hasOwnProperty.call(input, "package")) {
      this.isPackage = !!(input as DriveItem)?.package
      this.packageType = (input as DriveItem)?.package?.type || null
    } else {
      this.isPackage = (input as ProjectFile)?.isPackage
      this.packageType = (input as ProjectFile)?.packageType
    }
  }
}

export default ProjectFile
