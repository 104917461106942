import { reactive, toRefs } from "@vue/composition-api"
import { createContainer } from "./Container"
import FileApi from "@/api/FileApi"
import ProjectFile from "@/models/ProjectFile"
import Notification from "@/models/Notification"
import DateTime from "@/models/DateTime"
import User from "@/models/User"
import NotificationApi from "@/api/NotificationApi"
import html2canvas from "html2canvas"
import { v4 as uuidV4 } from "uuid"
import TeamsContext from "@/models/TeamsContext"
import { DriveItem } from "@microsoft/microsoft-graph-types"

const fileApi = new FileApi()
const notificationApi = new NotificationApi()

interface State {
  fileItems: { [key: string]: DriveItem[] }
  isDownloadingPdf: boolean
  isInited: boolean
}

function notificationState(props?: State | null) {
  const state = reactive<State>(
    props ?? { fileItems: {}, isDownloadingPdf: false, isInited: false }
  )

  const notificationFolderPath = "/teirei-assist_temp"
  const notificationFolderPathOld = "/metis_sofie_temp"

  const createNotificationBaseFolderIfNotExist = async (siteId: string) => {
    fileApi.createFolderIfNotExist(siteId, notificationFolderPath)
  }

  const htmlToJpegFile = async (target: HTMLElement, fileName: string) => {
    const canvas = await html2canvas(target, {
      // なぜか微妙にキャンバスがずれるので目視調整
      scrollX: -8.5,
      scale: 2,
    })
    const type = "image/jpeg"
    const dataurl = canvas.toDataURL(type)
    const bin = atob(dataurl.split(",")[1])
    const buffer = new Uint8Array(bin.length)
    for (let i = 0; i < bin.length; i++) {
      buffer[i] = bin.charCodeAt(i)
    }
    return new File([buffer.buffer], fileName, { type })
  }

  const htmlToFile = (target: HTMLElement, fileName: string) => {
    const html = target.innerHTML
    const type = "text/html"
    return new File([html], fileName, { type })
  }

  const fileUpload = async (siteId: string, path: string, uploadFile: File) => {
    const boarderSize = 4000000
    if (uploadFile.size <= boarderSize) {
      await fileApi.uploadSmallFile(siteId, path, uploadFile)
    } else {
      await fileApi.uploadFile(siteId, path, uploadFile)
    }
  }

  const deleteNotification = async (
    siteId: string,
    notificationId: string,
    driveItemIds: Array<string>
  ) => {
    const tasks = driveItemIds.map(item => fileApi.deleteFileItem(siteId, item))
    tasks.push(notificationApi.removeNotification(notificationId))
    await Promise.all(tasks)
  }

  const createNotification = async (
    meetingId: string,
    siteId: string,
    context:
      | TeamsContext
      | {
          channelId: string
          teamId: string
          isPrivate: boolean
        },
    target: HTMLElement,
    notificationDate: DateTime,
    users: User[]
  ) => {
    const currentDataId = uuidV4()
    const htmlFile = htmlToFile(target, `notification_${currentDataId}.html`)
    const bodyImageFile = await htmlToJpegFile(
      target,
      `notification_${currentDataId}.jpg`
    )
    const targetPath = `${notificationFolderPath}/${meetingId}`
    const key = `${siteId}_${targetPath}`
    delete state.fileItems[key]

    await fileApi.createFolderIfNotExist(siteId, targetPath)
    await Promise.all([
      fileUpload(siteId, targetPath, bodyImageFile),
      fileUpload(siteId, targetPath, htmlFile),
    ])
    const files = await fileApi.getFileItems(siteId, targetPath)
    const file = files
      .map(f => new ProjectFile(f))
      .find(f => bodyImageFile.name === f.name)
    if (!file) throw new Error("NotFound")
    const notification = new Notification({
      meetingId,
      bodyImageUrl: file.webUrl,
      notificationDate,
      channelId: context.channelId,
      teamId: context.teamId,
      userIds: users.map(u => u.userId || ""),
      button: null,
      isFromPrivate: context.isPrivate,
    } as Notification)
    const ret = await notificationApi.createNotification(notification)
    return {
      driveItems: files
        .filter(f => f.name?.includes(currentDataId))
        .map(f => f.id),
      notification: new Notification(ret),
    }
  }

  const getMyNotification = async () => {
    return await notificationApi.getMyNotifications()
  }

  const getNotificationItems = async (
    groupId: string,
    siteId: string,
    imageUrl: string
  ) => {
    const ret: { [key: string]: DriveItem | null } = {}
    const fileImages = imageUrl.split("/")
    const fileName = fileImages[fileImages.length - 1]
    const meetingId = fileImages[fileImages.length - 2]
    const targetPath = imageUrl.includes(notificationFolderPathOld)
      ? `${notificationFolderPathOld}/${meetingId}`
      : `${notificationFolderPath}/${meetingId}`
    const key = `${siteId}_${targetPath}`
    const files =
      state.fileItems[key] ||
      (siteId
        ? await fileApi.getFileItems(siteId, targetPath).catch(() => {
            window.appInsights.trackTrace({
              message: "フォルダが見つかりませんでした。",
            })
            return [] as Array<DriveItem>
          })
        : await fileApi.getFileItemsByGroupId(groupId, targetPath))
    state.fileItems[key] = files
    ret["jpg"] = files.find(file => fileName === file.name) || null
    const htmlFileName = fileName.replace(".jpg", ".html")
    ret["html"] = files.find(file => htmlFileName === file.name) || null
    return ret
  }

  const getNotificationContents = async (item: DriveItem) => {
    const contents = await fileApi.getFileItemContents(item)
    return contents
  }

  return {
    state: toRefs(state),
    deleteNotification,
    createNotificationBaseFolderIfNotExist,
    createNotification,
    getMyNotification,
    getNotificationItems,
    getNotificationContents,
  }
}

type notificationStore = ReturnType<typeof notificationState>

/**
 * @constant
 * Answererコンテナ
 */
export const notificationContainer = createContainer<notificationStore, State>(
  notificationState
)
