import { reactive, toRefs } from "@vue/composition-api"
import { createContainer } from "./Container"
import ManagementApi from "@/api/ManagementApi"
import { v4 as uuidV4 } from "uuid"
import { ListStructure, TextColumn } from "@/models/Management/listStructure"

const api = new ManagementApi()

interface State {
  currentListStructure: ListStructure
}

function getDefaultState(): State {
  const memo = new TextColumn({
    name: "memo",
    displayName: "議事録",
    text: {
      allowMultipleLines: true,
      maxLength: 10000,
      textType: "richText",
      linesForEditing: 6,
      appendChangesToExistingText: false,
    },
  } as TextColumn)
  const users = new TextColumn({
    name: "Users",
    displayName: "参加者",
    text: {
      allowMultipleLines: true,
      maxLength: 1000,
      textType: "plain",
      linesForEditing: 1,
      appendChangesToExistingText: false,
    },
  } as TextColumn)
  const files = new TextColumn({
    name: "Files",
    displayName: "利用ファイル",
    text: {
      allowMultipleLines: true,
      maxLength: 1000,
      textType: "plain",
      linesForEditing: 1,
      appendChangesToExistingText: false,
    },
  } as TextColumn)
  const search = new TextColumn({
    name: "Search",
    displayName: "検索フィールド",
    text: {
      allowMultipleLines: true,
      maxLength: 10000,
      textType: "richText",
      linesForEditing: 6,
      appendChangesToExistingText: false,
    },
  } as TextColumn)
  const time = new TextColumn({
    name: "Time",
    displayName: "開催時間",
    text: {
      allowMultipleLines: false,
      maxLength: 255,
      textType: "plain",
      appendChangesToExistingText: false,
    },
  } as TextColumn)
  const location = new TextColumn({
    name: "Location",
    displayName: "開催場所",
    text: {
      allowMultipleLines: false,
      maxLength: 255,
      textType: "plain",
      appendChangesToExistingText: false,
    },
  } as TextColumn)
  return {
    currentListStructure: new ListStructure({
      displayName: "teirei-assist_meetings",
      columns: [memo, users, files, search, time, location],
    } as ListStructure),
  }
}

function useListStructure(props?: State | null) {
  const state = reactive<State>(props ?? getDefaultState())

  const createNewListStructureAsync = async (siteid: string) => {
    state.currentListStructure.displayName = `teirei-assist_meetings_${uuidV4()}`
    const ret = await api.createListStructure(
      siteid,
      state.currentListStructure
    )
    return ret
  }

  const syncStructure = async (siteId: string, listId: string) => {
    const ret = await api.getListStructure(siteId, listId)
    state.currentListStructure.columns.forEach(async cldef => {
      if (
        !ret.columns?.some(
          cc => cc.name?.toLowerCase() === cldef.name.toLowerCase()
        )
      ) {
        await api.updateListStructure(siteId, listId, cldef)
      }
    })
    return ret
  }

  return {
    state: toRefs(state),
    createNewListStructureAsync,
    syncStructure,
  }
}

type ListStructureStore = ReturnType<typeof useListStructure>

/**
 * @constant
 * Answererコンテナ
 */
export const listStructureContainer = createContainer<
  ListStructureStore,
  State
>(useListStructure)
