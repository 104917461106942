





























import { defineComponent, onBeforeUpdate, ref } from "@vue/composition-api"
import Icon from "@/components/Icon.vue"

export default defineComponent({
  props: {
    customName: {
      type: String,
      default: "not_specified",
    },
    iconPack: String,
    iconName: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    iconColor: String,
    iconSize: {
      type: Number,
      default: 26,
    },
    elevation: {
      type: [String, Number],
      default: 2,
    },
    tooltip: {
      type: String,
      default: "",
    },
    smallBtn: Boolean,
  },
  components: {
    Icon,
  },
  setup(_props, { emit, slots }) {
    const hasDefaultSlot = () => !!slots.default

    const hasLabel = ref(hasDefaultSlot())

    onBeforeUpdate(() => {
      hasLabel.value = hasDefaultSlot()
    })

    const onClick = () => {
      emit("click")
    }

    return {
      hasLabel,
      onClick,
    }
  },
})
