import ErrorResponse from "@/models/ErrorResponse"
import ApiError from "@/models/Errors/ApiError"
import { ACCEPTED, CREATED, NO_CONTENT, OK } from "http-status"

export interface HttpClientConstructorInput {
  baseUrl: string
}

export interface HttpRequestOptions {
  headers?: Record<string, string>
  queryParameters?: URLSearchParams
  noContentType?: boolean
}

export interface HttpClientConstructorInput {
  /**
   * ベースURL
   */
  baseUrl: string
}

/**
 * 内部リクエストオプション
 */
interface HttpRequestOptionsInner extends HttpRequestOptions {
  /**
   * リクエストの中身
   */
  body?: string | FormData | File
}

class HttpClient {
  private _baseUrl: string

  constructor(input: HttpClientConstructorInput) {
    this._baseUrl = input.baseUrl
  }

  /**
   * pathにGETリクエストを送ります。
   *
   * @param {string} path 送り先
   * @param {HttpRequestOptions} [options] リクエストオプション
   * @returns {Promise<Response>} リクエスト結果
   */
  getAsync = async <T>(
    path: string,
    options?: HttpRequestOptions,
    responseHandler: ((r: Response) => Promise<T>) | null = null
  ): Promise<T> => {
    const opts = this._getRequestOptions(options)
    const request = this._getRequest("GET", path, opts)
    return this._sendRequestAsync<T>(
      request,
      responseHandler ?? this.responseToJson
    )
  }

  /**
   * pathにPOSTリクエストを送ります。
   *
   * @param {string} path 送り先
   * @param {string} [data] 送るデータ
   * @param {HttpRequestOptions} [options] リクエストオプション
   * @returns {Promise<Response>} リクエスト結果
   */
  postAsync = async <T>(
    path: string,
    data?: string | FormData,
    options?: HttpRequestOptions,
    responseHandler: ((r: Response) => Promise<T>) | null = null
  ): Promise<T> => {
    const opts = this._getRequestOptions(options, data)
    const request = this._getRequest("POST", path, opts)
    return this._sendRequestAsync<T>(
      request,
      responseHandler ?? this.responseToJson
    )
  }

  /**
   * pathにPATCHリクエストを送ります。
   *
   * @param {string} path 送り先
   * @param {string} [data] 送るデータ
   * @param {HttpRequestOptions} [options] リクエストオプション
   * @returns {Promise<Response>} リクエスト結果
   */
  patchAsync = async <T>(
    path: string,
    data?: string,
    options?: HttpRequestOptions,
    responseHandler: ((r: Response) => Promise<T>) | null = null
  ): Promise<T> => {
    const opts = this._getRequestOptions(options, data)
    const request = this._getRequest("PATCH", path, opts)
    return this._sendRequestAsync<T>(
      request,
      responseHandler ?? this.responseToJson
    )
  }

  /**
   * pathにDELETEリクエストを送ります。
   *
   * @param {string} path 送り先
   * @param {HttpRequestOptions} [options] リクエストオプション
   * @returns {Promise<Response>} リクエスト結果
   */
  deleteAsync = async <T>(path: string, options?: HttpRequestOptions) => {
    const opts = this._getRequestOptions(options)
    const request = this._getRequest("DELETE", path, opts)
    return this._sendRequestAsync(request, () => Promise.resolve())
  }

  putAsync = async <T>(
    path: string,
    data?: string | FormData | File,
    options?: HttpRequestOptions,
    responseHandler: ((r: Response) => Promise<T>) | null = null
  ) => {
    const opts = this._getRequestOptions(options, data)
    const request = this._getRequest("PUT", path, opts)
    return this._sendRequestAsync<T>(
      request,
      responseHandler ?? this.responseToJson
    )
  }

  /**
   * リクエストを作成して返します。
   *
   * @param {string} method HTTPメソッド
   * @param {string} path 送り先
   * @param {HttpRequestOptionsInner} options リクエストオプション
   * @returns {Request} 作成したリクエスト
   */
  private _getRequest(
    method: string,
    path: string,
    options: HttpRequestOptionsInner
  ) {
    const url = this._getUrl(path, options.queryParameters)
    const headers = options.headers
    const requestInit: RequestInit = {
      method,
      headers,
      cache: "no-cache",
    }

    if (options.body !== undefined) requestInit.body = options.body
    return new Request(url, requestInit)
  }

  private async responseToJson<T>(response: Response) {
    const json: T = await response.json()
    return json
  }

  /**
   * リクエストを送ります。
   *
   * https://developer.mozilla.org/ja/docs/Web/API/Fetch_API/Using_Fetch
   *
   * @param {Request} request 送るリクエスト
   * @returns {Promise<Response>} リクエスト結果
   */
  private _sendRequestAsync = async <T>(
    request: Request,
    func: (r: Response) => Promise<T>
  ): Promise<T> => {
    //try ~ catchで書くと、ここでエラーをキャッチできない（ApiErrorがスローされない）
    return window
      .fetch(request)
      .then(async response => {
        //全てのAPIコールに共通的なエラー処理を実装する。個別のエラー処理はコール元で行う。
        if (
          response.status === ACCEPTED ||
          response.status === OK ||
          response.status === CREATED ||
          response.status === NO_CONTENT
        ) {
          return await func(response)
        }
        let errorRes: ErrorResponse | undefined = undefined
        try {
          const body = await response.json()
          errorRes = {
            code: body?.code || "",
            details: body?.details || {},
            summary: body?.summary || "",
          }
        } catch {
          console.log("Api Error")
        }
        throw new ApiError({ status: response.status, response }, errorRes)
      })
      .catch(e => {
        // thenの中でthrowされたApiErrorはそのままthrow
        if (e instanceof ApiError) {
          throw e
        } else {
          // 原因不明系もApiのError Status 0とする
          throw new ApiError({
            status: 0,
            message: e.message,
          })
        }
      })
  }

  /**
   * pathのqueryParametersを追加したURLを取得します。
   *
   * @param {string} path パス
   * @param {HttpQueryParameters} [queryParameters] URLに追加するクエリパラメータ
   * @returns {string} pathのqueryParametersを追加したURL
   */
  private _getUrl = (path: string, queryParameters?: URLSearchParams) => {
    const params = queryParameters ? `?${queryParameters.toString()}` : ""
    return `${this._baseUrl}/${path}${params}`
  }

  /**
   * 内部リクエストオプションを作成して返します。
   *
   * @param {HttpRequestOptions} [options] リクエストオプション
   * @param {string | FormData } [body] リクエストの中身
   * @returns {HttpRequestOptionsInner} 作成した内部リクエストオプション
   */
  private _getRequestOptions = (
    options?: HttpRequestOptions,
    body?: string | FormData | File
  ): HttpRequestOptionsInner => {
    return { ...options, body }
  }
}

export default HttpClient
