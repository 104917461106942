




import { defineComponent } from "@vue/composition-api"

export default defineComponent({
  props: {
    color: String,
    size: {
      type: [Number, String],
      default: 32,
    },
  },

  setup(props) {
    const progressColor = getColor(props.color)

    return { progressColor }
  },
})

function getColor(color: string | undefined) {
  if (!color) return "primary"
  return color === "default" ? undefined : color
}
