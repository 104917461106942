import { AadUserConversationMember } from "@microsoft/microsoft-graph-types"

class User {
  id = ""
  email = ""
  displayName?: string
  userId?: string
  roles?: Array<string>

  constructor(input?: AadUserConversationMember) {
    if (!input) return
    if (input.id) this.id = input.id
    if (input.email) this.email = input.email
    if (input.displayName) this.displayName = input.displayName
    if (input.userId) this.userId = input.userId
    if (input.roles) this.roles = input.roles
  }
}

export default User
