/* eslint-disable @typescript-eslint/no-explicit-any */
import VueRouter, {
  RouteConfig,
  Route as VueRouterRoute,
  Location as VueRouterLocation,
} from "vue-router"
import { RoutePropsFunction, Dictionary } from "vue-router/types/router"
import { assertExhaustive } from "@/utilities/switch"
import RouteName, {
  routeNames,
  routeNameToString,
  routeNameFromString,
} from "@/constants/RouteName"
import { Props as LoginProps } from "@/views/Login/types"
import { Props as AuthenticateProps } from "@/views/Authenticate/types"
import { Props as AuthenticateBrowseProps } from "@/views/AuthenticateBrowse/types"
import { Props as AuthorizeStartProps } from "@/views/AuthorizeStart/types"
import { Props as AuthorizeEndProps } from "@/views/AuthorizeEnd/types"
import { Props as MeetingSeriesProps } from "@/views/MeetingSeries/types"
import { Props as InMeetingProps } from "@/views/InMeeting/types"
import { Props as TabConfigProps } from "@/views/TabConfig/types"
import { Props as RemoveConfigProps } from "@/views/RemoveConfig/types"
import { Props as MeetingInfoProps } from "@/views/MeetingInfo/types"
import { Props as NotificationSettingsProps } from "@/views/NotificationSettings/types"
import { Props as MemoTemplateSettingsProps } from "@/views/MemoTemplateSettings/types"
import { Props as ExternalLinkSettingsProps } from "@/views/ExternalLinkSettings/types"
import { Props as NewMeetingCreationProps } from "@/views/NewMeetingCreation/types"
import { Props as MeetingCreationFromOutlookProps } from "@/views/MeetingCreationFromOutlook/types"
import { Props as PastMemoListProps } from "@/views/PastMemoList/types"
import { Props as PersonalSearchProps } from "@/views/PersonalSearch/types"
import { Props as PersonalNotificationProps } from "@/views/PersonalNotification/types"
import { Props as ConfirmApprovesProps } from "@/views/ConfirmApproves/types"
import { Props as MeetingCreationFromExternalProps } from "@/views/MeetingCreationFromExternal/types"

export interface Route extends VueRouterRoute {}

type RouteParams<T extends RouteName, Props> = { name: T; params: Props }
export type RouteLocation =
  | RouteParams<RouteName.Authenticate, AuthenticateProps>
  | RouteParams<RouteName.AuthenticateBrowse, AuthenticateBrowseProps>
  | RouteParams<RouteName.Login, LoginProps>
  | RouteParams<RouteName.AuthorizeStart, AuthorizeStartProps>
  | RouteParams<RouteName.AuthorizeEnd, AuthorizeEndProps>
  | RouteParams<RouteName.InMeeting, InMeetingProps>
  | RouteParams<RouteName.MeetingSeries, MeetingSeriesProps>
  | RouteParams<RouteName.TabConfig, TabConfigProps>
  | RouteParams<RouteName.RemoveConfig, RemoveConfigProps>
  | RouteParams<RouteName.MeetingInfo, MeetingInfoProps>
  | RouteParams<RouteName.NotificationSettings, NotificationSettingsProps>
  | RouteParams<RouteName.MemoTemplateSettings, MemoTemplateSettingsProps>
  | RouteParams<RouteName.ExternalLinkSettings, ExternalLinkSettingsProps>
  | RouteParams<RouteName.NewMeetingCreation, NewMeetingCreationProps>
  | RouteParams<
      RouteName.MeetingCreationFromOutlook,
      MeetingCreationFromOutlookProps
    >
  | RouteParams<RouteName.PastMemoList, PastMemoListProps>
  | RouteParams<RouteName.PersonalSearch, PersonalSearchProps>
  | RouteParams<RouteName.PersonalNotification, PersonalNotificationProps>
  | RouteParams<RouteName.ConfirmApproves, ConfirmApprovesProps>
  | RouteParams<
      RouteName.MeetingCreationFromExternal,
      MeetingCreationFromExternalProps
    >

export type Location =
  | { name: string; params?: Record<string, any> }
  | RouteLocation

export type RawLocation = string | VueRouterLocation

export type NavigationGuard = (
  to: Route,
  from: Route,
  next: (to?: RawLocation | false | ((vm: Vue) => any) | void) => void
) => Promise<void> | void

interface RoutingInformation {
  path: string
  props?: boolean | Record<string, any> | RoutePropsFunction
  viewComponent?: {
    name?: string
    isFile?: boolean
  }
  requiresAuthentication?: boolean
  hideAppNavigation?: boolean
}

const routes: Array<RouteConfig> = [
  ...routeNames.map(r => {
    const {
      viewComponent = {},
      requiresAuthentication = true,
      hideAppNavigation = false,
      ...rest
    } = getRoutingInformation(r)
    const name = routeNameToString(r)
    const { name: componentName = name, isFile = false } = viewComponent
    const component = lazilyLoadView(componentName, isFile)
    const meta = { requiresAuthentication, hideAppNavigation }

    return {
      name,
      component,
      meta,
      ...rest,
    }
  }),
  {
    path: "*",
    redirect: { name: routeNameToString(RouteName.MeetingSeries) },
  },
]

let router: VueRouter
const historyCount: Array<string> = []

export {
  getRouter,
  addBeforeEachGuard,
  addBeforeResolveGuard,
  addAfterEachGuard,
  goToRoute,
  goToPath,
  goBack,
  getHistory,
  onReady,
  getCurrentRouteName,
  isPathForRoute,
}

function getRouter() {
  if (!router) {
    router = new VueRouter({
      mode: "history",
      routes,
    })
    router.beforeResolve((_to, from, next) => {
      if (
        from.name &&
        from.name.toLowerCase() !== routeNameToString(RouteName.Authenticate) &&
        from.name.toLowerCase() !==
          routeNameToString(RouteName.AuthenticateBrowse) &&
        from.name.toLowerCase() !== routeNameToString(RouteName.Login) &&
        from.name.toLowerCase() !==
          routeNameToString(RouteName.AuthorizeStart) &&
        from.name.toLowerCase() !== routeNameToString(RouteName.AuthorizeEnd) &&
        from.name.toLowerCase() !== routeNameToString(RouteName.TabConfig) &&
        from.name.toLowerCase() !== routeNameToString(RouteName.RemoveConfig)
      )
        historyCount.push(from.name.toLowerCase())
      next()
    })
  }
  return router
}

function addBeforeEachGuard(guard: NavigationGuard): void {
  router.beforeEach(guard)
}

function addBeforeResolveGuard(guard: NavigationGuard): void {
  router.beforeResolve(guard)
}

function addAfterEachGuard(hook: (to: Route, from: Route) => any): void {
  router.afterEach(hook)
}

function goToRoute(location: RouteLocation): void {
  router.push(createVueRouterLocation(location))
}

function goToPath(path: string): void {
  router.push(path)
}

function goBack(): void {
  historyCount.pop()
  router.back()
}

function getHistory(): string[] {
  return historyCount
}

function onReady(callback: Function): void {
  router.onReady(callback)
}

function getCurrentRouteName(): RouteName | null {
  if (!router.currentRoute.name) return null
  return routeNameFromString(router.currentRoute.name)
}

function isPathForRoute(path: string, name: RouteName): boolean {
  if (!path) return false
  const routePath = getRoutingInformation(name).path
  return path.startsWith(routePath)
}

function getRoutingInformation(name: RouteName): RoutingInformation {
  switch (name) {
    case RouteName.Authenticate:
      return {
        path: "/authenticate",
        props: route => ({
          redirect:
            (Array.isArray(route.query.redirect)
              ? null
              : route.query.redirect) || "",
          ...route.params,
        }),
        requiresAuthentication: false,
        hideAppNavigation: true,
      }

    case RouteName.AuthenticateBrowse:
      return {
        path: "/authenticateBrowse",
        props: route => ({
          redirect:
            (Array.isArray(route.query.redirect)
              ? null
              : route.query.redirect) || "",
          ...route.params,
        }),
        requiresAuthentication: false,
        hideAppNavigation: true,
      }

    case RouteName.Login:
      return {
        path: "/login",
        props: true,
        requiresAuthentication: false,
        hideAppNavigation: true,
      }

    case RouteName.AuthorizeStart:
      return {
        path: "/authorize-start",
        props: route => ({
          scopes: route.query.scopes,
          clientId: route.query.clientId,
        }),
        requiresAuthentication: false,
        hideAppNavigation: true,
      }

    case RouteName.AuthorizeEnd:
      return {
        path: "/authorize-end",
        requiresAuthentication: false,
        hideAppNavigation: true,
      }

    case RouteName.InMeeting:
      return {
        path: "/meeting-series/:parentId/in-meeting/:id",
        props: true,
      }

    case RouteName.MeetingSeries:
      return {
        path: "/meeting-series/:id?",
        props: true,
      }

    case RouteName.TabConfig:
      return {
        path: "/config",
        hideAppNavigation: true,
      }

    case RouteName.RemoveConfig:
      return {
        path: "/remove-config",
        hideAppNavigation: true,
      }

    case RouteName.MeetingInfo:
      return {
        path: "/meetingInfo/:id?",
        props: true,
        hideAppNavigation: false,
      }

    case RouteName.NotificationSettings:
      return {
        path: "/notificationSettings/:id",
        props: true,
        hideAppNavigation: false,
      }

    case RouteName.MemoTemplateSettings:
      return {
        path: "/MemoTemplateSettings/:id",
        props: true,
        hideAppNavigation: false,
      }

    case RouteName.ExternalLinkSettings:
      return {
        path: "/ExternalLinkSettings/:id",
        props: true,
        hideAppNavigation: false,
      }

    case RouteName.NewMeetingCreation:
      return {
        path: "/NewMeetingCreation/:id",
        props: true,
        hideAppNavigation: false,
      }

    case RouteName.MeetingCreationFromOutlook:
      return {
        path: "/MeetingCreationFromOutlook/:id",
        props: true,
        hideAppNavigation: false,
      }

    case RouteName.PastMemoList:
      return {
        path: "/PastMemoList/:id",
        props: true,
        hideAppNavigation: false,
      }

    case RouteName.PersonalSearch:
      return {
        path: "/personalSearch",
        hideAppNavigation: true,
      }

    case RouteName.PersonalNotification:
      return {
        path: "/personalNotification",
        hideAppNavigation: true,
      }

    case RouteName.PersonalConfirmMemo:
      return {
        path: "/personalConfirmMemo",
        hideAppNavigation: true,
      }

    case RouteName.ConfirmApproves:
      return {
        path: "/confirmApproves/:id",
        props: true,
        hideAppNavigation: false,
      }

    case RouteName.MeetingCreationFromExternal:
      return {
        path: "/meetingCreationFromExternal",
        props: true,
        hideAppNavigation: true,
      }

    default:
      return assertExhaustive<never>(
        name,
        undefined,
        `Unknown route name: '${name}'`
      )
  }
}

function createVueRouterLocation(location: RouteLocation): VueRouterLocation {
  return {
    name: routeNameToString(location.name),
    params: (location.params as any) as Dictionary<string>,
  }
}

function lazilyLoadView(name: string, isFile?: boolean) {
  const fileName = `${name}.vue`
  const path = `${isFile ? "" : `${name}/`}${fileName}`
  return () => import(`../views/${path}`)
}
