import DateTime from "./DateTime"
import Link from "./Link"
import NotificationSetting from "./NotificationSetting"
import { Project } from "./Project"
import ProjectFile from "./ProjectFile"
import User from "./User"

export class Agenda {
  agenda?: string
  times?: number | null

  constructor(input?: Agenda) {
    this.agenda = input?.agenda
    this.times = input?.times
  }
}

export class MeetingContent {
  purpose?: string
  goal?: string
  agendas?: Array<Agenda>

  constructor(input?: MeetingContent) {
    this.purpose = input?.purpose
    this.goal = input?.goal
    const agendas = input?.agendas?.map(a => new Agenda(a))
    if (agendas && agendas.length) this.agendas = agendas
    else if (agendas && agendas.length === 1)
      this.agendas = [...agendas, new Agenda()]
    else this.agendas = [new Agenda(), new Agenda()]
  }
}

export class Meeting {
  startTime: DateTime
  endTime: DateTime
  id: string
  location = ""
  subject = ""
  purpose?: string
  goal?: string
  agendas?: Array<Agenda>
  iCaluid: string
  sharepointItemId: string
  userIds: Array<string>
  users: Array<User>
  files: Array<ProjectFile>
  isTeams: boolean
  onlineMeetingUrl: string
  // eslint-disable-next-line @typescript-eslint/ban-types
  memo: Object

  constructor(input: Meeting) {
    this.id = input.id
    this.location = input.location
    this.subject = input.subject
    this.purpose = input.purpose
    this.goal = input.goal
    this.agendas = input.agendas?.map(a => new Agenda(a))
    this.startTime = new DateTime(input.startTime, "Asia/Tokyo")
    this.endTime = new DateTime(input.endTime, "Asia/Tokyo")
    this.iCaluid = input.iCaluid
    this.sharepointItemId = input.sharepointItemId
    this.userIds = input.userIds ?? []
    this.users = input.users ?? []
    this.files = input.files ?? []
    this.isTeams = input.isTeams
    this.onlineMeetingUrl = input.onlineMeetingUrl
    this.memo = input.memo
  }
}

export class MeetingStructure {
  id: string
  name: string
  order: string
  summary: string
  location: string
  userIds: Array<string>
  meetings: Array<Meeting>
  links: Array<Link>
  prepareRequestNotification: NotificationSetting
  meetingAlertNotification: NotificationSetting
  selectedMemoTemplateId: string
  sharepointNextMemoItemId: string
  users: Array<User>

  constructor(input: MeetingStructure) {
    this.id = input.id
    this.name = input.name
    this.summary = input.summary
    this.location = input.location
    this.userIds = input.userIds
    this.meetings = input.meetings?.map(m => new Meeting(m)) ?? []
    this.links = input.links
    this.prepareRequestNotification = new NotificationSetting(
      input.prepareRequestNotification
    )
    this.meetingAlertNotification = new NotificationSetting(
      input.meetingAlertNotification
    )
    this.selectedMemoTemplateId = input.selectedMemoTemplateId
    this.sharepointNextMemoItemId = input.sharepointNextMemoItemId
    this.users = input.users ?? []
    this.order = input.order ?? ""
  }
}

export class MeetingStructureSummary {
  id: string
  name: string
  summary: string
  userIds: Array<string>
  users: Array<User>
  project?: Project

  constructor(input: MeetingStructureSummary) {
    this.id = input.id
    this.name = input.name
    this.summary = input.summary
    this.userIds = input.userIds
    this.users = input.users ?? []
    this.project = input.project ? new Project(input.project) : undefined
  }
}
