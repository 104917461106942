import moment, { Moment } from "moment-timezone"
import { assertExhaustive } from "@/utilities/switch"
import RegularMeetingSolutionError from "@/models/RegularMeetingSolutionError"

moment.locale("ja", {
  weekdays: [
    "日曜日",
    "月曜日",
    "火曜日",
    "水曜日",
    "木曜日",
    "金曜日",
    "土曜日",
  ],
  weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"],
})

export type DurationName =
  | "years"
  | "months"
  | "days"
  | "hours"
  | "minutes"
  | "seconds"

export type Duration = Partial<Record<DurationName, number>>

export interface Defaults {
  now?: string
  timeZone: string
}

export interface SetDefaultsInput extends Omit<Partial<Defaults>, "now"> {
  now?: null | string
}

class DateTime {
  private static defaults: Defaults = {
    timeZone: moment.tz.guess(),
  }

  _value: Moment = moment(DateTime.defaults.now)
  _timeZone: string = DateTime.defaults.timeZone

  constructor(input?: DateTime | Date | string, timeZone?: string) {
    if (!input) return

    if (input instanceof DateTime) {
      this._timeZone = input._timeZone
      this._value = moment(input._value)

      if (timeZone) {
        this._timeZone = timeZone
        this._value.tz(timeZone)
      }
    } else {
      this._timeZone = timeZone || this._timeZone
      this._value = moment.tz(input, this._timeZone)
    }

    if (!moment.isMoment(this._value))
      throw new RegularMeetingSolutionError({
        message: `invalid-date-time-value: '${input}'`,
      })
  }

  static setDefaults = (input: SetDefaultsInput): void => {
    const { now = undefined, timeZone = "" } = input

    if (now !== undefined) {
      if (now === null) delete DateTime.defaults.now
      else DateTime.defaults.now = now
    }
    if (timeZone.length > 0) DateTime.defaults.timeZone = timeZone
  }

  static getDefaults = (): Defaults => ({ ...DateTime.defaults })

  getStartOfMonth = (): DateTime =>
    new DateTime(
      this._value
        .clone()
        .startOf("month")
        .format("YYYY-MM-DD hh:mm:ss")
    )

  getEndOfMonth = (): DateTime =>
    new DateTime(
      this._value
        .clone()
        .endOf("month")
        .format("YYYY-MM-DD hh:mm:ss")
    )

  toJsDate = (): Date => this._value.toDate()

  toIsoString = (): string => this._value.toISOString()

  toDateTimeString = (): string => this._value.format("YYYY-MM-DDTHH:mm:ss")

  toDateString = (): string => this._value.format("YYYY-MM-DD")

  toDateStringSlash = (): string => this._value.format("YYYY/MM/DD")

  toDateStringSlashFull = (): string => this._value.format("YYYY/MM/DD HH:mm")

  toDateJpString = (): string => this._value.format("YYYY年MM月DD日")

  toMonthJpString = (): string => this._value.format("YYYY年MM月")

  toDateJpStringFull = (): string => this._value.format("YYYY年MM月DD日 HH:mm")

  toJpTimeString = (): string => this._value.format("HH:mm")

  toJpDayOfWeek = (): string => this._value.format("ddd")

  add = (duration: Duration): DateTime =>
    new DateTime(add(this._value, duration).toDate(), this._timeZone)

  sub = (duration: Duration): DateTime =>
    new DateTime(subtract(this._value, duration).toDate(), this._timeZone)

  getDifference = (date: DateTime | Date, duration: DurationName): number =>
    this._value.diff(extractValue(date), duration, true)

  isSame = (date: DateTime | Date, unit: DurationName): boolean =>
    this._value.isSame(extractValue(date), unit)

  isSameOrBefore = (date: DateTime | Date, unit?: DurationName): boolean =>
    this._value.isSameOrBefore(extractValue(date), unit)

  isSameOrAfter = (date: DateTime | Date, unit?: DurationName): boolean =>
    this._value.isSameOrAfter(extractValue(date), unit)

  isBefore = (date: DateTime | Date, unit: DurationName): boolean =>
    this._value.isBefore(extractValue(date), unit)

  isAfter = (date: DateTime | Date, unit: DurationName): boolean =>
    this._value.isAfter(extractValue(date), unit)

  isWeekend = (): boolean => this._value.day() % 6 === 0
}

export default DateTime

function add(date: Moment, duration: Duration): Moment {
  const result = moment(date)
  Object.entries(duration).forEach(([name, value]) => {
    if (!isDurationName(name) || typeof value !== "number") return
    result.add(value, name)
  })
  return result
}

function subtract(date: Moment, duration: Duration): Moment {
  const result = moment(date)
  Object.entries(duration).forEach(([name, value]) => {
    if (!isDurationName(name) || typeof value !== "number") return
    result.subtract(value, name)
  })
  return result
}

function extractValue(date: DateTime | Date): Moment | Date {
  return date instanceof DateTime ? date._value : date
}

function isDurationName(value: string): value is DurationName {
  const valueAsDurationName = value as DurationName
  switch (valueAsDurationName) {
    case "years":
    case "months":
    case "days":
    case "hours":
    case "minutes":
    case "seconds":
      return true

    default:
      return assertExhaustive(valueAsDurationName, false)
  }
}
