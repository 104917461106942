export interface RegularMeetingSolutionErrorConstructorInput {
  message: string
}

class RegularMeetingSolutionError extends Error {
  message: string

  constructor({ message }: RegularMeetingSolutionErrorConstructorInput) {
    super(message)

    this.message = message
  }
}

export default RegularMeetingSolutionError
