export default class NotificationSetting {
  isNotify = false
  removeNotify = false
  timeBefore = "0:0:0:0"
  removeAfter = "0:1:0:0"
  message = ""
  userIds: Array<string> = []

  constructor(input?: NotificationSetting) {
    if (!input) return
    this.isNotify = input.isNotify
    this.removeNotify = input.removeNotify
    this.timeBefore = input.timeBefore ?? "0:0:0:0"
    this.removeAfter = input.removeAfter ?? "0:1:0:0"
    this.message = input.message ?? ""
    this.userIds = input.userIds ?? []
  }
}
