











import { defineComponent, computed } from "@vue/composition-api"

export default defineComponent({
  props: {
    pack: {
      type: String,
      default: "mdi",
    },
    name: {
      type: String,
      required: true,
    },
    color: String,
    size: {
      type: Number,
      default: 32,
    },
  },

  setup(props) {
    const iconColor = computed(() => getIconColor(props.color))

    const iconName = computed(() => {
      switch (props.pack) {
        case "ms":
          return `$ms-${props.name}`

        default:
          return `${props.pack}-${props.name}`
      }
    })

    return { iconColor, iconName }
  },
})

function getIconColor(color: string | undefined) {
  if (!color) return "primary"
  return color === "default" ? undefined : color
}
