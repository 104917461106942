import { reactive, toRefs } from "@vue/composition-api"
import { createContainer } from "./Container"
import TaskApi from "@/api/TaskApi"
import {
  PlannerAssignment,
  PlannerBucket,
  PlannerPlan,
} from "@microsoft/microsoft-graph-types"

const api = new TaskApi()

interface State {
  plans: Array<PlannerPlan> | null
  buckets: { [key: string]: Array<PlannerBucket> }
}

function getDefaultState(): State {
  return {
    plans: null,
    buckets: {},
  }
}

function useProject(props?: State | null) {
  const state = reactive<State>(props ?? getDefaultState())

  const getPlansAsync = async (groupId: string) => {
    if (state.plans) return state.plans
    const plans = await api.getPlans(groupId)
    state.plans = plans
    return plans
  }

  const getBucketsAsync = async (planId: string) => {
    if (state.buckets[planId]) return state.buckets[planId]
    const buckets = await api.getBuckets(planId)
    state.buckets[planId] = buckets
    return buckets
  }

  const createTaskAsync = async (task: {
    title: string
    planId: string
    bucketId: string
    percentComplete: number
    priority: number
    users: Array<string>
  }) => {
    const userAssignments: { [key: string]: PlannerAssignment } = {}
    const taskData = Object.assign({}, task, {
      assignments: task.users.reduce((r, a) => {
        r[a] = {
          "@odata.type": "microsoft.graph.plannerAssignment",
          orderHint: " !",
        } as PlannerAssignment
        return r
      }, userAssignments),
    })
    const buckets = await api.createTasks(taskData)
    return buckets
  }

  return {
    state: toRefs(state),
    getPlansAsync,
    getBucketsAsync,
    createTaskAsync,
  }
}

type useTaskStore = ReturnType<typeof useProject>

/**
 * @constant
 * Answererコンテナ
 */
export const taskContainer = createContainer<useTaskStore, State>(useProject)
