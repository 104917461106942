import HttpClient, { HttpRequestOptions } from "./HttpClient"
import SofieTokenProvider from "@/utilities/SofieTokenProvider"
import ApiError from "@/models/Errors/ApiError"

const sofieTokenProvider: SofieTokenProvider = new SofieTokenProvider()

class MsGraphClient {
  private _client: HttpClient
  private _type: "manage" | "customer"

  constructor(type: "manage" | "customer" = "customer") {
    this._type = type
    this._client = new HttpClient({
      baseUrl:
        type === "manage"
          ? "https://graph.microsoft.com/beta"
          : "https://graph.microsoft.com/v1.0",
    })
  }

  /**
   * pathにGETリクエストを送ります。
   *
   * @param {string} path 送り先
   * @param {HttpRequestOptions} [options] リクエストオプション
   * @returns {Promise<Response>} リクエスト結果
   */
  getAsync = async <T>(path: string, options?: HttpRequestOptions) => {
    return this._handleRequestErrorsAsync<T>(async () => {
      const httpRequestOptions = await this._getHttpRequestOptionsAsync(options)
      return this._client.getAsync<T>(path, httpRequestOptions)
    })
  }

  /**
   * pathにPOSTリクエストを送ります。
   *
   * @param {string} path 送り先
   * @param {string} [data] 送るデータ
   * @param {HttpRequestOptions} [options] リクエストオプション
   * @returns {Promise<Response>} リクエスト結果
   */
  postAsync = <T>(
    path: string,
    data?: string | FormData,
    options?: HttpRequestOptions,
    responseHandler: ((r: Response) => Promise<T>) | null = null
  ) => {
    return this._handleRequestErrorsAsync(async () => {
      const httpRequestOptions = await this._getHttpRequestOptionsAsync(
        options,
        data !== undefined
      )
      return this._client.postAsync<T>(
        path,
        data,
        httpRequestOptions,
        responseHandler
      )
    })
  }

  /**
   * pathにPATCHリクエストを送ります。
   *
   * @param {string} path 送り先
   * @param {string} [data] 送るデータ
   * @param {HttpRequestOptions} [options] リクエストオプション
   * @returns {Promise<Response>} リクエスト結果
   */
  patchAsync = async <T>(
    path: string,
    data?: string,
    options?: HttpRequestOptions
  ) => {
    return this._handleRequestErrorsAsync(async () => {
      const httpRequestOptions = await this._getHttpRequestOptionsAsync(
        options,
        data !== undefined
      )
      return this._client.patchAsync<T>(path, data, httpRequestOptions)
    })
  }

  /**
   * pathにPATCHリクエストを送ります。
   *
   * @param {string} path 送り先
   * @param {string} [data] 送るデータ
   * @param {HttpRequestOptions} [options] リクエストオプション
   * @returns {Promise<Response>} リクエスト結果
   */
  putAsync = async <T>(
    path: string,
    data?: string | File,
    options?: HttpRequestOptions
  ) => {
    return this._handleRequestErrorsAsync(async () => {
      const httpRequestOptions = await this._getHttpRequestOptionsAsync(
        options,
        data !== undefined
      )
      return this._client.putAsync<T>(path, data, httpRequestOptions)
    })
  }

  /**
   * pathにDELETEリクエストを送ります。
   *
   * @param {string} 送り先
   * @param {HttpRequestOptions} [options] リクエストオプション
   * @returns {Promise<Response>} リクエスト結果
   */
  deleteAsync = async (path: string, options?: HttpRequestOptions) => {
    return this._handleRequestErrorsAsync(async () => {
      const httpRequestOptions = await this._getHttpRequestOptionsAsync(options)
      return this._client.deleteAsync(path, httpRequestOptions)
    })
  }

  private _getHttpRequestOptionsAsync = async (
    options?: HttpRequestOptions,
    hasData?: boolean
  ): Promise<HttpRequestOptions> => {
    const headers = await this._getHeadersAsync(options, hasData)
    return {
      ...options,
      headers,
      queryParameters: options?.queryParameters,
    }
  }

  getTokenAsync = async () => {
    switch (this._type) {
      case "manage":
        return await sofieTokenProvider.getManageGraphApiTokenAsync()

      case "customer":
      default:
        return await sofieTokenProvider.getCustomerGraphApiTokenAsync()
    }
  }

  private _getHeadersAsync = async (
    options?: HttpRequestOptions,
    hasData?: boolean
  ) => {
    const defaultHeaders: Record<string, string> = {
      Accept: "application/json",
    }

    if (hasData && !options?.noContentType)
      defaultHeaders["Content-Type"] = "application/json"
    const token = await this.getTokenAsync()
    defaultHeaders["Authorization"] = `Bearer ${token}`

    return { ...defaultHeaders, ...options?.headers }
  }

  /**
   * sendRequestAsyncを実行して、
   * Responseの結果によって再度sendRequestAsyncを実行します。
   *
   * @param {() => Promise<Response>} sendRequestAsync 実行するファンクション
   * @param {number} [retryCount] 再度実行した回数
   * @returns {Promise<Response>} sendRequestAsyncの結果
   */
  private _handleRequestErrorsAsync = async <T>(
    sendRequestAsync: () => Promise<T>,
    retryCount = 0
  ): Promise<T> => {
    try {
      const response = await sendRequestAsync()
      return response
    } catch (e) {
      if (e instanceof ApiError) {
        if (e.status >= 400 && retryCount < 1)
          return this._handleRequestErrorsAsync(
            sendRequestAsync,
            retryCount + 1
          )
      }
      throw e
    }
  }
}

export default MsGraphClient
