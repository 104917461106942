import { reactive, toRefs } from "@vue/composition-api"
import { createContainer } from "./Container"
import MemoNegotiateApi from "@/api/MemoNegotiateApi"
import ApplicationInformation from "@/models/ApplicationInformation"

interface State {}

let api: MemoNegotiateApi | null = null

function memoSyncState(props?: State) {
  const apiProvider = () => {
    if (api === null) {
      api = new MemoNegotiateApi(
        (window.aplicationInfo as ApplicationInformation).funcEndpointUrl
      )
    }
    return api
  }
  const state = reactive<State>(props ?? {})

  const negotiateAsync = (
    siteid: string,
    groupid: string,
    listid: string,
    itemid: string,
    userid: string,
    initHtml: string
  ) => {
    return apiProvider().negotiate(
      siteid,
      groupid,
      listid,
      itemid,
      userid,
      initHtml
    )
  }
  const saveAsync = (
    siteid: string,
    groupid: string,
    listid: string,
    itemid: string
  ) => {
    return apiProvider().save(siteid, groupid, listid, itemid)
  }

  return {
    state: toRefs(state),
    negotiateAsync,
    saveAsync,
  }
}

type memoSyncStore = ReturnType<typeof memoSyncState>

export const memoSyncContainer = createContainer<memoSyncStore, State>(
  memoSyncState
)
