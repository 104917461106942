import MsGraphClient from "./clients/MsGraphClient"
import * as MicrosoftGraph from "@microsoft/microsoft-graph-types"

class SiteApi {
  private _client = new MsGraphClient()

  getSite = async (
    hostname: string,
    serverRelativePath: string
  ): Promise<MicrosoftGraph.Site> => {
    const ret = await this._client.getAsync<MicrosoftGraph.Site>(
      `sites/${hostname}:${serverRelativePath}`
    )
    return ret
  }
}

export default SiteApi
