import MsGraphClient from "./clients/MsGraphClient"
import SofieClient from "./clients/SofieClient"
import * as MicrosoftGraph from "@microsoft/microsoft-graph-types"

class ChatApi {
  private _msClient = new MsGraphClient()
  private _client = new SofieClient()

  sendMessage = async (sendRequest: {
    message: string
    teamId: string
    channelId: string
    mentionUserIds: string[]
  }) => {
    await this._client.postAsync<void>(
      `chatmessage/send`,
      JSON.stringify(sendRequest)
    )
  }

  sendMessageUseGraph = async (sendRequest: {
    message: string
    teamId: string
    channelId: string
    mentionUsers: Array<{ displayName: string; id: string }>
  }) => {
    const mentions = sendRequest.mentionUsers.map((mu, index) => ({
      id: index,
      mentionText: mu.displayName,
      mentioned: {
        user: { displayName: mu.displayName, id: mu.id },
      },
    }))
    let message = sendRequest.message
    for (const mention of mentions) {
      const mentionTxt = `<at id="${mention.id}">${mention.mentionText}</at>`
      if (sendRequest.message.includes(mention.mentionText))
        message = message.replace(mention.mentionText, mentionTxt)
      else message += mentionTxt
    }
    await this._msClient.postAsync<MicrosoftGraph.Message>(
      `chats/${sendRequest.channelId}/messages`,
      JSON.stringify({
        messageType: "message",
        body: {
          content: message,
          contentType: "html",
        },
        locale: "ja-JP",
        mentions: mentions,
      } as MicrosoftGraph.Message)
    )
  }
}

export default ChatApi
