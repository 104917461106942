


































































import { defineComponent } from "@vue/composition-api"
import DefaultButton from "../buttons/DefaultButton.vue"
import IconButton from "../buttons/IconButton.vue"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"

export default defineComponent({
  components: { DefaultButton, IconButton, I18nFormattedMessage },
  props: {
    customName: String,
    visible: Boolean,
    maxWidth: {
      type: [String, Number],
      default: 700,
    },
    isLoading: Boolean,
    hideOkBtn: Boolean,
    hideCancelBtn: Boolean,
    disabledOkBtn: Boolean,
    disabledCancelBtn: Boolean,
    scrollable: Boolean,
    fixedActionBtn: Boolean,
    blackTextBody: Boolean,
  },
  setup(props, { emit, slots }) {
    const { dialogSubtitle } = slots

    const onCancelClick = () => {
      if (props.hideCancelBtn) throw Error("cancel button is hidden")
      emit("cancel")
    }

    const closeDialog = () => {
      emit("cancel")
    }

    const onOkClick = () => {
      if (props.hideOkBtn) throw Error("ok button is hidden")
      emit("ok")
    }

    return {
      hasSubtitle: !!dialogSubtitle,
      onCancelClick,
      closeDialog,
      onOkClick,
    }
  },
})
