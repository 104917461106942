import ErrorResponse from "@/models/ErrorResponse"

export interface ApiErrorConstructorInput {
  status: number
  response?: Response
  message?: string
  statusText?: string
}

export class ApiErrorMessage {
  detail?: Array<Record<"Key" | "Value", string>>
}

class ApiError extends Error {
  status: number
  response?: Response
  message: string
  statusText?: string
  errorInfo?: ErrorResponse

  constructor(input: ApiErrorConstructorInput, errorData?: ErrorResponse) {
    const { message = `API Error ${input.status}` } = input
    super(message)

    this.message = message
    this.errorInfo = errorData
    this.response = input.response
    this.status = input.status
    this.statusText = input.statusText
  }
}

export default ApiError
