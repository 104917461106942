/* eslint-disable  @typescript-eslint/no-explicit-any */

import HttpClient from "./clients/HttpClient"
import SofieTokenProvider from "@/utilities/SofieTokenProvider"

const sofieTokenProvider: SofieTokenProvider = new SofieTokenProvider()

class MemoNegotiateApi {
  client: HttpClient

  constructor(funcEndpointUrl: string) {
    this.client = new HttpClient({
      baseUrl: funcEndpointUrl,
    })
  }

  async negotiate(
    siteid: string,
    groupid: string,
    listid: string,
    itemid: string,
    userid: string,
    initHtml: string
  ) {
    const uniqueid = `${groupid}_${listid}_${itemid}_${userid}`
    const token = await sofieTokenProvider.getCustomerGraphApiTokenAsync()
    const res = await this.client.postAsync<{
      accessToken: string
      baseUrl: string
      url: string
    }>(
      `negotiate?userid=${uniqueid}`,
      JSON.stringify({
        siteid,
        token,
        initHtml,
      })
    )
    return res
  }

  async save(siteid: string, groupid: string, listid: string, itemid: string) {
    const token = await sofieTokenProvider.getCustomerGraphApiTokenAsync()
    const res = await this.client.postAsync<{
      lastUpdate: number
      isClosed: boolean
    }>(
      "save",
      JSON.stringify({
        siteid,
        groupid: groupid,
        listid: listid,
        itemid: itemid,
        token,
      })
    )
    return res
  }
}

export default MemoNegotiateApi
