





















































import { defineComponent, reactive, ref } from "@vue/composition-api"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import Icon from "@/components/Icon.vue"
import DefaultDialog from "@/components/dialogs/DefaultDialog.vue"
import TextField from "@/components/textFields/TextField.vue"
import { MeetingStructure } from "@/models/Meeting"
import { teamsContextContainer } from "@/containers/TeamsContextContainer"
import { projectsContainer } from "@/containers/ProjectsContainer"
import { meetingContainer } from "@/containers/MeetingsContainer"
import { i18nContainer } from "@/containers/I18nContainer"
import MeetingStructureInput from "@/components/MeetingStructureInput.vue"
import { generateOrderValue } from "@/utilities/Order"
import User from "@/models/User"
import { goToRoute } from "@/router"
import RouteName from "@/constants/RouteName"

interface State {
  isLoadingCreate: boolean
  meetingTitle: string
  isFormValid: boolean
  newMtgStructure: MeetingStructure
  errorMsg: string
}

export default defineComponent({
  props: {
    value: {
      type: Boolean,
    },
    isMenuVisible: {
      type: Boolean,
    },
  },
  components: {
    I18nFormattedMessage,
    Icon,
    DefaultDialog,
    TextField,
    MeetingStructureInput,
  },
  setup(_, { emit }) {
    const { formatMessage } = i18nContainer.useContainer()
    const {
      getMeetingsAsync,
      createMeetingStructureAsync,
    } = meetingContainer.useContainer()
    const { getContext } = teamsContextContainer.useContainer()
    const { getProjectAsync } = projectsContainer.useContainer()

    const state = reactive<State>({
      isLoadingCreate: false,
      meetingTitle: "",
      isFormValid: false,
      newMtgStructure: {} as MeetingStructure,
      errorMsg: "",
    })

    const onAddMeetingClick = () => {
      emit("input", true)
    }

    const mtgStructureInput = ref<InstanceType<typeof MeetingStructureInput>>()
    const clearInputData = () => {
      if (!mtgStructureInput.value) return
      //子コンポーネント(MeetingStructureInput.vue)のメソッドを使って、入力データを空にする
      mtgStructureInput.value.clearData()
    }

    const onCancel = () => {
      emit("input", false)
      clearInputData()
    }

    const onOk = async () => {
      state.isLoadingCreate = true
      try {
        const context = await getContext()
        const project = await getProjectAsync(context.entityId)
        const meetings = await getMeetingsAsync(context.entityId)
        state.newMtgStructure.order =
          meetings.length === 0
            ? generateOrderValue()
            : generateOrderValue(meetings[meetings.length - 1].order)
        state.newMtgStructure.meetingAlertNotification = {
          isNotify: true,
          removeNotify: false,
          timeBefore: "0:0:5:0",
          removeAfter: "0:1:0:0",
          message: "会議が間もなく始まります。",
          userIds: state.newMtgStructure.userIds,
        }
        const createdMeetings = await createMeetingStructureAsync(
          project.siteId,
          context.entityId,
          state.newMtgStructure
        )

        clearInputData()
        goToRoute({
          name: RouteName.MeetingSeries,
          params: { id: createdMeetings.id },
        })
      } finally {
        state.isLoadingCreate = false
      }
      emit("input", false)
    }

    const onExternalAttendeeChange = (attendees: Array<User>) => {
      const attendeesWithId = attendees.map(t => {
        return new User({ ...t, id: "" })
      })

      state.newMtgStructure.users = attendeesWithId
    }

    const onMeetingStructureInput = (mtgStructure: MeetingStructure) => {
      state.newMtgStructure = mtgStructure
    }

    const onValidChange = (isValid: boolean) => {
      if (isValid && state.newMtgStructure.name) {
        state.isFormValid = true
      } else {
        state.isFormValid = false
      }
    }

    return {
      state,
      i18nFormattedMessage: formatMessage,
      onAddMeetingClick,
      onCancel,
      onOk,
      onExternalAttendeeChange,
      onMeetingStructureInput,
      onValidChange,
      mtgStructureInput,
    }
  },
})
