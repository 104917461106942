



























import {
  defineComponent,
  PropType,
  computed,
  reactive,
  watch,
} from "@vue/composition-api"
import { ruleFunction } from "@/utilities/ruleFunction"

interface State {
  text: string
}

export default defineComponent({
  props: {
    required: Boolean,
    label: String,
    placeholder: String,
    hideDetails: Boolean,
    value: String,
    readonly: Boolean,
    rules: Array as PropType<ruleFunction[]>,
    blackText: Boolean,
    validateOnBlur: Boolean,
    autofocus: Boolean,
    bgColor: {
      type: String,
      default: "inputField",
    },
  },

  setup(props, { emit }) {
    const state = reactive<State>({
      text: props.value ?? "",
    })

    const getDefaultRules = (): Array<ruleFunction> => {
      const rules: Array<ruleFunction> = []

      if (props.required)
        rules.push(value => !!value.toString().trim() || "入力は必須です。")

      return rules
    }

    const combinedRules = computed<Array<ruleFunction>>(() =>
      getDefaultRules().concat(props.rules || [])
    )

    const onTheValueChanged = (value: string) => {
      emit("input", value)
    }

    const emitOn = () => {
      emit("click")
    }

    watch(
      () => props.value,
      newValue => {
        state.text = newValue ?? ""
      }
    )

    return { state, onTheValueChanged, emitOn, combinedRules }
  },
})
