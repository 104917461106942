




import { defineComponent, computed, PropType } from "@vue/composition-api"
import { i18nContainer } from "@/containers/I18nContainer"

export default defineComponent({
  props: {
    dateTime: {
      type: Date as PropType<Date>,
      required: true,
    },
    weekday: String as PropType<"long" | "short" | "narrow">, //曜日
    era: String as PropType<"long" | "short" | "narrow">,
    year: String as PropType<"numeric" | "2-digit">,
    month: String as PropType<
      "long" | "short" | "narrow" | "numeric" | "2-digit"
    >,
    day: String as PropType<"numeric" | "2-digit">, //日付
    hour: String as PropType<"numeric" | "2-digit">,
    minute: String as PropType<"numeric" | "2-digit">,
    second: String as PropType<"numeric" | "2-digit">,
    hours12or24: String,
  },

  setup(props) {
    const { formatDateTime } = i18nContainer.useContainer()

    if (
      props.hours12or24 !== undefined &&
      props.hours12or24 !== "12" &&
      props.hours12or24 !== "24"
    ) {
      throw new Error(`hours12or24 is not '12' or '24': '${props.hours12or24}'`)
    }

    const dateTimeString = computed(() => {
      const { dateTime, hours12or24, ...options } = props
      const hour12 =
        hours12or24 === undefined ? undefined : hours12or24 === "12"

      return formatDateTime(dateTime, {
        ...options,
        hour12,
      })
    })

    return { dateTimeString }
  },
})
