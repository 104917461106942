




























































import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api"
import I18nFormattedDateTime from "@/components/i18n/I18nFormattedDateTime.vue"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import Icon from "@/components/Icon.vue"
import Loading from "@/components/Loading.vue"
import DateTime from "@/models/DateTime"
import { Meeting, MeetingStructure } from "@/models/Meeting"
import { meetingContainer } from "@/containers/MeetingsContainer"
import { i18nContainer } from "@/containers/I18nContainer"
import { teamsContextContainer } from "@/containers/TeamsContextContainer"
import draggable from "vuedraggable"
import { order, generateOrderValue, meetingOrder } from "@/utilities/Order"

interface ViewData {
  meetingStructure: MeetingStructure
  meetings: Array<Meeting>
}

interface State {
  currentOrderValue: { value: ViewData; from: number; to: number } | null
  isLoadingMeetings: boolean
  isLoadingCreate: boolean
  isOpenDialog: boolean
  meetingTitle: string
  meetingViewDatas: Array<ViewData>
}

export default defineComponent({
  components: {
    I18nFormattedDateTime,
    I18nFormattedMessage,
    Icon,
    Loading,
    draggable,
  },
  setup() {
    const { formatDateTime, formatMessage } = i18nContainer.useContainer()
    const { getContext } = teamsContextContainer.useContainer()
    const {
      state: meetingState,
      updateMeetingStructureAsync,
      getMeetingsAsync,
    } = meetingContainer.useContainer()

    const now = new DateTime()

    const state = reactive<State>({
      currentOrderValue: null,
      isLoadingCreate: false,
      isLoadingMeetings: meetingState.isLoadingMeetings.value,
      isOpenDialog: false,
      meetingTitle: "",
      meetingViewDatas: [],
    })

    watch(
      () => meetingState.isLoadingMeetings.value,
      newValue => {
        state.isLoadingMeetings = newValue
      }
    )

    onMounted(async () => {
      if (!meetingState.meetings.value) {
        const context = await getContext()
        await getMeetingsAsync(context.entityId)
      }
      const meetings = meetingState.meetings.value
        ?.slice()
        .sort(function(a, b) {
          return order(a.order, b.order)
        })
        .reduce(
          (viewData, meetingStructure) => [
            ...viewData,
            {
              meetingStructure,
              meetings: getNextAndPastMeetingItems(meetingStructure, now),
            },
          ],
          [] as Array<ViewData>
        )
      state.meetingViewDatas = meetings || []
    })

    const move = (evt: {
      draggedContext: { element: ViewData; index: number }
      relatedContext: { index: number }
    }) => {
      state.currentOrderValue = {
        value: evt.draggedContext.element,
        from: evt.draggedContext.index,
        to: evt.relatedContext.index,
      }
    }

    const end = async () => {
      if (
        state.currentOrderValue &&
        state.currentOrderValue.from !== state.currentOrderValue.to
      ) {
        let before = ""
        let after = ""
        if (state.currentOrderValue.to !== 0) {
          before =
            state.meetingViewDatas[state.currentOrderValue.to - 1]
              .meetingStructure.order
        }
        if (state.currentOrderValue.to !== state.meetingViewDatas.length - 1) {
          after =
            state.meetingViewDatas[state.currentOrderValue.to + 1]
              .meetingStructure.order
        }
        const meetingStructure = state.currentOrderValue.value.meetingStructure
        meetingStructure.order = generateOrderValue(before, after)
        const context = await getContext()
        await updateMeetingStructureAsync(
          null,
          context.entityId,
          meetingStructure.id,
          meetingStructure
        )
      }
      state.currentOrderValue = null
    }

    return {
      state,
      move,
      end,
      formatDateTime,
      i18nFormattedMessage: formatMessage,
    }
  },
})

function getNextAndPastMeetingItems(
  meeting: MeetingStructure,
  now: DateTime
): Array<Meeting> {
  const sortedItems = meeting.meetings.sort(meetingOrder).reduce(
    (acc, item) => {
      const isEnded = item.endTime.isAfter(now, "minutes")
      if (isEnded) {
        return {
          past: [...acc.past],
          future: [...acc.future, item],
        }
      }
      return {
        past: [...acc.past, item],
        future: [...acc.future],
      }
    },
    {
      past: [] as Array<Meeting>,
      future: [] as Array<Meeting>,
    }
  )
  const nextItem: Meeting | null =
    sortedItems.future[sortedItems.future.length - 1]

  return (nextItem ? [nextItem] : []).concat(sortedItems.past)
}
