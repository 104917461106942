
































import { defineComponent, computed } from "@vue/composition-api"
import Icon from "@/components/Icon.vue"

export default defineComponent({
  components: {
    Icon,
  },
  props: {
    customName: {
      type: String,
      default: "not_specified",
    },
    color: {
      type: String,
      default: "black",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    height: {
      type: [String, Number],
      default: "auto",
    },
    width: {
      type: [String, Number],
      default: "auto",
    },
    to: String,
    iconName: String,
    iconPosition: String,
    iconSize: [String, Number],
    boldIcon: Boolean,
    iconColor: {
      type: String,
      default: "primary",
    },
  },
  setup(props, { slots }) {
    if (!slots.default) throw new Error("no-default-slot")

    const hasIcon = computed<boolean>(() => !!props.iconName)
    const isIconRight = computed<boolean>(() => props.iconPosition === "right")

    const toLocation = computed(() => {
      if (props.to) return { name: props.to }
    })

    return { hasIcon, isIconRight, toLocation }
  },
})
