import DateTime from "./DateTime"

export class Button {
  url = ""
  label = ""
}

class Notification {
  id: string
  meetingId: string
  bodyImageUrl: string
  notificationDate: DateTime
  userIds: Array<string> = []
  autherId: string
  button: Button | null = null
  teamId: string
  channelId: string
  isFromPrivate: boolean

  constructor(input: Notification) {
    this.id = input.id
    this.meetingId = input.meetingId
    this.bodyImageUrl = input.bodyImageUrl
    this.notificationDate = new DateTime(input.notificationDate)
    this.userIds = input.userIds
    this.button = input.button
    this.teamId = input.teamId
    this.channelId = input.channelId
    this.autherId = input.autherId
    this.isFromPrivate = input.isFromPrivate
  }
}

export default Notification
